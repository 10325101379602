<div [ngClass]="typeClass" >
    <div [ngClass]="{'style-vertical' : position === 'vertical', 'style-horizontal' : position === 'horizontal'}">
        <div *ngFor="let step of data;  let i = index" >
            <div [ngClass]="{
                'estado-activo' : step.estado === 'activo',
                'estado-completado' : step.estado === 'completado',
                'estado-incompleto' :  step.estado === 'incompleto'
            }"
            class="content estado-completado">
                <div class="step">
                    <div class="circulo">
                        <div class="circulo__text">
                            <p  class="font-primary-bold-h5" *ngIf="step.estado === 'completado'"> &check;</p>
                            <p class="font-primary-bold-h5" *ngIf="step.estado != 'completado'">  {{ i+1 }}
                        </div>
                    </div>
                    <div [ngClass]="{'linea-step': true, 'linea-step--short': data.length > 3}" *ngIf="data.length != i+1"> </div>
                </div>
                <div class="text-step">
                    <p
                        [ngClass]="{'font-primary-bold-h5' : position === 'vertical', 'font-primary-mobile-bold-h6' : position === 'horizontal'}"
                        class="titulo">
                        {{step.titulo}}
                    </p>
                    <p class="subtitulo font-secondary-regular-16"> {{step.subtitulo}} </p>
                </div>
            </div>
        </div>
    </div>

</div>
