import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalConfirmService {
  private _loadingButtonConfirm = new BehaviorSubject<boolean>(false);
  loadingButtonConfirm = this._loadingButtonConfirm.asObservable();

  setLoadingButtonConfirm(isloading: boolean) {
    this._loadingButtonConfirm.next(isloading);
  }

}
