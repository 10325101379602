<div [ngClass]="typeClass" >  
    <div  [ngClass]="{'content' : isShow, 'contentDark' : !isShow}"  >  
        <div class="centrar">
            <!-- <img class="iconPreloader" [ngSrc]="icon" alt="icon del preloader"> -->
           
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              
        </div>
    </div>
</div> 