import { Component } from '@angular/core';

@Component({
  selector: 'cbx-personal-data-processing-policy',
  templateUrl: './personal-data-processing-policy.component.html',
  styleUrls: ['./personal-data-processing-policy.component.scss']
})
export class PersonalDataProcessingPolicyComponent {

}
