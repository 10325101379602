<ng-container [ngSwitch]="typeInputs">
    <ng-container *ngSwitchCase="'ClassicInput'">
        <div class="ClassicInput" (click)="toggleDropdown()" >
            <div
                class="input-container"
                [style.width]="width"
            >
                <input
                    type="text"
                    id="username"
                    [style.height]="height"
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    [readonly]="readOnly"
                    [value]="value"
                    [ngClass]="classInput"
                    (input)="onChange($event.target)"
                    autocomplete="off"
                    #cbxDropDownList
                >
                <label
                    for="username"
                    class="label"
                >
                    {{label}}
                </label>
                <div class="arrow">
                    <img src="assets/imagenes/dropdown/{{arrow}}.svg">
                </div>



                <div
                    class="loading"
                    *ngIf="loading && !hasIcon"
                >
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div
                    class="icon"
                    *ngIf="hasIcon"
                >
                    <img src="assets/imagenes/normal-input/{{icon}}.svg">
                </div>
            </div>

            <div [ngClass]="classInfo" [style.width]="width">
                <div *ngIf="hasError" class="message-error" >
                    {{msgError}}
                </div>
                <div
                    *ngIf="hasInfo"
                    class="message-info"
                >
                    {{msgInfo}}
                </div>
                <div
                    *ngIf="hasCounter"
                    class="counter"
                >
                    {{counter}}/{{total}}
                </div>
            </div>

            <ng-container [ngSwitch]="typeView">
                <ng-container *ngSwitchCase="'LOADING'">
                    <div class="wrapper-filter">
                        <div class="empty">Cargando...</div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'EMPTY'">
                    <div class="wrapper-filter">
                        <div class="empty"></div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'FULL'">
                    <div class="wrapper-list">
                        <ng-container *ngFor="let item of data">
                            <div
                                class="wrapper-list__option"
                                (click)="getElement(item)"
                                (mousedown)="$event.preventDefault();"
                            >
                                {{item?.name}}
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'ERROR'"></ng-container>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'ClassicInputCompany'">
        <div class="ClassicInputCompany" (click)="toggleDropdown()" >
            <div
                class="input-container"
                [style.width]="width"
            >
                <input
                    type="text"
                    id="username"
                    [style.height]="height"
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    [readonly]="readOnly"
                    [value]="value"
                    [ngClass]="classInput"
                    (input)="onChange($event.target)"
                    autocomplete="off"
                    #cbxDropDownList
                >
                <label
                    for="username"
                    class="label"
                >
                    {{label}}
                </label>
                <div class="arrow">
                    <img src="assets/imagenes/dropdown/{{arrow}}.svg">
                </div>



                <div
                    class="loading"
                    *ngIf="loading && !hasIcon"
                >
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div
                    class="icon"
                    *ngIf="hasIcon"
                >
                    <img src="assets/imagenes/normal-input/{{icon}}.svg">
                </div>
            </div>

            <div [ngClass]="classInfo" [style.width]="width">
                <div *ngIf="hasError" class="message-error" >
                    {{msgError}}
                </div>
                <div
                    *ngIf="hasInfo"
                    class="message-info"
                >
                    {{msgInfo}}
                </div>
                <div
                    *ngIf="hasCounter"
                    class="counter"
                >
                    {{counter}}/{{total}}
                </div>
            </div>

            <ng-container [ngSwitch]="typeView">
                <ng-container *ngSwitchCase="'LOADING'">
                    <div class="wrapper-filter">
                        <div class="empty">Cargando...</div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'EMPTY'">
                    <div class="wrapper-filter">
                        <div class="empty"></div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'FULL'">
                    <div class="wrapper-list">
                        <ng-container *ngFor="let item of data">
                            <div
                                class="wrapper-list__option"
                                (click)="getElement(item)"
                                (mousedown)="$event.preventDefault();"
                            >
                                {{item?.name}}
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'ERROR'"></ng-container>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
