import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PreloaderService } from '../../service/preloader.service';

const getStyles = (...args: string[]) => ["TableDefault", ...args].filter(Boolean)

export interface columnTable {
   header : string,
   field: string,
}

@Component({
  selector: 'cbx-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {

  @Input() arrayHeader    : columnTable[] = [];
  @Input() arrayBody      : any[] = [];
  @Input() isAction       : Boolean = true;
  @Input() isDashboard    : Boolean | null  = true;
  @Input() textAction     : string = "";
  @Input() type           : "TableDefault" | "TableDash" | "TableMyOperations" | "TableMyOperationsResponsive" = "TableDefault";
  @Input() opStatusList   : { [key: string]: string } = {
    'P'  : 'registered',
    'FS' : 'success',
    'C'  : 'canceled',
    'CA' : 'expired',
    'VA' : 'validated',
    'OB' : 'observed',
  };

  @Output() actiontable = new EventEmitter();

  cols : any;

  public get typeClass(): string[] {
    return getStyles(this.type)
  }

  constructor(
    private servicePreloader: PreloaderService,
  ){

  }

  onSelectRow(dataRow : any){
    this.servicePreloader.setpreloader(true);
    this.actiontable.emit(dataRow);
  }

  onClaseEstado(estado : string){
    return this.opStatusList[estado];
  }

}
