import { Overlay, ComponentType, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { DialogRef } from '../directives/dialog-cdk-ref';
import { DIALOG_DATA } from '../directives/dialog-cdk-tokens';
import { DialogConfig } from '../interfaces/dialog.interface';




const DEFAULT_CONFIG: DialogConfig = {
  hasBackdrop: true,
  backdropClass: 'overlay-backdrop',
  panelClass: '',
  disableClose: false,
  size : 'sm'
}

@Injectable({
  providedIn: 'root',
})
export class DialogCdkService {

  constructor(
    private overlay: Overlay,
    private injector: Injector
  ) { }

  open<T>(component: ComponentType<T>, config?: DialogConfig): DialogRef {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config }; 
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayRef = this.overlay.create({
      positionStrategy,
      backdropClass: dialogConfig.backdropClass,
      panelClass: dialogConfig.panelClass,
      maxWidth: dialogConfig.maxWidth,
      width: dialogConfig.width,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      hasBackdrop: dialogConfig.hasBackdrop
    
    });

    const dialogRef = new DialogRef(overlayRef, dialogConfig);

    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: DialogRef, useValue: dialogRef },
        { provide: DIALOG_DATA, useValue: dialogConfig?.data },
      ],
    });

    const portal = new ComponentPortal(component, null, injector);
    overlayRef.attach(portal);

    return dialogRef;
  }
}
