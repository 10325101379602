 <ng-container [ngSwitch]="typeInputs">
    <ng-container *ngSwitchCase="'ClassicInputRegister'">
        <div class="ClassicInputRegister"    >
            <div class="input-container" [style.width]="width">
                <input
                    type="text"
                    [id]="idTextField"
                    [style.height]="height"
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    [readonly]="readOnly"
                    [value]="value"
                    [ngClass]="classInput"
                    (input)="onChange($event.target)"
                    autocomplete="off"
                    #cbxDropDownList
                    (click)="toggleDropdown()"
                >
                <label [htmlFor]="idTextField" class="label">
                    {{label}}
                </label>
                <div class="arrow" (click)="toggleDropdown()">
                    <img src="assets/imagenes/dropdown/{{arrow}}.svg">
                </div>
                <div class="loading" *ngIf="loading" >
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>


            <div [ngClass]="classInfo" [style.width]="width" >
                <div *ngIf="hasError" class="message-error" >
                    {{msgError}}
                </div>
            </div>

            <ng-container [ngSwitch]="typeView">
                <ng-container *ngSwitchCase="'ERROR'">
                    <div class="wrapper-list-found" >
                        <div class="wrapper-list-found__empty color-black-300">
                            <img src="assets/imagenes/dropdown/error.svg">
                            <div> No se encontró ... <b> "{{textSearch}}" </b> </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'FOUND'">
                    <div class="wrapper-list-found"   (click)="getElement(textSearch)"  (mousedown)="$event.preventDefault();">
                        <div class="wrapper-list-found__empty color-black-300">
                            <img src="assets/imagenes/icon-exclamacion.svg">
                            <div class=" color-black-300">
                                <div class="wrapper-list-found__empty-items">
                                    Guardar  <span *ngIf="label === 'Ocupación'"> la </span>
                                    {{ label | lowercase }}
                                    <div class="dato color-black-300"> "{{ textSearch  | uppercase}}" </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'EMPTY'">
                    <div class="wrapper-filter">
                        <div class="empty"></div>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'FULL'">
                    <div class="wrapper-list">
                        <ng-container *ngFor="let item of data">
                            <div
                                class="wrapper-list__option"
                                (click)="getElement(item)"
                                (mousedown)="$event.preventDefault();"
                            >
                                {{item?.name}}
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </div>


    </ng-container>
</ng-container>
