import { Component, Input, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild } from '@angular/core';

const getStyles = (...args: string[]) => ["tipo", ...args].filter(Boolean)


@Component({
  selector: 'cbx-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Input() pages = 8;
  @Input() activePage  : number = 1;
  @Input() type :   "PaginatorDefault"  | "PaginatorPrimary" | "PaginatorBlog" = "PaginatorDefault";
  @Output() onChange = new EventEmitter();

  public get typeClass(): string[] {
   return getStyles(this.type)
  }

  @ViewChild('contenedor', { static: true }) contenedor!: ElementRef;
  arrayPage : any[] = [];

  ngOninit(){
    this.createPagination(this.pages, this.activePage, null);
  }

  createPagination(pages : any, page : any, opcion : any) {
    if(page == '...'){return;}

    if(opcion){
      page = opcion == 'imagen-left' ? this.activePage-1 : this.activePage+1;
    }else{
      this.activePage = page;
    }

    if(page -1  === this.pages && opcion == 'imagen-rigth'){
      this.activePage = this.pages;
      return;
    }
    if(page === 0 && opcion == 'imagen-left'){
      this.activePage = 1;
      return;
    }
    //Enviamos el numero de pagina seleccionada
    if(opcion === 0 || opcion == 'imagen-left' ||  opcion == 'imagen-rigth'){this.onChange.emit(page);}
    //limpiamos el array
    this.arrayPage = [];

    if (page > 1) this.arrayPage.push({ type : 'imagen-left', value : 0 ,  img : 'assets/imagenes/iconos/icon-paginator-left.svg', direction : 'left'})
    if (page == 1) this.arrayPage.push({ type : 'imagen-left', value : 0 ,  img : 'assets/imagenes/iconos/icon-paginator-left-disabled.svg', direction : 'left'})

    if (pages < 5) {
      for (let p = 1; p <= pages; p++) {
        this.arrayPage.push({ type : 'page', value : p})
      }
      this.activePage = page;
    } else {

      let pageCutLow = page - 1;
      let pageCutHigh = page + 2 ;

      if (page === 1) { pageCutHigh = pageCutHigh + 1;}
      if (page === pages) { pageCutLow = pageCutLow -2;
      } else if (page === pages-1) { pageCutLow =  pageCutLow -1;    }

      this.activePage = page;
      for (let p = pageCutLow; p <= pageCutHigh; p++) {
        if (p === 0) { p += 1; }
        if (p > pages) { continue }
        this.arrayPage.push({ type : 'page', value : p})
      }

      //para mostrar 4 items por default
      if (page < pages-1) {
        if (this.activePage <= pages-4) {
          this.arrayPage.push({ type : 'page', value : '...'})
          this.arrayPage.push({ type : 'page', value : pages})
        }else{
          if (this.arrayPage[1].value < pages -3 ) {
            this.arrayPage.push({ type : 'page', value : pages})
          }
        }
      }

    //para mostrar 3 items por default
    //  if (page < pages-1) {
    //   if (page < pages-2) {
    //     this.arrayPage.push({ type : 'page', value : '...'})
    //   }
    //   this.arrayPage.push({ type : 'page', value : pages})
    //  }

    }

    if (page < pages) this.arrayPage.push({ type : 'imagen-rigth',value :pages,  img : 'assets/imagenes/iconos/icon-paginator-rigth.svg' , direction : 'rigth'});
    if (page == pages) this.arrayPage.push({ type : 'imagen-rigth', value : pages,  img :  'assets/imagenes/iconos/icon-paginator-rigth-disabled.svg' , direction : 'rigth'});
  }

}

