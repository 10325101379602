import { Pipe, PipeTransform, } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'formatAmount'
})
export class FormatAmountPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) { }

  transform(value: any, ...args: any[]): any {
    const parsed = parseFloat(value.replace(/,/gi, ''));

    if (!value) return value;

    return this.decimalPipe.transform(parsed, '2.2-2', 'en-US');
  }
}
