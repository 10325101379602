import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
const getStyles = (...args: string[]) => [...args].filter(Boolean)
export enum EInputValidation {
  Number = 'number',
  Alpha = 'alpha',
  Alphanumeric = 'alphanumeric',
  Text = 'text',
  digitsOnly = 'number',
}

@Component({
  selector: 'cbx-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent implements OnChanges {
  @Input() typeToggle:
  "ClassicToggle" | 'other' = "ClassicToggle";
  @Input() idCheck!:string;
  @Input() text = '';
  @Input()
  colorTextChecked!: string;
  @Input() colorTextNotChecked!: string;
  @Input() bgChecked!: string;
  @Input() bgNotChecked!: string;
  @Input() borderColorChecked! : string;
  @Input() borderColorNotChecked!: string;
  @Input() checked = true;
  @Input() disabled = false;
  @Output() changed = new EventEmitter();
  public get typeToggles(): string[] {
    return getStyles(this.typeToggle)
  }
  constructor(private elRef: ElementRef){
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.checked ? this.elRef?.nativeElement?.style?.setProperty('--colorText',this.colorTextChecked):this.elRef?.nativeElement?.style?.setProperty('--colorText',this.colorTextNotChecked);
  }
 

  UpdateCustomProperties(){
    this.elRef.nativeElement.style.setProperty('--bgChecked',this.bgChecked);
    this.elRef.nativeElement.style.setProperty('--bgNotChecked',this.bgNotChecked);
    this.elRef.nativeElement.style.setProperty('--colorTextChecked',this.colorTextChecked);
    this.elRef.nativeElement.style.setProperty('--colorTextNotChecked',this.colorTextNotChecked);
    this.elRef.nativeElement.style.setProperty('--borderColorChecked',this.borderColorChecked);
    this.elRef.nativeElement.style.setProperty('--borderColorNotChecked',this.borderColorNotChecked);
    this.checked ? this.elRef.nativeElement.style.setProperty('--colorText',this.colorTextChecked):this.elRef.nativeElement.style.setProperty('--colorText',this.colorTextNotChecked);
  }
  ngAfterContentInit(){
    this.UpdateCustomProperties();
  }
  

  onChanged(event: any) {
    if (!this.disabled) {
      this.checked=event.target.checked;
      this.checked ? this.elRef.nativeElement.style.setProperty('--colorText',this.colorTextChecked):this.elRef.nativeElement.style.setProperty('--colorText',this.colorTextNotChecked);
      this.changed.emit(event);
    }
  }
}

