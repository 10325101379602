<div class="secure-url">
  <div class="logo">
    <img src="assets/landing/icons/logotipo-cambix.svg" alt="Cambix">
  </div>
  <div class="warning">
    <div>
      <p>Está saliendo de la zona segura de Cambix...</p>
      <p>Se le redireccionará en</p>
      <h2>5</h2>
      <!-- <h2>{{timer$ | async}}</h2> -->
    </div>
  </div>
</div>
