<div class="label" *ngIf="label">
  {{label}}
</div>

<div [ngClass]="typeClass">
  <input
    pdDigitsOnly
    keyValidation="decimal"
    inputmode="numeric"
    class="input font-secondary-bold color-black-300"
    (input)="onChange($event.target)"
    (blur)="onBlur($event.target)"
    (focus)="onFocus($event.target)"
    [decimal]="true"
    [type]="type"
    [maxLength]="maxLength"
    [value]="value"
    [disabled]="disabled"
    [readonly]="isReadOnly"
    [style.width]="width">

  <div class="currencyInput">{{ typeCurrency }}</div>
</div>

<div class="wrapper-error">
  <div class="error" *ngIf="errorMsg">
    {{ errorMsg }}
  </div>
</div>
