<ng-container [ngSwitch]="typeInputs">
    <ng-container *ngSwitchCase="'CalendarCambix'">
        <div class="CalendarCambix">
            <div class="input-container" [style.width]="width" #inputCalendario >
                <input
                    type="text"
                    id="calendario"
                    [style.height]="height"
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    [readonly]="readOnly"
                    maxlength="10"
                    [value]="value"
                    [ngClass]="{
                        'focus-input': showCalendar,
                        'text-input' : !showCalendar,
                        'error-input' : msgError
                    }"
                    (input)="onChange($event.target)"
                    (focus)="onFocus(true)"
                    autocomplete="off"
                    #cbxDropDownList
                >
                <label for="calendario" class="label"> {{label}} </label>
                <div class="arrow" *ngIf="!loading">
                    <img src="assets/imagenes/auth/icon-calendar.svg">
                </div>
                <div class="loading" *ngIf="loading">
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div [ngClass]="classInfo" [style.width]="width">
                <div *ngIf="hasError" [ngClass]="{ 'message-error' : notSelectDateStyle, 'message-error--hidden' : !notSelectDateStyle }"    >
                    {{msgError}}
                </div>
            </div>
            <div class="body-container" *ngIf="showCalendar" #bodyCalendar>
                <div class="wrapper-list-found" >
                    <!-- CALENDARIO -->
                    <cbx-datepicker
                        typeInput="Classic"
                        [existeFecha]="existeFecha"
                        (enviarFecha)="getFecha($event)"  >
                    </cbx-datepicker>
                </div>
            </div>

        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'CalendarFilter'">
        <div class="CalendarFilter">
            <div class="input-container" [style.width]="width" #inputCalendario >
                <input
                    type="text"
                    id="calendario"
                    [style.height]="height"
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    [readonly]="readOnly"
                    maxlength="10"
                    [value]="value"
                    class="text-input"
                    [ngClass]="{
                        'focus-input': existeDateFilter,
                        'text-input': !existeDateFilter,
                    }"
                    (input)="onChange($event.target)"
                    (focus)="onFocus(true)"
                    autocomplete="off"
                    #cbxDropDownList>
            </div>
            <div *ngIf="showCalendar" #bodyCalendar>
                <div [ngClass]="{'wrapper-list-found': placeholder === 'Desde' , 'wrapper-list-found--right' : placeholder === 'Hasta'  }"  >
                    <cbx-datepicker-filter
                        typeInput="Filter"
                        [typeDate]="typeDate"
                        [dateFrom]="dateFrom"
                        [dateUntil]="dateUntil"
                        (sendDate)="getFechaCalendarFilter($event)"
                    >
                    </cbx-datepicker-filter>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'CalendarArray'">
        <div class="CalendarArray">
            <div class="input-container" [style.width]="width" #inputCalendario >
                <input
                    type="text"
                    id="calendario"
                    [style.height]="height"
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    [readonly]="readOnly"
                    maxlength="10"
                    [value]="value"
                    [ngClass]="{
                        'focus-input': showCalendar,
                        'text-input' : !showCalendar,
                        'error-input' : msgError
                    }"
                    (input)="onChange($event.target)"
                    (focus)="onFocus(true)"
                    autocomplete="off"
                    #cbxDropDownList
                >
                <label for="calendario" class="label"> {{label}} </label>
                <div class="arrow" *ngIf="!loading">
                    <img src="assets/imagenes/auth/icon-calendar.svg">
                </div>
                <div class="loading" *ngIf="loading">
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div [ngClass]="classInfo" [style.width]="width">
                <div *ngIf="hasError" [ngClass]="{ 'message-error' : notSelectDateStyle, 'message-error--hidden' : !notSelectDateStyle }"    >
                    {{msgError}}
                </div>
            </div>
            <div class="body-container" *ngIf="showCalendar" #bodyCalendar>
                <div class="wrapper-list-found" >
                    <!-- CALENDARIO -->
                    <cbx-datepicker
                        typeInput="ClassicArray"
                        [existeFecha]="existeFecha"
                        (enviarFecha)="getFechaArray($event)"  >
                    </cbx-datepicker>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'Others'">
        <div class="Others">
            <div class="input-container" [style.width]="width" #inputCalendario >
                <input
                    type="text"
                    id="calendario"
                    [style.height]="height"
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    [readonly]="readOnly"
                    maxlength="10"
                    [value]="value"
                    [ngClass]="{
                        'focus-input': showCalendar,
                        'text-input' : !showCalendar,
                        'error-input' : msgError
                    }"
                    (input)="onChange($event.target)"
                    (focus)="onFocus(true)"
                    autocomplete="off"
                    #cbxDropDownList
                >
                <label for="calendario" class="label"> {{label}} </label>
                <div class="arrow" *ngIf="!loading">
                    <img src="assets/imagenes/auth/icon-calendar.svg">
                </div>
                <div class="loading" *ngIf="loading">
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div [ngClass]="classInfo" [style.width]="width">
                <div *ngIf="hasError" class="message-error" >
                    {{msgError}}
                </div>
            </div>
            <div *ngIf="showCalendar" #bodyCalendar>
                <div class="wrapper-list-found" >
                    <!-- CALENDARIO -->
                    <cbx-datepicker
                        typeInput="Others"
                        [existeFecha]="existeFecha"
                        (enviarFecha)="getFecha($event)" >
                    </cbx-datepicker>
                </div>
            </div>

        </div>
    </ng-container>
</ng-container>
