import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
const getStyles = (...args: string[]) => [...args].filter(Boolean)
export enum EInputValidation {
  Number = 'number',
  Alpha = 'alpha',
  Alphanumeric = 'alphanumeric',
  Text = 'text',
  digitsOnly = 'number',
}

@Component({
  selector: 'cbx-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnChanges {

  @Input() idTextField = 'idTextField';
  @Input() placeholder = ' ';
  @Input() label = 'label';
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() value : string = "";
  @Input() maxLength = '60';
  @Input() size = 'md';
  @Input() paste = true;
  @Input() msgError = "";
  @Input() msgInfo = "";
  @Input() hasCounter = false;
  @Input() width = '';
  @Input() height = '48px';
  @Input() icon = '';
  @Input()
  inputValidation!: EInputValidation;
  @Output() public valueChanged = new EventEmitter();
  @Output() public blurred = new EventEmitter();
  @Output() public enter = new EventEmitter();
  @Input() typeInput:
    "ClassicInput" | "ClassicInputCompany" | "InputWithoutTitle" | "ClassicInputPass" = "ClassicInput";
  @Input() loading = false;
  @Input() readOnly=false;
  hasError = false;
  hasInfo = false;
  maxLimitLength = 60;
  maxInputLength = this.maxLimitLength;
  expression!: RegExp;
  counter = 0;
  total = 60;
  hasIcon = false;
  

  classInput = {
    'text-input': true,
    'error-input': false
  };
  classInfo = {
    'info-container': true,
    'counter-container': false
  };

  public get typeInputs(): string[] {
    return getStyles(this.typeInput)
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.msgInfo != "" && this.msgError != "") {
      this.hasInfo = false;
      this.hasError = true;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    if (this.msgInfo == "" && this.msgError == "") {
      this.hasInfo = false;
      this.hasError = false;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    if (this.msgInfo == "" && this.msgError != "") {
      this.hasInfo = false;
      this.hasError = true;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    if (this.msgInfo != "" && this.msgError == "") {
      this.hasInfo = true;
      this.hasError = false;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    if (this.hasCounter && (this.msgError == "" && this.msgInfo == "")) {
      this.classInfo['info-container'] = false;
      this.classInfo['counter-container'] = true;
    }
    else {
      this.classInfo['info-container'] = true;
      this.classInfo['counter-container'] = false;
    }
    if (this.icon != "") this.hasIcon = true;
    else this.hasIcon = false;

    this.total = Number(this.maxLength);



  }
  onChange(target: any) {
    switch (this.inputValidation) {
      case EInputValidation.digitsOnly:
        this.expression = /[^0-9]/g, '';
        break;
      case EInputValidation.Number:
        this.expression = /[A-Za-zÑÁÉÍÓÚñáéíóú`~!¡@#$%{]}¿[^&*()_|+\-=?;:'",.<>°]/g;
        break;
      case EInputValidation.Alpha:
        this.expression = /[0-9`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Alphanumeric:
        this.expression = /[`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Text:
        this.expression = /[0-9`~!¡@#$%^&*()_|+\-=?;:'",.<>°]/g;
        break;
    }
    if (this.expression) {
      target.value = target.value
        .replace(this.expression, '');

    }
    target.value = target.value.substr(0, Number(this.maxLength));
    this.counter = target.value.length;
    this.valueChanged.emit(target.value);
  }

  onBlur(target: any) { 
    this.blurred.emit(target.value);
  }

  onEnter(event: any, value: any) {
    this.enter.emit(value || event.target.value);
  }

  onPaste(event: ClipboardEvent) {
    if ((window as any).clipboardData) {
      const pastedText = (window as any).clipboardData;
    } else {
      const pastedText = event?.clipboardData?.getData('text');
    }
    return this.paste;
  }

 
}

