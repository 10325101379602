import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppObservable } from '../../observables/app.observable';
import { environment } from 'apps/cambix/src/environments/environment';

import { STORAGE_KEY } from 'apps/cambix/src/utils/constants/storage';
export interface CookieDetail {
  id: string;
  cookieGeneralName: string;
  openImgUrl: string;
  closeImgUrl: string;
  isOpenedDetail: boolean;
  foreverActive: boolean;
  detailCookie: string;
  cookies: CookieExplained[];
}

interface CookieExplained {
  name: string;
  supplier: string;
  purpose: string;
  expiration: string;
  kind: string;
  category: string;
}

@Component({
  selector: 'app-cookies-config',
  templateUrl: './cookies-config.component.html',
  styleUrls: ['./cookies-config.component.scss']
})
export class CookiesConfigComponent {


  formCookies: FormGroup;
  stadisticCookies: string[] = ["_ga_K6QS7VCY15", "_gat_gtag_UA_219829640_2", "_ga", "_gid"];
  mktCookies: string[] = ["_fbp"];
  cookies: CookieDetail[] = [

    {
      id: "CE",
      cookieGeneralName: "Cookies estadísticas",
      openImgUrl: "assets/icons/plus-circle.svg",
      closeImgUrl: "assets/icons/minus-circle.svg",
      isOpenedDetail: false,
      foreverActive: false,
      detailCookie:
        "Las cookies estadísticas ayudan a los propietarios de páginas web a comprender cómo interactúan los visitantes con las páginas web reuniendo y proporcionando información de forma anónima, nos ayuda a mejorar la experiencia del usuario en la plataforma.",
      cookies: [
        {
          name: "_ga_K6QS7VCY15",
          supplier: "Google",
          purpose:
            "Recopila datos sobre el número de veces que un usuario ha visitado el sitio web además de las fechas de la primera visita y de la más reciente. Utilizada por Google Analytics.",
          expiration: "1 año",
          kind: "HTTP",
          category: "Estadística"
        },
        {
          name: "_gat_gtag_UA_219829640_2",
          supplier: "Google",
          purpose:
            "Esta cookie de Google Analytics es de análisis y sirve para medir como los usuarios interactúan con nuestra página web. ",
          expiration: "Hasta el fin de la sesión ",
          kind: "HTTP",
          category: "Estadística"
        },
        {
          name: "_ga",
          supplier: "Google",
          purpose:
            "Registra una identificación única que se utiliza para generar datos estadísticos acerca de cómo utiliza el visitante el sitio web.",
          expiration: "2 años",
          kind: "HTTP",
           category: "Estadística"
        },
        {
          name: "_gid",
          supplier: "Google",
          purpose:
            "Esta cookie sirve para dar un código de identificación diferente a cada página e indicar la fecha.",
          expiration: "24 horas",
          kind: "HTTP",
           category: "Estadística"
        },

      ],
    },
    {
      id: "CMKT",
      cookieGeneralName: "Cookies Marketing",
      openImgUrl: "assets/icons/plus-circle.svg",
      closeImgUrl: "assets/icons/minus-circle.svg",
      isOpenedDetail: false,
      foreverActive: false,
      detailCookie:
        "Las cookies de marketing se utilizan para rastrear a los visitantes en las páginas web. La intención es mostrar anuncios relevantes y atractivos para el usuario individual, y por lo tanto, más valiosos para los editores y terceros anunciantes.",
      cookies: [
        {
          name: "_fbp",
          supplier: "Meta",
          purpose:
            "Cookie de Meta, analiza la actividad de navegación en nuestros sitios web para realizar un seguimiento de la eficacia de la publicidad, personalizar las audiencias y mejorar la reorientación de anuncios.",
          expiration: "3 meses",
          kind: "HTTP",
          category: "Marketing"
        },
      ],
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<CookiesConfigComponent>,
    private cookieService: CookieService,
    private appObservable: AppObservable,
    private formBuilder: FormBuilder,
  ) {
    this.buildFormCookies();
  }

  ngOnInit() {
  }

  closeDialog(): void {
    this.dialogRef.close(true);
  }

  buildFormCookies(): void {
    this.formCookies = this.formBuilder.group({
      CE: new FormControl(false, Validators.compose([Validators.required])),
      CMKT: new FormControl(false, Validators.compose([Validators.required])),
    });

    this.formCookies.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((data) => {});
  }

  acceptAllCookies(): void {
    localStorage.setItem(STORAGE_KEY.accept_cookies, "yes");
    const cookies = this.appObservable.getTemporalCookies();
    localStorage.setItem(STORAGE_KEY.acceptedCookies, JSON.stringify(cookies));
    for (const key in cookies) {
      if (cookies) {
        this.cookieService.set(key, cookies[key]);
      }
    }
    this.dialogRef.close();
  }

  saveConfigurationCookies(): void {
    const acceptedCookies = {};
    const cookies = this.appObservable.getTemporalCookies();

    if (this.formCookies.controls['CE'].value) {
      this.stadisticCookies.forEach((cookie) => {
        if (cookies.hasOwnProperty(cookie)) {
          this.cookieService.set(cookie, cookies[cookie]);
          acceptedCookies[cookie] = cookies[cookie];
        }
      });
    }

    if (this.formCookies.controls['CMKT'].value) {
      this.mktCookies.forEach((cookie) => {
        if (cookies) {
          this.cookieService.set(cookie, cookie);
          acceptedCookies[cookie] = cookie;
        }
      });
    }

    localStorage.setItem(STORAGE_KEY.accept_cookies, "custom");
    localStorage.setItem(STORAGE_KEY.acceptedCookies, JSON.stringify(acceptedCookies));
    this.dialogRef.close();
  }

  openCookieDetail(cookie: CookieDetail, event): void {
    document.querySelectorAll(".toggle-content-cookie").forEach((cElem) => {
      cElem.classList.remove("toggle-open");
    });

    const clicked = event.target;
    const container = clicked.closest(".accordion-item-cookie");
    const cookieCont = container.querySelector(".toggle-content-cookie") as HTMLElement;

    this.cookies.forEach((cc: CookieDetail) => {
      if (cc !== cookie) {
        cc.isOpenedDetail = false;
      }
    });
    if (cookie.isOpenedDetail) {
      cookieCont.classList.remove("toggle-open");
    } else {
      cookieCont.classList.add("toggle-open");
    }
    cookie.isOpenedDetail = !cookie.isOpenedDetail;
  }

  deleteInitialCookies() {
    const cookies = this.appObservable.getTemporalCookies();
    for (let key in cookies) {
      if (cookies.hasOwnProperty(key)) {
        this.cookieService.delete(key, "/");
        for (let index = 0; index < environment.domains.length; index++) {
          this.cookieService.delete(key, "/", environment.domains[index]);
        }
      }
    }
  }

}
