import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PaginatorService {

  private isPaginatorActive$ = new BehaviorSubject<number>(1);
  private  _isPagActive: Observable<number> = this.isPaginatorActive$.asObservable();

 
  setPagActive(value: number) {
    this.isPaginatorActive$.next(value);
  }

  getPagActive(): Observable<number> {
    return this._isPagActive;
  }

 
 
}
