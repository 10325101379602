import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CbxLayoutService } from '../../service/layout/responsive.service';
const getStyles = (...args: string[]) => [...args].filter(Boolean);
const LEVEL = {
  PLATA: 'silver',
  ORO: 'gold',
  BRONCE: 'bronze',
};

@Component({
  selector: 'cbx-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss'],
})
export class ProgressBarComponent {
  @Input() type = 'Classic';
  @Input() total = 0;
  @Input() points = 0;
  @Input() level: 'PLATA' | 'ORO' | 'BRONCE' = 'PLATA';
  @Input() percent = '30%';
  @Input() height = '';
  @Input() hasIcon = true;
  @Input() hasStatus = true;
  size = '';
  ClassSize = '';
  constructor(private cbxLayout: CbxLayoutService) {
    this.cbxLayout.currentScreenSize$.subscribe((currentSize) => {
      this.size = currentSize;
      this.ClassSize = `${this.type}__${this.size}`;
    });
  }
  public get typeProgress(): string[] {
    return getStyles(this.type);
  }
  public get levelText(): string {
    return (
      this.level.charAt(0).toUpperCase() + this.level.slice(1).toLowerCase()
    );
  }
  public get levelImg(): string {
    return LEVEL[this.level];
  }
}
