<div
    class="funds"
    [style.width]="width"
>
    <div class="funds__content">
        <div class="imagen">
            <img src="../../../../assets/imagenes/iconos/icon-verifica-cellphone.svg">
        </div> 
     
        <div>
            <div class="otp-dialog">
                <div class="otp-dialog font-primary-bold-h6 mb-3">Verificaremos el número de celular</div>

                <div *ngIf="!flgValidarCellphone" class="otp-dialog font-secondary-regular-16 mb-3" >
                    Se envió un código de 6 dígitos al celular
                </div>

                <div class="otp-dialog  font-primary-bold-h5 mb-2">
                    <div class="formulario-input" *ngIf="flgValidarCellphone" >
                        <form [formGroup]="newCellPhoneForm">
                            <input type="text"
                                maxlength="9"
                                class="input-class-cellphone"
                                formControlName="cellphone"
                                cellphoneValidator
                                #inputnewCellphone >
                            <span class="text-error-cellphone font-secondary-regular-12 color-secondary"
                                *ngIf="getErrorCellphone">
                                {{getErrorCellphone}}
                            </span>
                        </form>
                    </div>
                    <div *ngIf="!flgValidarCellphone" class="color-black-300" >
                        {{ data.cellphone }}
                    </div>
                </div>


      

                <div class="otp-dialog__corregirCode color-black font-secondary-bold-14">
                    <p *ngIf="flgValidarCellphone"  [ngClass]="{ 
                        'otp-dialog__corregirCode--active': newCellPhoneForm.valid,
                        'otp-dialog__corregirCode--disabled': !newCellPhoneForm.valid}" 
                    (click)="onValidarCellphone()">
                        Validar celular
                    </p> 

                    <p *ngIf="!flgValidarCellphone"  class="otp-dialog__corregirCode--active"
                    (click)="onCorregirCellphone()">
                        Corregir celular
                    </p>  
                </div>



                <form [formGroup]="otpCodeForm">
                    <div
                        class="otp-dialog__code"
                        [ngClass]="{ 
                            'otp-dialog__code-error': data.error || flgErroCodeService, 
                            'otp-dialog__code' : !flgResendCode || !data.error,
                            'otp-dialog__code-flgTimeoOut' : flgTimeoOut
                         }"
                    >
                        <input
                            type="text"
                            formControlName="digit01"
                            maxlength="1"
                            pdDigitsOnly
                            #digit1
                            cdkFocusInitial
                            (keyup)="moveDigit($event,'',digit1,digit2,0)"
                            [readonly]="flgTimeoOut"
                            (paste)="pasteDigit('','','','','',$event.clipboardData?.getData('text/plain'),true)"
                        >
                        <input
                            type="text"
                            formControlName="digit02"
                            maxlength="1"
                            #digit2
                            pdDigitsOnly
                            (keyup)="moveDigit($event,digit1,digit2,digit3,1)"
                            [readonly]="flgTimeoOut"
                        >
                        <input
                            type="text"
                            formControlName="digit03"
                            maxlength="1"
                            pdDigitsOnly
                            #digit3
                            (keyup)="moveDigit($event,digit2,digit3,digit4,2)"
                            [readonly]="flgTimeoOut"
                        >
                        <input
                            type="text"
                            formControlName="digit04"
                            maxlength="1"
                            pdDigitsOnly
                            #digit4
                            (keyup)="moveDigit($event,digit3,digit4,digit5,3)"
                            [readonly]="flgTimeoOut"
                        >
                        <input
                            type="text"
                            formControlName="digit05"
                            maxlength="1"
                            pdDigitsOnly
                            #digit5
                            (keyup)="moveDigit($event,digit4,digit5,digit6,4)"
                            [readonly]="flgTimeoOut"
                        >
                        <input
                            type="text"
                            formControlName="digit06"
                            maxlength="1"
                            pdDigitsOnly
                            #digit6
                            (keyup)="moveDigit($event,digit5,digit6,'',5)"
                            [readonly]="flgTimeoOut"
                        >
                    </div>
                </form>
                <div *ngIf="loaderOtp" class="loader-line"
                ></div>
                <div class="divTiempo font-secondary-regular-12">
                    <div [ngClass]="{ 'text-error': flgErroCodeService && !loaderOtp,'text-error-hideen': !flgErroCodeService || loaderOtp }"
                        class="ml-4 color-secondary">
                        Código incorrecto
                    </div>
                    <div class="temporizador">
                        <img src="../../../../assets/imagenes/auth/icon-reloj.svg" alt="icono de reloj" >
                        <p class="color-black font-secondary-bold">
                            {{ cronometro != null ? cronometro : '05 : 00'}}
                        </p>
                    </div>
                </div>



                <div class="resend mt-4 font-secondary-bold-14" > 
                    <p (click)="resendCode()" [ngClass]="{ 'btn-resend--active': flgResendCode,'btn-resend--processing': !flgResendCode }">
                        Reenviar código
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
