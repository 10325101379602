import { Component,Input } from '@angular/core';
import { PreloaderService } from '../../service/preloader.service';

const getStyles = (...args: string[]) => [...args].filter(Boolean);

@Component({
  selector: 'cbx-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent {

  @Input() isShow : boolean = false;
  @Input() icon : string = '';
  @Input() type : "PreloaderPrimary" | "PreloaderSecondary" = "PreloaderPrimary";
 
  constructor(  
    private service : PreloaderService
  ){  
    
    this.service.getpreloader()
    .subscribe(resp  =>this.isShow = resp);
    
  }

  public get typeClass(): string[] {
    return getStyles(this.type)
   } 
   
  
}
