import { Component, Inject, SecurityContext} from '@angular/core';
import { DialogRef } from 'libs/cambix/ui/src/directives/dialog-cdk-ref';
import { DIALOG_DATA } from 'libs/cambix/ui/src/directives/dialog-cdk-tokens';
import { Subject,} from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'cambix-web-v2.0-dialog-delete',
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.scss'],
})
export class DialogDeleteComponent {
  loadingButtonConfirm = false;
  destroy = new Subject<void>();
  bodyHtml : any ="";

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any, 
    private sanitizer: DomSanitizer
  ) {
   
  }
  ngOnDestroy(): void {
    this.destroy.next();
  }

  ngOnInit(): void {
    this.bodyHtml =  this.sanitizer.sanitize(SecurityContext.HTML, this.data.body);
  }
  
  onAceptar() {
    this.dialogRef.close(true);

  }
  onCancel() {
    this.dialogRef.close(false); 
  }

}
