import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
const getStyles = (...args: string[]) => [...args].filter(Boolean)
@Component({
  selector: 'cbx-calendar-dropdown',
  templateUrl: './calendar-dropdown.component.html',
  styleUrls: ['./calendar-dropdown.component.scss'],
})
export class CalendarDropdownComponent {


  @Input() placeholder = ' ';
  @Input() label = 'label';
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() value = '';
  @Input() maxLength = '60';
  @Input() size = 'md';
  @Input() width = '';
  @Input() height = '25px';
  @Output() public valueChanged = new EventEmitter();
  @Output() public blurred = new EventEmitter();
  @Output() public enter = new EventEmitter();
  @Output() public dataValue = new EventEmitter();
  @Input() typeInput: "Classic" | "Others" = "Classic";
  @Input() data: any = [];
  maxLimitLength = 60;
  expression!: RegExp;
  @Input() typeView: "FULL" | "EMPTY" | "" = "EMPTY";
  showCombo: Boolean = false;
  classInput = {
    'text-input': true,
    'error-input': false,
  };
  classInfo = {
    'info-container': true,
    'counter-container': false
  };
  arrow='arrow-down-gris';
  isDropdownOpen: boolean = false;

  public get typeInputs(): string[] {
    return getStyles(this.typeInput)
  }

  getArrow(){
    return this.showCombo == false ? this.arrow='arrow-up' : this.arrow='arrow-down-gris';
  }

  getElement(element: any) {
      this.showCombo = false;
      this.getArrow();
      this.dataValue.emit(element);
  }

  toggleDropdown() {
    if(this.disabled) return;
    this.showCombo = !this.showCombo;
    if (this.showCombo) this.typeView = 'FULL';
    else
      this.typeView = 'EMPTY';
    this.UpdateArrow();
  }

  UpdateArrow() {
    return this.typeView === 'FULL' ? this.arrow = 'arrow-up' : this.arrow = 'arrow-down-gris';
  }


}


