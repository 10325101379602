<ng-container [ngSwitch]="typeInputs">
    <ng-container *ngSwitchCase="'Classic'">
        <div class="Classic"    >
           
            <div class="content-input">
                <div class="input-container"  >
                    <input
                    type="text"     
                    [id]="idTextField"  
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    [readonly]="true"
                    [value]="value"
                    [ngClass]="classInput"
                    (input)="onChange($event.target)" 
                    autocomplete="off"
                    #cbxDropDownList
                    (click)="toggleDropdown()"
                >
                <label [htmlFor]="idTextField" class="label"> {{label}} </label>
                <div class="arrow" (click)="toggleDropdown()">
                    <img src="assets/imagenes/dropdown/{{arrow}}.svg">
                </div> 
                </div>
                <div *ngIf="value">
                    <img src="assets/imagenes/dropdown/{{arrow}}.svg">
                </div>
            </div>

            <ng-container [ngSwitch]="typeView">
                <ng-container *ngSwitchCase="'EMPTY'">
                    <div class="wrapper-filter">
                        <div class="empty"></div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'FULL'">
                    <div class="wrapper-list">
                        <ng-container>
                            <div class="wrapper-list__option">
                                <!-- <cbx-legal-representative-form> </cbx-legal-representative-form> -->
                                Hola
                            </div>
                        </ng-container> 
                    </div>
                </ng-container>
            </ng-container> 
        </div>
    </ng-container> 
</ng-container>
