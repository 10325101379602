import { Injectable } from "@angular/core";
import { environment } from "apps/cambix/src/environments/environment";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class AppVersionService {

  private _cookiesEnabled: boolean;
  private _keyName = 'bc-a8fa885c';

  constructor(
    private cookieService: CookieService
  ) {
    this._cookiesEnabled = this.areCookiesEnabled();
  }

  /**
   * Check if there is a new app version
   */
  hasNewVersion(): boolean {
    // if(this._cookiesEnabled){
    //   return this.whenCookiesEnbled();
    // }

    return this.whenLocalhostEnbled();
  }

  /**
   * Retrieve the latest app version
   */
  getLatestVersion(): string {
    return environment.release;
  }

  /**
   * Set the current app version
   * @param version - version to set
   */
  setAppCurrentVersion(version: string): void {
    // if(this._cookiesEnabled) {
    //   this.cookieService.set(this._keyName, version);
    //   return;
    // }

    localStorage.setItem(this._keyName, version);
  }

  /**
   * Check if there is a new app version when cookies
   * are enabled in the browser
   */
  private whenCookiesEnbled(): boolean {
    const current = this.cookieService.get(this._keyName);
    const latest = environment.release;

    if(!latest) {
      return false;
    }

    if(current !== latest) {
      return true;
    }

    return false;
  }

  /**
   * Check if there is a new app version when cookies
   * are NOT enabled in the browser
   */
  private whenLocalhostEnbled(): boolean {
    const current = localStorage.getItem(this._keyName);
    const latest = environment.release;

    if(!latest) {
      return false;
    }

    if(current !== latest) {
      return true;
    }

    return false;
  }

  /**
   * Check if cookies are enabled in the browser
   */
  private areCookiesEnabled(): boolean {
    let cookieEnabled = navigator.cookieEnabled ? true : false;

    if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled)
    {
      document.cookie="testcookie";
      cookieEnabled = (document.cookie.indexOf("testcookie") != -1) ? true : false;
    }

    return (cookieEnabled);
  }
}
