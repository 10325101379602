import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IChangePass, IBusinessLeadV6, IuserLeadV1 } from '../../auth/interface/register.interface';

@Injectable()
export class newRegisterObservable {
  business!: IBusinessLeadV6;
  usuario!: IuserLeadV1;
  usuarioChagePass!: IChangePass;
  flgGoogle: boolean = false;
 

  private setnewuser = new BehaviorSubject<IuserLeadV1>(this.usuario);
  public getnewuser = this.setnewuser.asObservable();

  private setFlgRegGoogle = new BehaviorSubject<boolean>(this.flgGoogle);
  public getFlgRegGoogle = this.setFlgRegGoogle.asObservable();
 
  private setuserChangePass = new BehaviorSubject<IChangePass>(this.usuarioChagePass);
  public getUserChangePass = this.setuserChangePass.asObservable();

  private PreLoader = new BehaviorSubject<boolean>(false);
  public getPreLoader = this.PreLoader.asObservable();


  //COMPANY
  private setnewBusiness = new BehaviorSubject<IBusinessLeadV6>(this.business);
  public getnewBusiness = this.setnewBusiness.asObservable();
  constructor() {
  }

  setNewBusiness(val: IBusinessLeadV6) {
    this.setnewBusiness.next(val);
  }

  setUser(val: IuserLeadV1) {
    this.setnewuser.next(val);
  }

  setUserChangePass(val: IChangePass) {
    this.setuserChangePass.next(val);
  }
 
  setRegGoogler(val: boolean) {
    this.setFlgRegGoogle.next(val);
  }
 
  setPreLoader(val: boolean) {
    this.PreLoader.next(val);
  }




}
