import { Component, EventEmitter, Input, Output } from '@angular/core';
const getStyles = (...args: string[]) => [...args].filter(Boolean)


@Component({
  selector: 'cbx-trademark',
  templateUrl: './trademark.component.html',
  styleUrls: ['./trademark.component.scss'],
})
export class TradeMarkComponent {
  @Input() type = "tradeMarkDefault";
  @Input()
  size = "sm";
  public get typeClass(): string[] {
    return getStyles(this.type)
  }
  public get typeSize(): string {
    return `${this.type}__${this.size}`;
  }


}

