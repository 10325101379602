<ng-container [ngSwitch]="typeToggles">
    <ng-container *ngSwitchCase="'ClassicToggle'">
        <div class="ClassicToggle">
            <input
                type="checkbox"
                class="toggle_checkbox"
                [id]="idCheck"
                [checked]="checked"
                [disabled]="disabled"
                (change)="onChanged($event)"
            >
            <label [htmlFor]="idCheck">{{ text }}</label>
        </div>
    </ng-container>
</ng-container>
