import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";   

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {
 
 preloader: boolean = false;


  private preloader$ = new BehaviorSubject<boolean>(this.preloader);
  private  _preloader: Observable<boolean> = this.preloader$.asObservable();
 
  setpreloader(data: boolean) { 
    this.preloader$.next(data);
  }

   getpreloader(): Observable<boolean> {
    return this._preloader;
  }

 
}
