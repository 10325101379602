<ng-container [ngSwitch]="checkboxStyle">
    <ng-container *ngSwitchCase="'classicCheckbox'">
        <label class="classicCheckbox">
            <input 
                [id]="id"
                type="checkbox" 
                [disabled]="isDisabled" 
                [value]="isChecked"
                (change)="onValueCheck($event)"
                [checked]="isChecked" >
            <span></span>
        </label>
    </ng-container>
    <ng-container *ngSwitchCase="'AuthCheckbox'">
        <label class="AuthCheckbox">
            <input 
                [id]="id"
                type="checkbox" 
                [disabled]="isDisabled" 
                [value]="isChecked"
                (change)="onValueCheck($event)"
                [checked]="isChecked" >
            <span></span>
        </label>
    </ng-container>
</ng-container>