import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CbxLayoutService {
  private _currentScreenSize$: Subject<string> = new BehaviorSubject('xl');

  readonly currentScreenSize$: Observable<string> = from(
    this._currentScreenSize$
  );

  private displayNameMap = new Map([
    ['(max-width: 599px)', 'xs'],
    ['(min-width: 600px) and (max-width: 959px)', 'sm'],
    ['(min-width: 960px) and (max-width: 1279px)', 'md'],
    ['(min-width: 1280px) and (max-width: 1919px)', 'lg'],
    ['(min-width: 1920px) and (max-width: 5000px)', 'xl'],
  ]);

  constructor(private breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        '(max-width: 599px)',
        '(min-width: 600px) and (max-width: 959px)',
        '(min-width: 960px) and (max-width: 1279px)',
        '(min-width: 1280px) and (max-width: 1919px)',
        '(min-width: 1920px) and (max-width: 5000px)',
      ])
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            return this._currentScreenSize$.next(
              this.displayNameMap.get(query) ?? 'xl'
            );
          }
        }
      });
  }
}
