import { Component, ElementRef, EventEmitter, Input, LOCALE_ID, Output, Renderer2 } from '@angular/core';
import { ICarouselDate } from '../../interfaces/carousel.interface';
import { RegisterOperationHttp } from 'apps/cambix/src/app/cambix/dashboard/change-dollar/commons/http/register-operation.http';
import { tap } from 'rxjs';
import localEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

import { STORAGE_KEY } from 'apps/cambix/src/utils/constants/storage';

registerLocaleData(localEs, 'es');

const getStyles = (...args: string[]) => ["data", ...args].filter(Boolean)

@Component({
  selector: 'cbx-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  providers : [{provide :LOCALE_ID, useValue: 'es'}]
})
export class CarouselComponent {

  @Input() position : string | null = 'vertical';
  @Input() type :   "CarouselDefault" | "CarouselMeses" | "CarouselPrimary" = "CarouselDefault";
  @Input() daySelect : any | null;
  @Output() changeMonth  = new EventEmitter();

  codeCarousel : any;
  arraySlidesLegend: any[] = [];
  arraySlidesService : ICarouselDate[] = [];
  slideService : any;
  intervalId: any;
  arrayRandom : any[] = [];

  public get typeClass(): string[] {
    return getStyles(this.type)
   }

   constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private registerService : RegisterOperationHttp
  ) {

  }

  ngOnInit(){
    if(this.type === 'CarouselPrimary'){
      this.registerService.getExternalProducts()
      .pipe(
        tap((data) => {
          this.arraySlidesService = data;
          const ID_RANDOM = localStorage.getItem(STORAGE_KEY.id_random);
          this.onRandom(ID_RANDOM);
       }
      ),
      ).subscribe();
    }
  }

  onRandom(idRandon : any){
    if(!idRandon){
      this.codeCarousel = Math.floor(Math.random() * this.arraySlidesService.length );
      window.localStorage.setItem(STORAGE_KEY.id_random, this.codeCarousel);
      this.onShowSlide(this.codeCarousel);
    }else{
      this.codeCarousel = Math.floor(Math.random() * this.arraySlidesService.length );
      if(idRandon == this.codeCarousel){
        this.onRandom(idRandon);
      }else{
        window.localStorage.setItem(STORAGE_KEY.id_random, this.codeCarousel);
        this.onShowSlide(this.codeCarousel);
      }
    }
  }


  autoMove(){
    this.intervalId = setInterval(() => {
      this.onChangeCodeRight();
    }, 8000);
  }

  onShowSlide(codeSlide : number){
    this.slideService = this.arraySlidesService[codeSlide];
    this.autoMove();
  }

   onChangeCodeLeft(){
    clearInterval(this.intervalId);
    let newCodeSlide = this.codeCarousel === 0
    ?  this.arraySlidesService.length -1
    :  this.codeCarousel - 1;
    this.codeCarousel = newCodeSlide;
    this.onAnimation();
    this.onShowSlide(newCodeSlide);
   }

   onChangeCodeRight(){
    clearInterval(this.intervalId);
    let newCodeSlide = (this.codeCarousel === this.arraySlidesService.length - 1 )
    ? 0
    : this.codeCarousel + 1;
    this.codeCarousel = newCodeSlide;
    this.onAnimation();
    this.onShowSlide(newCodeSlide)
   }


   onAnimation(){
    const elements = this.el.nativeElement.querySelectorAll('.sliders__slider');
    elements.forEach((element: any) => {
      if (!element.classList.contains('animation-fade-in')) {
        this.renderer.addClass(element, 'animation-fade-in');
        setTimeout(() => {
          this.renderer.removeClass(element, 'animation-fade-in');
        }, 200);
      }
    });
   }


   onChangeMonth(direction : any){
    this.onAnimation();
    let R_MONTH = new Date(new Date(this.daySelect).setMonth(this.daySelect.getMonth()+1));
    let L_MONTH = new Date(new Date(this.daySelect).setMonth(this.daySelect.getMonth()-1));

    let newMonth = direction === 'left' ? L_MONTH : R_MONTH;
    this.changeMonth.emit(newMonth);
   }



  ngOnDestroy() {
    window.localStorage.removeItem(STORAGE_KEY.id_random);
    clearInterval(this.intervalId);
  }

}
