<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'PaginatorBlog'">
        <div [ngClass]="typeClass">
            <div class="pagination">
                <div *ngFor="let item of arrayPage, let i = index" class="pagination-items">
                    <div [ngSwitch]="item.type"  >
                        <div *ngSwitchCase="'imagen-left'" class="item-btn"  (click)="createPagination(pages,item.value, item.type )" > 
                            <img [src]="item.img" alt="imagen" width="25" height="25">
                        </div>
                        <div *ngSwitchDefault (click)="createPagination(pages,item.value, 0)" [ngClass]="{ 'item' : item.value != activePage ,  'item--active' : item.value == activePage}"> 
                          {{ item.value }}
                        </div>
                        <div *ngSwitchCase="'imagen-rigth'" class="item-btn"  (click)="createPagination(pages,item.value, item.type )" > 
                            <img [src]="item.img" alt="imagen" width="25" height="25">
                        </div>
                    </div>  
                </div>
            </div>
        </div>   
    </ng-container>
    <ng-container *ngSwitchCase="'PaginatorPrimary'">
        <div [ngClass]="typeClass">
        <!-- other paginado -->
        </div>   
    </ng-container>
</ng-container>
  