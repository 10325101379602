import { Component, EventEmitter, Input, Output } from '@angular/core'; 
const getStyles = (...args: string[]) => [...args].filter(Boolean)

@Component({
  selector: 'cbx-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() id = "";
  @Input() type : "classicCheckbox" | "AuthCheckbox" =  "classicCheckbox" ;
  @Input() isDisabled = false;
  @Input() isChecked : boolean = false;
  @Output() valueCheck = new EventEmitter;

  public get checkboxStyle(): string[] {
    return getStyles(this.type)
  }
 

  onValueCheck(event : any){  
    if (!this.isDisabled) { 
      this.isChecked = event.target.checked;
      this.valueCheck.emit(event);
    }
  }
}