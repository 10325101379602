<div class="cookie-config-container">
  <div class="cookie-config-header">
    <p class="title-config-cookies">Configuración de cookies</p>
    <span class="close-cookie-config-container" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </span>
  </div>

  <div class="container-accordion">
    <form [formGroup]="formCookies">
      <div class="accordion-item-cookie" *ngFor="let cookie of cookies">
        <table class="table-cookie">
          <tbody>
            <tr>
              <td rowspan="2" class="td-icon-cookie">
                <a class="opener-button" (click)="openCookieDetail(cookie, $event)" >
                  <img [src]=" cookie.isOpenedDetail ? cookie.closeImgUrl : cookie.openImgUrl" alt="Open/Close Detail Cookie" />
                </a>
              </td>
              <td class="td-type-cookie">{{ cookie.cookieGeneralName }}</td>
              <td rowspan="2" class="td-active-cookie">
                <div class="pd-badge" *ngIf="cookie.foreverActive">Activas siempre</div>
                <mat-slide-toggle [formControlName]="cookie.id" color="primary" *ngIf="!cookie.foreverActive" ></mat-slide-toggle>
              </td>
            </tr>
            <tr>
              <td class="td-detail-cookie"> {{ cookie.detailCookie }} </td>
            </tr>
          </tbody>
        </table>

        <div class="toggle-content-cookie">
          <table class="cookie-table">
            <thead>
              <tr class="cookie-table__head__row">
                <th class="cookie-table__head__cell">Nombre</th>
                <th class="cookie-table__head__cell">Proveedor</th>
                <th class="cookie-table__head__cell">Propósito</th>
                <th class="cookie-table__head__cell">Caducidad</th>
                <th class="cookie-table__head__cell">Tipo</th>
                <th class="cookie-table__head__cell">Categoría</th>
              </tr>
            </thead>
            <tbody class="cookie-table__body">
              <tr *ngFor="let cc of cookie.cookies">
                <td class="cookie-table__body__cell">{{ cc.name }}</td>
                <td class="cookie-table__body__cell">{{ cc.supplier }}</td>
                <td class="cookie-table__body__cell cookie-table__body__cell__purpose proposite-td-cookie">{{ cc.purpose }}</td>
                <td class="cookie-table__body__cell cookie-table__body__cell__expiration">{{ cc.expiration }}</td>
                <td class="cookie-table__body__cell">{{ cc.kind }}</td>
                <td class="cookie-table__body__cell">{{ cc.category }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>

  <div class="cookie-content-btns"> 
    <button
      type="button"
      class="btn-stroked btn-stroked__bg-light cbx-font-secondary-bold cookie-config__btn-save-config"
      (click)="saveConfigurationCookies()">Guardar configuración</button>
    <button
      type="button"
      class="btn-flat cbx-font-secondary-bold cookie-config__btn-accept-all"
      (click)="acceptAllCookies()">Aceptar todas</button>
  </div>
</div>