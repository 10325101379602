import { Component, EventEmitter, Input, Output } from '@angular/core';
const getStyles = (...args: string[]) => ['nombreBotton', ...args].filter(Boolean)

@Component({
  selector: 'cbx-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})

export class ButtonComponent {

  @Input() isDisabled       : boolean | null = false;
  @Input() isActive         : boolean | null = false;
  @Input() loadingdButton   : boolean = false;
  @Input() nombreBotton     : string | null = '';
  @Input() urlimagen        : string | null = '';
  @Input() size             : string | null = 'sm';
  @Input() sizeResponsive   : boolean | null = false;
  @Input() type             : 'Classic'  | 'ClassicCompany' | 'BtnTypeRegister' | 'BottonDefault' | 'BtnBack' | 'BottonMigration' | 'BottonPrimary' | 'BottonSecondary' | 'BottonTertiary' | 'BottonCuatertiary' | 'BottonTipoCambio' |'ButtonTransparentWhite'  | 'BottonGoogle'  = 'BottonDefault';

  @Output() clicked = new EventEmitter();

  public get typeClass(): string[] {
    return getStyles(this.type)
  }

  public get typeSize(): string {
    return `${this.type}__${this.size}`;
  }

  onClicked() {
    if (!this.isDisabled) {
      this.clicked.emit();
    }
  }
}
