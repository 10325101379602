import { Component } from '@angular/core';

@Component({
  selector: 'cbx-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent {

}
