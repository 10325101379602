 
  
  <div class="full-width">
    <div class="title">POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</div>
    <p class="paragraph paragraph__first">De conformidad con lo establecido en la Ley N° 29733, Ley de Protección de Datos Personales, y su Reglamento, aprobado por Decreto Supremo N° 003-2013-JUS y sus modificatorias; el Banco de Comercio S.A (en adelante, el Banco) ha implementado mecanismos y medidas técnicas organizativas y legales que nos permitan proteger su información personal y efectuar el tratamiento de los mismos. En consecuencia, bajo la presente política informa las condiciones bajo las cuales son tratados los datos personales de los clientes y/o no clientes, a nivel estrictamente informativo, lo que no constituye un medio para la recopilación de consentimientos.</p>
  
    <div class="subtitle">Responsable del tratamiento de datos personales</div>
    <p class="paragraph">Banco de Comercio S.A., con RUC N° 20509507199 y domicilio en Av. Canaval y Moreyra 452 - 454, San Isidro, Lima, Perú.</p>
    
    <div class="subtitle">Finalidades necesarias del tratamiento de datos personales</div>
    <p class="paragraph">El Banco de Comercio, tratará sus datos con las siguientes finalidades necesarias:</p>
    <ul class="list list__letters">
      <p class="subtitle">Prospecto de Clientes</p>
      <li class="list__letters list__item">Evaluar su comportamiento en el sistema bancario y su capacidad de pago;</li>
      <li class="list__letters list__item">Decidir otorgarle o no el (los) producto(s) y/o servicio(s) que solicite;</li>
      <li class="list__letters list__item">Enviarle encuestas para verificar la atención de los servicios contratados</li>
      <li class="list__letters list__item">Gestionar la presente solicitud; sus datos personales serán utilizados con propósitos limitados, tal como los expuestos precedentemente.</li>
      <p class="subtitle">Clientes Persona Natural</p>
      <li class="list__letters list__item">Gestionar las solicitudes y/o la contratación de nuestros servicios y/o productos, que se encuentran aprobados administrativamente de forma previa por la Superintendencia de Banca, Seguros y AFP o no;</li>
      <li class="list__letters list__item">Usar, brindar y/o transferir esta información a terceros, dentro o fuera del país, vinculadas o no al Banco, nacionales o extranjeras, públicas o privadas en ejercicio de la ley; también, al Gobierno, así como a entidades financieras nacionales o extranjeras, de acuerdo con las disposiciones legales del lugar de origen de estas entidades y a la operativa que realice el Banco en cumplimiento de ellas; asimismo, a proveedores, que incluyen a empresas de mensajería, auditoría, seguridad, sistemas, entre otras que requieran la información en relación con las funciones del Banco;</li>
      <li class="list__letters list__item">Enviarle encuestas para verificar la atención de los servicios contratados;</li>
      <li class="list__letters list__item">Gestionar la presente solicitud; sus datos personales serán utilizados con propósitos limitados, tal como los expuestos precedentemente.</li>
      <li class="list__letters list__item">El titular del dato personal otorga al Banco su consentimiento para requerir de manera directa a su
        empleador, información de contacto debidamente actualizada, como el número de teléfono, dirección
        de domicilio o algún otro dato que permita contactarlo;</li>
    </ul>
  
    <div class="subtitle">Finalidades adicionales del tratamiento de datos personales</div>
    <p class="paragraph">Cuando usted acepte el tratamiento para las finalidades adicionales a sus datos personales, el Banco utilizará sus datos personales para:</p>
    <ul class="list list__letters">
      <li class="list__letters list__item">Ofrecerle productos y/o servicios del Banco, y/o de terceros, vinculados al Banco o no (por ejemplo, cuentas, préstamos, entre otros) lo que podremos hacer también a través de diversos medios de comunicación y/o de terceras personas;</li>
      <li class="list__letters list__item">Enviarle ofertas comerciales y/o promociones (personalizadas o generales), obsequios, publicidad e información de portafolio de los productos y/o servicios del Banco y/o de terceros vinculados o no, (por ejemplo, con empresas con las que tengamos asociaciones o alianzas comerciales), mediante cualquiera de los canales físicos y/o virtuales del Banco;</li>
      <li class="list__letters list__item">Evaluar su comportamiento en el sistema bancario y su capacidad de pago para evaluar si califica para algún otro producto o servicio.</li>
      <li class="list__letters list__item">Enviarle invitaciones y saludos personales.</li>
      <li class="list__letters list__item">Desarrollar acciones comerciales y realizar estudios de mercado.</li>
      <li class="list__letters list__item">Elaborar perfiles de compra y evaluaciones financieras.</li>
      <li class="list__letters list__item">En el caso de la actualización requerida al empleador del titular del dato, según lo estipulado en el
        siguiente párrafo, los presentes incisos aplicarán para la ejecución de finalidades adicionales en caso
        de aceptación.</li>
    </ul>
  
    <p class="paragraph">En caso usted autorice el tratamiento de sus datos mediante línea telefónica, el Banco utilizará sus datos para las finalidades informadas a través de dicho canal, es decir, además de las finalidades antes indicadas, también serán utilizados para evaluar si califica para contratar algún producto o servicio del Banco, y para que le puedan informar la oferta de esa oportunidad.</p>
    
    <p class="paragraph">El otorgamiento del consentimiento es libre, por tanto, usted puede otorgar su consentimiento o puede negarse a hacerlo. Cuando usted nos brinde su consentimiento, sus datos podrán ser tratados solo para las finalidades señaladas. Del mismo modo, su negativa al consentimiento no afectará la prestación del producto o servicio que el Banco le brinde y sus datos podrán ser usados para cumplir con la preparación, celebración y ejecución de la relación contractual y/o solicitar el cumplimiento de obligaciones adquiridas mediante los contratos, anexos y demás documentación del producto o servicio que haya contratado con nosotros.</p>
  
    <div class="subtitle">Datos Personales Obligatorios del tratamiento de datos personales</div>
    <p class="paragraph">Para llevar a cabo las finalidades descritas es obligatorio nos proporcione los siguientes datos personales y sensibles: nombres y apellidos, número de documento de identidad, N° RUC, N° Pasaporte, dirección de domicilio, dirección de correo electrónico, número de teléfono, voz, firma, firma electrónica, estado civil, fecha de nacimiento, nacionalidad, sexo, profesión, edad, datos de derechohabientes, datos de persona de contacto, créditos, préstamos, avales, datos bancarios, historial de créditos, tarjetas de crédito, bienes patrimoniales, hipotecas, deudas, características de vivienda, ingresos económicos, huella digital, reconocimiento facial.</p>
  
    <div class="subtitle">Consecuencias de proporcionar los datos o su negativa a hacerlo</div>
    <p class="paragraph">De no proporcionar los datos obligatorios no se le podrá brindar el servicio o producto.</p>
  
    <div class="subtitle">Transferencia y Destinatarios del tratamiento de datos personales</div>
    <p class="paragraph">Para la ejecución de las finalidades el Banco compartirá los datos sus proveedores como encargados del tratamiento (mensajería, auditoría, seguridad, sistemas, entre otras que requieran la información para sus funciones) y sus empresas vinculadas, de manera directa o a través de proveedores; quienes están comprometidos a salvaguardar la confidencialidad de sus datos personales, salvo que por ley se exija su revelación.</p>
    <p class="paragraph">Las empresas vinculadas al Banco de Comercio son Inversiones Banco de Comercio S.A.; Administradora del Comercio S.A. y Almacenera Peruana del Comercio S.A.</p>
    <p class="paragraph">Los proveedores que realizan el tratamiento de sus datos personales por encargo del Banco de Comercio en virtud de una relación jurídica se encuentran listadas en el ítem 02 de la sección <a class="link"
      href="https://www.bancomercio.com/elbanco/categoria/proteccion-de-datos-personales/545/c-545"
      target="_blank">“Protección de Datos Personales”</a>.</p>
    <p class="paragraph">Asimismo, se realiza el flujo transfronterizo de datos personales, de acuerdo con el siguiente detalle:</p>
    
    <table class="table-modal">
      <tr>
        <th>Destinatario</th>
        <th>País</th>
        <th>Finalidad</th>
      </tr>
      <tr>
        <td>Microsoft Azure</td>
        <td>Estados Unidos de América </td>
        <td>Administrar la información de clientes para brindarles servicios financieros.</td>
      </tr>
    </table>
  
    <div class="subtitle">Banco de Datos del tratamiento de datos personales</div>
    <p class="paragraph">La información será almacenada en los Bancos de Datos mediante su inscripción en el Registro Nacional de Protección de Datos Personales, 
      con las denominaciones ‘‘Clientes Persona Natural” RNPDP-PJP N° 5464 y “Prospecto de Clientes” RNPDP-PJP N°10391, según corresponda.</p>
  
    <div class="subtitle">Tiempo del tratamiento de datos personales</div>
    <p class="paragraph">El tratamiento se efectuará mientras sean necesario para cumplir con las finalidades o hasta por diez años luego de concluida la relación contractual, 
      de acuerdo a lo establecido en la legislación bancaria. En caso haya dado su consentimiento para las finalidades adicionales, sus datos se conservarán mientras no solicite su cancelación.</p>
  
    <div class="subtitle">Ejercicio de los derechos ARCO</div>
    <p class="paragraph">
      Como titular se sus datos personales, usted podrá ejercer su derecho de acceso, rectificación, cancelación y/o oposición al tratamiento de sus datos personales, 
      presentando su solicitud en cualquier agencia u oficina del Banco. Asimismo, usted podrá ejercer su derecho de revocación del consentimiento en cualquier momento.
    </p>
    <p class="paragraph">Los procedimientos y requisitos se pueden consultar en los teléfonos: 0-800-00-2222 o al 513- 6001 al interior del país; a través del Portal <a class="link"
      href="https://www.bancomercio.com/elbanco/categoria/proteccion-de-datos-personales/545/c-545"
      target="_blank">https://www.bancomercio.com/elbanco/categoria/proteccion-de-datos-personales/545/c-545</a> o en cualquier agencia u oficina. Sin embargo, es importante que tenga en 
      cuenta que no en todos los casos podremos atender su solicitud de oposición o cancelación de forma inmediata, ya que es posible que por alguna obligación legal o causales de improcedencia 
      que estipula la Ley de Protección de datos Personales y su Reglamento requiramos seguir tratando sus datos personales.
    </p>
    <p class="paragraph">Los cambios y actualizaciones de las condiciones de tratamiento del presente documento se harán de tu conocimiento en cualquiera de nuestras agencias u oficinas; 
      en nuestro portal <a class="link" href="http://www.bancomercio.com" target="_blank">www.bancomercio.com</a> y/o en el estado de cuenta del producto que tenga contratado con nosotros.
    </p>
  
    <div class="subtitle">Contactar al Banco</div>
    <p class="paragraph">Si considera que sus derechos de protección de datos personales han sido vulnerados por alguna conducta de nuestros trabajadores, de nuestras actuaciones o respuestas, 
      puede contactar a nuestra Área de Protección de Datos Personales, email:
      <a class="link" href="mailto:derechos_pdp@bancomercio.com.pe" target="_blank" rel="external" 
      title="Envíanos un correo">derechos_pdp@bancomercio.com.pe</a> o a los teléfonos 0-800-00-2222 o al 513-6001 al interior del país. Sin perjuicio de ello, puede presentar una reclamación
       ante la Autoridad Nacional de Protección de Datos Personales, dirigiéndose a la Mesa de Partes del Ministerio de Justicia y Derechos Humanos: Calle Scipion Llona 350, Miraflores, Lima, Perú.
    </p>
    
    <p class="paragraph">Al suscribir la presente política, usted declara haber leído y aceptado la política de privacidad.</p>
  
  </div>