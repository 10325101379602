import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


import { catchError, map } from 'rxjs/operators';


@Injectable()
export class ApiService {
  private fullApiUrl: string;
  constructor(
    private http: HttpClient
  ) {
    this.fullApiUrl = environment.api_url;
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.fullApiUrl}${path}`, { params })
      .pipe(
        catchError(this.formatErrors)
      );
  }

  getHttpStatudCode(path: string, params: HttpParams = new HttpParams()): Observable<number> {
    return this.http.get<null>(`${this.fullApiUrl}${path}`, { observe: 'response', params })
    .pipe(map((res) => res.status));
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(
      `${this.fullApiUrl}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http.post(
      `${this.fullApiUrl}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }

  postFormData(path: string, body: object = {}): Observable<any> {
    return this.http.post(
      `${this.fullApiUrl}${path}`,
      body
    ).pipe(catchError(this.formatErrors));
  }

  postFile(path: string, formData: object = {}): Observable<any> {
    return this.http.post(
      `${this.fullApiUrl}${path}`,
      formData, {
        reportProgress: true,
        observe: "events",
      }
    ).pipe(catchError(this.formatErrors));
  }

  patch(path: string, body: object = {}): Observable<any> {
    return this.http.patch(
      `${this.fullApiUrl}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }

  delete(path: any): Observable<any> {
    return this.http.delete(
      `${this.fullApiUrl}${path}`
    ).pipe(catchError(this.formatErrors));
  }
}
