import { Component, Inject, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef } from 'libs/cambix/ui/src/directives/dialog-cdk-ref';
import { DIALOG_DATA } from 'libs/cambix/ui/src/directives/dialog-cdk-tokens';

@Component({
  selector: 'cambix-web-v2.0-dialog-register-business',
  templateUrl: './dialog-register-business.component.html',
  styleUrls: ['./dialog-register-business.component.scss'],
})
export class DialogRegisterBusinessComponent {
 
  size = 'sm' ;
  bodyHtml : any ="";
  loaderOtp : boolean = true;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private router : Router
    ) {
    if (data) {
      this.size = data?.sizeButton ? data?.sizeButton : this.size;
    }
  
  }

  ngOnInit(){ 
    setTimeout(() => {
      this.onClose();
    }, 10000);
  }

  onRegistrarEmpresa() {
    this.router.navigateByUrl('cambix-business/auth/registro');
  }

  
  onClose() {
    this.loaderOtp = false;
    this.dialogRef.close();
  }



}
