import { BiometricGlobalService } from './../../../../../../apps/cambix/src/app/cambix/shared/observables/biometric-global.service';
import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { iif, of } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'apps/cambix/src/environments/environment';

declare const Start: any;
const URL_BASE: string = environment.biometricConfig.URL_BASE;
const PROJECT_NAME: string = environment.biometricConfig.PROJECT_NAME;
const API_KEY: string = environment.biometricConfig.API_KEY         ;
const PRODUCT_ID: number = 1;
const FUNCTION_CAPTURE: number = 1;
const IS_FRONT_SIDE: boolean = false;
const UID_DEVICE: string = "";
const TOKEN: string = "";

@Component({
    selector: 'cbx-biometric',
    templateUrl: './biometric.component.html'
})
export class CbxBiometricComponent implements OnInit {

    constructor(
        private service: BiometricGlobalService,
        private cd: ChangeDetectorRef,
        private ngZone: NgZone,
        private router: Router
    ) { }


    ngOnInit(): void {

        this.service.configBiometric$.pipe(

            switchMap((data) =>
                iif(
                    () => (data.activated),
                    of(data).pipe(
                        tap(() => {
                            const { segment } = data;
                            Start(URL_BASE, PROJECT_NAME, API_KEY, PRODUCT_ID, FUNCTION_CAPTURE, IS_FRONT_SIDE, UID_DEVICE, TOKEN,
                                (response: any) => {
                                    this.ngZone.run(() => {

                                        this.service.setsendBiometric({ dataBiometric: response, segment });
                                    });
                                },
                                (response: any) => {
                                    this.ngZone.run(() => {
                                        this.service.setsendBiometric({ dataBiometric: response, segment });
                                    });
                                }
                            )

                        })

                    )
                    , of('data_not_found').pipe(tap(() => {
                        this.router.navigateByUrl('cambix/dashboard/home');
                    }))
                ),
            ),
            takeUntil(this.service.destroyBiometric)
        ).subscribe();


    }
    ngOnDestroy(): void {
        this.service.destroyBiometric.next();
    }

}
