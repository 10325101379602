import { Component, Inject } from '@angular/core';
import { DialogRef } from 'libs/cambix/ui/src/directives/dialog-cdk-ref';
import { DIALOG_DATA } from 'libs/cambix/ui/src/directives/dialog-cdk-tokens';

@Component({
  selector: 'cambix-web-v2.0-dialog-legal',
  templateUrl: './dialog-legal.component.html',
  styleUrls: ['./dialog-legal.component.scss']
})
export class DialogLegalComponent {
  
 
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any) 
  {} 

 

  onClose() {
    this.dialogRef.close();
  }


}
