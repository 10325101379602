import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { IDialogData } from '../interfaces/dialog.interface';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  dataSourceDialogAction! : IDialogData; 

  private isBuildDialogAction$ = new BehaviorSubject<IDialogData>(this.dataSourceDialogAction);
  private  _dataDialogAction: Observable<IDialogData> = this.isBuildDialogAction$.asObservable(); 

  constructor() { } 
  setData(value : IDialogData){ 
    this.isBuildDialogAction$.next(value); 
  } 
 
  getData(): Observable<IDialogData> { 
    return this._dataDialogAction;
  }   

}
