import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'apps/cambix/src/environments/environment';

@Injectable()
export class UtmService {

  private _apiUrl = environment.leads;

  constructor(
    private http: HttpClient,
  ) { }

  saveUtmParams(params: any): void {
    localStorage.setItem('utmParams', JSON.stringify(params));
  }

  getUtmParams(): any {
    const utmParams = localStorage.getItem('utmParams');
    return utmParams ? JSON.parse(utmParams) : null;
  }

  pushToSheets(info) {
    const { data } = info;
    const { id, attributes } = data;
    const row = {
      id,
      correo: attributes.correo,
      nombreTemplate: attributes.nombreTemplate,
      origen: attributes.origen,
      ...attributes.campos,
    };

    return this.http.post(this._apiUrl, JSON.stringify(row));
  }
}
