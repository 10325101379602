import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
const getStyles = (...args: string[]) => ['inputSimulator', ...args].filter(Boolean)
export enum EInputValidation {
  Number = 'number',
  Alpha = 'alpha',
  Alphanumeric = 'alphanumeric',
  Text = 'text',
  Decimal='decimal'
}

@Component({
  selector: 'cbx-input-simulator',
  templateUrl: './input-simulator.component.html',
  styleUrls: ['./input-simulator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSimulatorComponent {
  @Input() placeholder        = '';
  @Input() label              = '';
  @Input() errorMsg           = '';
  @Input() type               = 'text';
  @Input() disabled           = false;
  @Input() isReadOnly         = false;
  @Input() value : string     = '';
  @Input() maxLength          = '';
  @Input() size               = 'md';
  @Input() paste              = true;
  @Input() width              = ''
  @Input() typeCurrency       = 'PEN';
  @Input() typeInput          = 'inputSimulator';
  @Input() inputValidation!: EInputValidation;

  @Output() public valueChanged = new EventEmitter();
  @Output() public blurred = new EventEmitter();
  @Output() public enter = new EventEmitter();
  @Output() public focus = new EventEmitter();

  maxLimitLength = 999999;
  maxInputLength = this.maxLimitLength;
  expression!: RegExp;

  public get typeClass(): string[] {
    return getStyles(this.typeInput)
  }

  onChange(target: any) {
    switch (this.inputValidation) {
      case EInputValidation.Number:
        this.expression = /[A-Za-zÑÁÉÍÓÚñáéíóú`~!¡@#$%^&*()_|+\-=?;/:'',.<>° ]/g;
        break;
      case EInputValidation.Alpha:
        this.expression = /[0-9`~!¡@#$%^&*()_|+\-=?;:'',.<>° ]/g;
        break;
      case EInputValidation.Alphanumeric:
        this.expression = /[`~!¡@#$%^&*()_|+\-=?;:'',.<>° ]/g;
        break;
      case EInputValidation.Text:
        this.expression = /[0-9`~!¡@#$%^&*()_|+\-=?;:'',.<>°]/g;
        break;

    }
     if (this.expression) {
      target.value = target.value
        .replace(this.expression, '')
        .substr(0, Number(this.maxLength));
    }
    this.maxInputLength =
      target.value.length > 0 ? Number(this.maxLength) : this.maxLimitLength;
    this.valueChanged.emit(target.value);
  }

  onBlur(target: any) {
    this.blurred.emit(target.value);
  }

  onFocus(target: any) {
    this.focus.emit(target.value);
  }

  onEnter(event: any, value: any) {
    this.enter.emit(value || event.target.value);
  }

  onPaste(event: ClipboardEvent) {
    if ((window as any).clipboardData) {
      const pastedText = (window as any).clipboardData;
    } else {
      const pastedText = event?.clipboardData?.getData('text');
    }
    return this.paste;
  }
}

