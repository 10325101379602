import { Component, Input } from '@angular/core';
import { SidenavService } from '../../service/sidenav.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { MenuService } from '../../service/menu.serice';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { TokenStorageService } from 'apps/cambix/src/utils/services/token/token.storage.service';


const getStyles = (...args: string[]) => ["nombreBotton", ...args].filter(Boolean)


@Component({
  selector: 'cbx-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {

  @Input() position : string | null = "";
  @Input() size  : string | null = "sm";
  @Input() type : 'SidenavPrimary' | 'SidenavSecondary' = 'SidenavPrimary';

  isShow : Boolean = false;
  menuArray: any[] = [];
  typeClass  = '';
  sesionUsuario : any;
  destroyed = new Subject<void>();

  constructor(
    private serviceSidenav: SidenavService,
    private serviceMenu: MenuService,
    private breakpointObserver: BreakpointObserver,
    private tokenStorageService: TokenStorageService
  ){

    const datosSession : any = this.tokenStorageService.getStoreSessionUser()
    this.sesionUsuario = JSON.parse(datosSession);

    this.serviceSidenav.showSidenavMenuUser().pipe(
      tap((resp : any)  =>  {
        this.typeClass =  resp ? resp.type : 'SidenavPrimary';
        this.isShow = !resp ? false : resp.active;
       }),
      takeUntil(this.destroyed)
      ).subscribe();

      this.breakpointObserver.observe([Breakpoints.Small,Breakpoints.XSmall, Breakpoints.Medium]).subscribe((state: BreakpointState) => {
        if (!state.matches) {
          this.isShow = false;
        }
      });

      setTimeout(() => {
        this.onMenu();
      }, 200);

    }


  onMenu(){
    if(this.sesionUsuario.userType === 'PER'){
      this.serviceMenu.getMenuSource().pipe(
        tap((resp => this.menuArray = resp)),
        takeUntil(this.destroyed)
      ).subscribe();
    }else{
      this.serviceMenu.getMenuBusinessSource().pipe(
        tap((resp) => this.menuArray = resp),
        takeUntil(this.destroyed)
      ).subscribe( );
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

}




