<div [ngClass]="typeClass" >   
  <div  [ngClass]="{'content' : isShow, 'contentDark' : !isShow}" >
    <div class="navigation" *ngIf="isShow">  
      <div *ngIf=" typeClass === 'SidenavPrimary'">
        <cbx-menu [listMenu]="menuArray" type="MenuSidenav"> </cbx-menu> 
      </div>
      <div *ngIf=" typeClass === 'SidenavSecondary'">
        <cbx-menu-user [listMenu]="menuArray" type="MenuUserCbx"> </cbx-menu-user> 
      </div> 
    </div>
  </div> 
</div> 