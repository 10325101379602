<div [ngClass]="typeClass">

    <div class="div-content-menu">
        <div>
            <div *ngIf="type == 'MenuSidenav'" class="closeMenu" >
                <div  class="close-menu__icon"  (click)="onHiddenSidenav()"> &#x2715; </div>
            </div>

            <div class="contenido-menu">
                <div class="menu" *ngFor="let item of listMenu">
                    <div *ngIf="listMenu" [ngClass]="{
                        'menu__items' : !item.active,
                        'items_active' : item.active
                      }"
                      (click)="onActive(item.routerlink,item.code)">

                        <img class="icon-casita-white" [src]="item.icon" alt="icono de menu">
                        <img class="icon-casita" [src]="item.iconActive"  alt="icono de menu">
                        <div class="name-menu font-secondary-bold-14 pt-5"> {{item.name}} </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="div-bancos">
            <div class="divider-two"></div>

            <div class="alinear">
                <div  class="texto-bold2">
                    <img *ngIf="sesionUsuario.userType === 'EMP'"
                     class="icon-casita-white"
                     src="assets/icons/icon-horarios-business.svg" alt="icono de menu">
                     Horario de atención
                     <div *ngIf="!(sesionUsuario.userType === 'EMP')" class="show-tooltip">
                        <img class="info-tooltip" src="assets/imagenes/iconos/ico-purple-info.svg" alt="tooltip">
                        <cbx-tooltip [data]="toolHorariosPersona" type="TooltipPrimary"> </cbx-tooltip>
                    </div>
                </div>
            </div>

            <div class="font-secondary-regular-16">
                {{ this.sesionUsuario.userType === 'EMP' ? 'Lun. a  Vier. 8:30 a.m.  - 6:00 p.m.' : 'Lun. a  Dom. 8:00 a.m.  - 10:00 p.m.' }}
            </div>



            <div class="divider-two"> </div>

            <div class="texto-bold font-secondary-bold-14">
            <b>  Trabajamos con todos los bancos </b>
            </div>


            <div class="Bancos-lista" >
                <div *ngFor="let img of arrayImagenesBancos">
                    <img [src]="img.imagenBanco" [alt]="img.alt" width="30" height="30">
                </div>
                <div class="show-tooltip">
                    <img src="assets/imagenes/iconos/icono-mas-bancos.svg" alt="icono de menu">
                    <cbx-tooltip [data]="toolBancosAsociados" [position]="positionToolBancosResponsive" type="TooltipSecondary"> </cbx-tooltip>
                </div>
            </div>

            <div class="divider-two"> </div>
        </div>

    </div>

</div>

