import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OperationService {
 dataOperation : any={};
  private isDataOperation$ = new BehaviorSubject<boolean>(this.dataOperation);
  private  _isData: Observable<boolean> = this.isDataOperation$.asObservable();

 
  constructor() { }

  setDataOperation(value: any) {
    this.isDataOperation$.next(value);
  }

   getDataOperation(): Observable<boolean> {
    return this._isData;
  }
}
