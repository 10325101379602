import { DialogMessageComponent } from './../../../../../../apps/cambix/src/app/cambix/shared/dialog-message/dialog-message.component';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, Subscription, catchError, debounceTime, filter, fromEvent, map, of, switchMap, tap } from 'rxjs';
import { CbxLayoutService } from '../../service/layout/responsive.service';
import { DropdownHttp } from '../../service/dropdown/dropdown.service.http';
import { IAccountResV3 } from '../../interfaces/dropdown/dropdown.response.interface';
import { EErrorStatus } from '../../constants/dropdown/error.const';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DialogCdkService } from '../../service/dialog-cdk.service';
import { messageCCI } from 'apps/cambix/src/app/cambix/shared/message-type/message-type';
const getStyles = (...args: string[]) => [...args].filter(Boolean)
export enum EInputValidation {
  Number = 'number',
  Alpha = 'alpha',
  Alphanumeric = 'alphanumeric',
  Text = 'text',
  default = 'default',
  digitsOnly = 'number',
}

@Component({
  selector: 'cbx-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements AfterViewInit, OnInit {

  @ViewChild('search')
  searchElement!: ElementRef;
  showFilter = false;
  showCounter = false;
  isbank = false;
  bank = '';
  total = "20";
  digits = "0";
  @Input() type: 'Classic' | 'OtherType' = 'Classic';
  @Input() maxLength = '20';
  @Input() value = '';
  @Input()
  inputValidation: EInputValidation = EInputValidation.Number;
  expression!: RegExp;
  @Output() public valueChanged = new EventEmitter();
  @Output() public dataAccount = new EventEmitter();
  keyUpSubscription: Subscription = new Subscription;
  searching = false;
  selectorInput = "input";
  @Input() sourcePreviousCCI: any = [];
  @Input() label = "";
  @Input() currency: any;
  @Input()
  isOriginAccount!: boolean;
  placeholder = '';
  size = '';
  ClassSize = '';
  msgError!: SafeHtml;
  elementFound: any = {};
  data: any;
  public typeView: "FULL" | "FOUND" | "EMPTY" | "ERROR" | "LOADING" | "" = "EMPTY";
  ownAccount!: boolean;

  constructor(private cbxLayout: CbxLayoutService, private serviceDropDown: DropdownHttp, protected _sanitizer: DomSanitizer, private dialogService: DialogCdkService) {
    this.cbxLayout.currentScreenSize$.subscribe((currentSize) => {
      if (currentSize === 'xs') this.size = 'small';
      else this.size = 'large'
    });
  }

  ngOnInit(): void {
    this.dataAccount.emit({ ownAccount: false, imgbank: "", cci: "", owner: "", isOk: false });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currency']?.currentValue != changes['currency']?.previousValue) {
      this.selectorInput = "input";
      this.isbank = false;
      this.showCounter = false;
      this.dataAccount.emit({ ownAccount: false, imgbank: "", cci: "", owner: "", isOk: false });
      if (this.currency === 'USD') {
        this.placeholder = 'Ingrese CCI Dólares';
        if (!this.isbank) this.bank = "assets/imagenes/dropdown/symbolUSD.svg";

      }
      else {
        this.placeholder = 'Ingrese CCI Soles';
        if (!this.isbank) this.bank = "assets/imagenes/dropdown/symbolPEN.svg";

      }
    }


  }
  public get typeDropdown(): string[] {
    return getStyles(this.type)
  }
  onBlur(event: any) {


    this.showFilter = false;

    if (this.typeView === '') {
      const splitaccount = this.value?.trim().split('-');
      this.dataAccount.emit({ ownAccount: this.ownAccount, imgbank: this.bank, cci: splitaccount[0].trim(), owner: splitaccount[1].trim(), isOk: true });
    } else {
      this.dataAccount.emit({ ownAccount: false, imgbank: "", cci: "", owner: "", isOk: false });
    }

  }
  onFocus(event: any) {
    if (!this.showFilter) {

      this.data = this.sourcePreviousCCI;
      this.typeView = 'LOADING'
      this.showFilter = true;
      this.dataAccount.emit({ ownAccount: false, imgbank: "", cci: "", owner: "", isOk: false });
      this.serviceDropDown.getAccountByUser(this.currency).subscribe(
        (data) => {
          this.data = data
          if (this.data.length > 0)
            this.typeView = "FULL";
          else this.typeView = 'EMPTY';
          this.showFilter = true;
          this.selectorInput = "input";
          this.inputValidation = EInputValidation.Number;
          this.maxLength = "20";
          this.isbank = false;
          this.bank = this.currency === 'USD' ? 'assets/imagenes/dropdown/symbolUSD.svg' : 'assets/imagenes/dropdown/symbolPEN.svg';
          this.onChange({ value: this.value });

        },
        (error) => {
          this.typeView = 'ERROR';
          this.msgError = this._sanitizer.bypassSecurityTrustHtml(`Ocurrió un error, Intentelo mas Tarde`);
        });
    }


  }
  getData(cci: any): Observable<IAccountResV3> {
    return this.serviceDropDown.getAccountByCciV3(this.currency, cci, this.isOriginAccount);
  }
  ngAfterViewInit(): void {
    this.keyUpSubscription = fromEvent(this.searchElement.nativeElement, 'keyup')
      .pipe(
        tap(() => {
          if (this.data.length > 0) this.typeView = 'FULL';
          else this.typeView = 'EMPTY';
        }),
        filter((val: any) => this.onlyNumber((<HTMLInputElement>val.target).value, val.key)),
        map((event: any) => (<HTMLInputElement>event.target).value),
        filter((val) => val.length === 20),
        tap(() => this.searching = true),
        debounceTime(1000),
        switchMap(cci => this.getData(cci).pipe(
          tap((data) => {
            this.typeView = 'FOUND';
            this.elementFound = data;
            this.searching = false;
            this.showFilter = true;
            this.searchElement.nativeElement.focus();
            this.dataAccount.emit({ ownAccount: false, imgbank: "", cci: "", owner: "", isOk: false });


          }),
          catchError(({ error }) => {
            this.typeView = 'ERROR';
            this.showFilter = true;
            this.searchElement.nativeElement.focus();
            this.dataAccount.emit({ ownAccount: false, imgbank: "", cci: "", owner: "", isOk: false });
            this.msgError = this._sanitizer.bypassSecurityTrustHtml(`Ocurrió un error, Intentelo mas Tarde`);
            this.searching = false;
            if (error.httpStatus === EErrorStatus.NOT_FOUND) {
              this.msgError = this._sanitizer.bypassSecurityTrustHtml(error.message);

            }
            if (error.httpStatus === EErrorStatus.BAD_REQUEST) {
              this.msgError = this._sanitizer.bypassSecurityTrustHtml(error.message);
            }
            if (error.httpStatus === EErrorStatus.DESTINATION_LOCKED) {
              const dataModal = {
                titulo: 'Elige otro banco',
                body: error.message,
                icon: 'assets/imagenes/iconos/icono-elige-otro-banco.svg',
                nameBoton: 'Aceptar'
              }
              this.dialogService.open(DialogMessageComponent,
                { disableClose: false, width: '350px', data: dataModal }
              );
              this.msgError = this._sanitizer.bypassSecurityTrustHtml(error.message);
            }
            if (error.httpStatus === EErrorStatus.PRECONDITION_FAILED) {
              this.msgError = this._sanitizer.bypassSecurityTrustHtml(error.message);
            }
            return of("error");
          })

        ))


      )
      .subscribe();

  }
  onChange(target: any) {

    switch (this.inputValidation) {
      case EInputValidation.Number:
        this.expression = /[A-Za-zÑÁÉÍÓÚñáéíóú`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Alpha:
        this.expression = /[0-9`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Alphanumeric:
        this.expression = /[`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Text:
        this.expression = /[0-9`~!¡@#$%^&*()_|+\-=?;:'",.<>°]/g;
        break;
      case EInputValidation.default:
        this.expression = /^[a-z0-9._]+$/i;
        break;
    }
    if (this.expression && target.value) {
      target.value = target.value
        .replace(this.expression, '')
        .substr(0, Number(this.maxLength));
      this.value = target.value;
    }
    if (target.value === null || target.value === "") {
      this.showCounter = false;
    } else if (this.inputValidation == EInputValidation.default) {
      this.showCounter = false;
    } else {
      this.showCounter = true;
      this.digits = target.value.length;
      this.total = this.maxLength;
    }
    this.valueChanged.emit(target.value);

  }
  onlyNumber(val: any, key: any) {
    if (val.length > 0) {
      if (/^[0-9]+$/.test(val)) return true;
    } else if (key === "Backspace") {
      return true;
    }
    return false;
  }
  openFilter() {
    this.showFilter = !this.showFilter;
    if (this.showFilter) { this.showFilter = false; this.searchElement.nativeElement.focus(); }
    else { this.showFilter = true; this.searchElement.nativeElement.blur(); }

  }
  getElement(element: any) {
    this.searching = true;
    this.value = `${element.cci}`;
    this.getData(element.cci).pipe(
      tap((data) => {
        if(data.blocked === true){
          const data = messageCCI.cci_en_uso;
          this.dialogService.open(DialogMessageComponent,
            { disableClose: false, width: '350px', data: data }
        );

        this.typeView = "FULL";
        this.searching = false;
        this.value = "";
        return;

        }else{

          this.typeView = '';
          this.searching = false;
          this.showFilter = false; //!this.showFilter;
          this.maxLength = "999999";
          this.showCounter = false;
          this.selectorInput = "input_fill";
          this.inputValidation = EInputValidation.default;
          this.value = `${data.cci} - ${data.accountOwnerName}`;
          this.ownAccount = data.ownAccount;
          this.isbank = true;
          this.bank = data.urlWebImage;
          this.onChange({ value: this.value });
          this.searchElement.nativeElement.blur();
          const splitaccount = this.value?.split('-');
          this.dataAccount.emit({ ownAccount: data.ownAccount, imgbank: this.bank, cci: splitaccount[0].trim(), owner: splitaccount[1].trim(), isOk: true });

        }

      }),
      catchError(({ error }) => {
        this.typeView = 'ERROR';
        this.value = "";
        this.showFilter = true;
        this.searchElement.nativeElement.focus();
        this.dataAccount.emit({ ownAccount: false, imgbank: "", cci: "", owner: "", isOk: false });
        this.msgError = this._sanitizer.bypassSecurityTrustHtml(`Ocurrió un error, Intentelo mas Tarde`);
        this.searching = false;
        if (error.httpStatus === EErrorStatus.NOT_FOUND) {
          this.msgError = this._sanitizer.bypassSecurityTrustHtml(error.message);

        }
        if (error.httpStatus === EErrorStatus.BAD_REQUEST) {
          this.msgError = this._sanitizer.bypassSecurityTrustHtml(error.message);
        }
        if (error.httpStatus === EErrorStatus.DESTINATION_LOCKED) {
          const dataModal = {
            titulo: 'Elige otro banco',
            body: error.message,
            icon: 'assets/imagenes/iconos/icono-elige-otro-banco.svg',
            nameBoton: 'Aceptar'
          }
          this.dialogService.open(DialogMessageComponent,
            { disableClose: true, width: '350px', data: dataModal }
          );
          this.msgError = this._sanitizer.bypassSecurityTrustHtml(error.message);
        }
        if (error.httpStatus === EErrorStatus.PRECONDITION_FAILED) {
          this.msgError = this._sanitizer.bypassSecurityTrustHtml(error.message);
        }

        return of("error");
      })

    ).subscribe();



  }


}

