<div [ngClass]="typeClass">
    <div>
        <div class="div-closed">
            <div class="close-menu" >
                <div  class="close-menu__icon"  (click)="onHiddenSidenav()"> &#x2715; </div>
            </div>
            <div class="div-closed__logo mt-2 mb-4">
                <img  [ngClass]="{ 'div-closed__logo--business' : this.sesionUsuario.userType === 'EMP'}"
                src="../../assets/imagenes/logo-cambix2.0.png" alt="loco de cambix">
            </div>
            <div class="name-user mt-2 mb-4"> 
                <div  [ngClass]="{
                    'name--business' : this.sesionUsuario.userType === 'EMP',
                    'name' : this.sesionUsuario.userType === 'PER'}">
                 {{ firstName | titlecase  | appAddPoints : 50}} {{ sesionUsuario?.lastName  | titlecase }} </div>
            </div>
        </div>

        <div class="content-menu-sidenav" >
            <div class="menu" *ngFor="let item of itemsArray">
                <div class="items" (click)="onActive(item.routerlink,item.code)" >
                    <img class="icon-casita" [src]="item.icon" alt="icono de menu" width="25" height="25">
                    <p class="items_name color-black-300" > {{ item.name }} </p>
                </div>
                <div class="divider "> </div>
            </div>
        </div>
    </div>
</div>
