import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TableService {

  private isExistDataTable$ = new BehaviorSubject<boolean>(false);
  private  _isData: Observable<boolean> = this.isExistDataTable$.asObservable();

 
  constructor() { }
  setDataTable(value: boolean) {
    this.isExistDataTable$.next(value);
  }

   getDataTable(): Observable<boolean> {
    return this._isData;
  }
}
