<ng-container [ngSwitch]="typeDropdown">
    <ng-container *ngSwitchCase="'Classic'">
        <div class="Classic">
            <div class="label">{{label}}</div>
            <div class="wrapper-search">
                <div
                    [ngClass]="{'symbol': isbank === false, 'bank' : isbank === true }"
                    (click)="openFilter()"
                    (mousedown)="$event.preventDefault();"
                >
                    <img
                        [src]="bank"
                        alt="imagen-symbol"
                    >
                </div>
                <input
                    #search
                    [ngClass]="selectorInput"
                    type="text"
                    [placeholder]="placeholder"
                    (blur)="onBlur($event)"
                    (focus)="onFocus($event)"
                    (input)="onChange($event.target)"
                    inputmode="numeric"
                    [maxLength]="maxLength"
                    [value]="value"
                >
                <div
                    class="counter"
                    *ngIf="showCounter"
                >
                    ({{digits}}/{{total}})
                </div>



                <div
                    class="arrow"
                    *ngIf="!searching"
                    (click)="openFilter()"
                    (mousedown)="$event.preventDefault();"
                >
                    <img src="assets/imagenes/dropdown/arrow.svg">
                </div>
                <div
                    class="lds-ring"
                    *ngIf="searching"
                >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>


            <ng-container [ngSwitch]="typeView">
                <ng-container *ngSwitchCase="'LOADING'">
                    <div
                        *ngIf="showFilter"
                        class="wrapper-filter"
                    >
                        <div class="empty">Cargando...</div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'EMPTY'">
                    <div
                        *ngIf="showFilter"
                        class="wrapper-filter"
                    >
                        <div class="empty">
                            Aquí se mostrará tus CCI consultados anteriormente
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'FULL'">
                    <div *ngIf="showFilter" class="wrapper-filter" >
                        <ng-container *ngFor="let element of data">
                            <div [ngClass]="{
                                    'row-filter-blocked': element.blocked === true,
                                    'row-filter': element.blocked === false}"
                                (click)="getElement(element)" (mousedown)="$event.preventDefault();" >
                                <div class="bank">
                                    <img [src]="element.urlWebImage" alt="banco-img" >
                                </div> 

                                <ng-container *ngIf="size=='large'">
                                    <div class="account">{{element.cci}}</div>
                                    <div class="separator">-</div>
                                    <div class="fullname">{{element.accountOwnerName}}</div>
                                </ng-container>
                                <ng-container *ngIf="size=='small'">
                                    <div class="info">
                                        <div class="account">{{element.cci}}</div>
                                        <div class="fullname">{{element.accountOwnerName}}</div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'ERROR'">
                    <div *ngIf="showFilter" class="wrapper-filter" >
                        <div class="error">
                            <img src="assets/imagenes/dropdown/error.svg">
                            <div
                                class="msgError"
                                [innerHTML]="msgError"
                            ></div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'FOUND'">
                    <div *ngIf="showFilter" class="wrapper-filter">
                        <div class="found">
                            <div class="title">Datos del CCI</div>
                            <div [ngClass]="{
                                'row-filter-blocked': elementFound.blocked === true,
                                'row-filter': elementFound.blocked === false}"
                            (click)="getElement(elementFound)" (mousedown)="$event.preventDefault();" >
                                <div class="bank">
                                    <img [src]="elementFound.urlWebImage" alt="banco-img" >
                                </div>
                                <ng-container *ngIf="size=='large'">
                                    <div class="account">{{elementFound.cci}}</div>
                                    <div class="separator">-</div>
                                    <div class="fullname">{{elementFound.accountOwnerName}}</div>
                                </ng-container>
                                <ng-container *ngIf="size=='small'">
                                    <div class="info">
                                        <div class="account">{{elementFound.cci}}</div>
                                        <div class="fullname">{{elementFound.fullname}}</div>
                                    </div>
                                </ng-container>


                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>






        </div>
    </ng-container>
</ng-container>
