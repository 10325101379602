import { Component, EventEmitter, Input, Output } from '@angular/core';
const getStyles = (...args: string[]) => [...args].filter(Boolean)

@Component({
  selector: 'cbx-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {

  @Input() typemsg = "small";
  @Input() msg = "";
  @Input() height = "";
  @Input() width = "";

  public get typeMsg(): string[] {
    return getStyles(this.typemsg)
  }


}

