<div class="cbx-container home-cookie">
    <div class="cookie-dialog-container">
        <p class="cookie-policy__detail">
            Usamos cookies para brindarte una buena experiencia. Tenemos cookies que son
      necesarias para que interactues con el Banco de Comercio. Otras cookies son
      con fines analíticos, las puedes aceptar, configurar o rechazar. Si deseas
      mayor información consulta nuestra
            <a
                class="cookie-policy__link"
                (click)="openCookiesPolicyModal()"
            >
                Política de Cookies
            </a>
            .
        </p>
        <a
            class="cookie-policy__config"
            (click)="configureCookies()"
        >
            Configurar cookies
        </a>


        <button
            type="button"
            class="btn-flat cbx-font-secondary-bold cookie-policy__btn-sign-up"
            (click)="acceptAllCookies()"
        >
            Aceptar todas
        </button>
    </div>
</div>
