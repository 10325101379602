<ng-container [ngSwitch]="typeInputs"> 
    <ng-container *ngSwitchCase="'Filter'"> 
      <div class="Filter" >
        <div> 
          <cbx-carousel
            type="CarouselMeses"
            [daySelect]="daySelect"
            (changeMonth)="onChangeMonth($event)"> 
          </cbx-carousel>
        </div> 

        <div class="div-year">
          {{ year }}
        </div>

        <div class="div-calendario">
            <div class="form-group"> 
                <div class="datepicker"> 
                  <div class="calendar" >
                    <div class="calendar-header"> 
                    </div>
                    <table class="calendar-table">
                      <thead>
                        <tr>
                          <th *ngFor="let day of days">{{ day }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let week of calendar">

                          <ng-container *ngFor="let day of week">
                            <td *ngIf="day.day !== null" (click)="selectDate(day)" 
                              [ngClass]="onClassDatePicker(day)"  
                              (mouseover)="dayOver(day)">
                              {{ day.day}}
                            </td>
                            <td *ngIf="day.day === null" class="empty-cell"></td>
                          </ng-container>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
        </div>
        
        <div class="div-botones">
          <cbx-button
            nombreBotton="Borrar"
            type="BottonSecondary"
            size="sm" 
            (click)="onBorrar()"
            [isDisabled]="disableBtn"
            [sizeResponsive]="true">
          </cbx-button>


          <cbx-button
            nombreBotton="Aplicar"
            type="BottonPrimary"
            size="sm" 
            (click)="onAceptar()"
            [isDisabled]="disableBtn"
            [sizeResponsive]="true">
          </cbx-button> 
        </div>

      </div>
    </ng-container>  
</ng-container>
