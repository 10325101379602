
  <div class="full-width">
    <div class="title">TÉRMINOS Y CONDICIONES</div>

    <p class="paragraph paragraph__first">
      Este servicio le pertenece y es operada por Bancom. Al registrarse, usted acepta todos los términos
      y condiciones establecidos en el presente contrato, lo que le permitirá utilizar los servicios de CAMBIX
      (en adelante, “Bancom, Banco o CAMBIX”, indistintamente). Por favor lea este contrato detalladamente y
      asegúrese de entenderlo en su totalidad antes de utilizar los servicios, tenga en cuenta que, de no aceptar este acuerdo,
       no podrá hacer uso de los servicios.</p>

    <div class="subtitle">1. Objeto del contrato</div>
    <p class="paragraph">
      Este contrato tiene como objeto que los Clientes (en adelante, “CLIENTE”) se informen de los Términos y
      Condiciones de uso de CAMBIX. En base a ello, le pedimos que lea detalladamente este contrato
      y asegúrese que lo entiende y acepta los términos y condiciones completamente antes de usar los
      servicios.</p>

    <p class="paragraph">El simple uso y acceso a las aplicaciones o uso de sus servicios digitales que
      CAMBIX (en adelante, “la plataforma”) pone a su disposición, se entenderá como la aceptación de los
       mismos y por ende su compromiso para respetar las condiciones de uso. En el caso de que no estés de
        acuerdo con este contrato deberás abstenerte de utilizar aplicaciones y servicios asociados a CAMBIX.</p>

    <div class="subtitle">2. Terminología</div>
    <ul class="list list__circle">
      <li class="list__circle list__item">
        Cliente: Todo persona natural o jurídica que se registra a través de la Plataforma para hacer uso de los servicios de Cambix.
      </li>

      <li class="list__circle list__item">
        Compra: Operación en donde Cambix compra dólares, es decir el Cliente transfiere dólares a cambio de obtener soles.
      </li>

      <li class="list__circle list__item">
        Venta: Operación en donde Cambix vende soles, es decir el Cliente transfiere soles a cambio de obtener dólares.
      </li>

      <li class="list__circle list__item">
        Transferencias entre Cuentas del mismo Banco: Tienen lugar cuando las Cuentas de Origen y Cuentas de Destino del Cliente corresponden a los Bancos donde Cambix tiene cuentas bancarias.
      </li>

      <li class="list__circle list__item">
        Transferencia interbancaria: Tienen lugar cuando las Cuentas de Origen y/o Cuentas de Destino del Cliente corresponden a los Bancos donde Cambix no tiene cuentas bancarias.
      </li>

      <li class="list__circle list__item">
        Transferencia interbancaria regular: Si utilizas la transferencia diferida para transportar dinero la cantidad que hayas decidido movilizar
        estará disponible en la cuenta de destino después de unas horas luego de emitida la transferencia.
      </li>

      <li class="list__circle list__item">
        Transferencia interbancaria Inmediata: Las transferencias Inmediatas son muy parecidas a las transferencias diferidas;
        son movimientos interbancarios para poder transferir dinero de una cuenta financiera a otra con la diferencia de que se hace de forma inmediata.
      </li>

      <li class="list__circle list__item">
        Cuenta Origen: Cuenta que registra el CLIENTE desde donde enviará el dinero a cambiar.
      </li>

      <li class="list__circle list__item">
        Cuenta Destino: Cuenta que registra el CLIENTE en donde se le abonará el dinero cambiado.
      </li>

      <li class="list__circle list__item">
        Transferencia interbancaria Inmediata: Las transferencias Inmediatas son muy parecidas a las transferencias diferidas;
        son movimientos interbancarios para poder transferir dinero de una cuenta financiera a otra con la diferencia de que se hace de forma inmediata.
      </li>

      <li class="list__circle list__item">CCI: Código de Cuenta Interbancario.</li>

    </ul>

    <div class="subtitle">3. Empresa</div>
    <p class="paragraph">Banco de Comercio S.A., autorizado por la Superintendencia de Banca, Seguros y AFP, registrada en Perú con RUC N° 20509507199, domicilio legal en la avenida Canaval y Moreyra 452 - 454, San Isidro, Lima. </p>

    <div class="subtitle">4. Propiedad Intelectual</div>
    <p class="paragraph">CAMBIX es operada por Bancom y está protegido por los derechos de propiedad intelectual correspondientes.
       Todos los derechos involucrados como por ejemplo el contenido, el diseño visual,
        logos y eslogan que forman parte del contenido y servicios, pertenecen a Bancom. En base a ello,
         se prohíbe utilizar, codificar, copiar, distribuir, transmitir y/o comercializar los derechos
          involucrados sin el permiso expreso por escrito del Bancom.</p>
    <p class="paragraph">EL CLIENTE no adquiere ningún derecho de propiedad intelectual por el uso de los Servicios y Contenidos de las aplicaciones, no siendo considerado este documento como una autorización ni licencia para utilizar los servicios y contenidos con fines distintos a los mencionados en el presente contrato.</p>

    <div class="subtitle">5. Servicio</div>
    <p class="paragraph">Bancom ofrece el servicio de cambio de dólares a través de su aplicativo CAMBIX
       (en adelante, “el Servicio”). El usuario a través de CAMBIX podrá registrar la operación de tipo de
        cambio y solicitar que su cambio sea depositado en cuentas Bancom o de otras entidades financieras.</p>

    <div class="subtitle">6. Tarifas</div>
    <ul class="list list__circle">
      <li class="list__circle list__item">
        CAMBIX no cobra ninguna comisión por el uso de su plataforma, ni por el costo del envío de la
        transferencia interbancaria de respuesta para EL CLIENTE que solicite que el cambio sea abonado en
         cuentas diferentes al Bancom.
      </li>
      <li class="list__circle list__item">
        Asimismo, el Cliente debe considerar que en determinados escenarios las operaciones que se realicen pueden ser objeto de cobro de comisiones o de la aplicación de tributos como el impuesto a las transacciones financieras (ITF) por parte de las Empresas Financieras involucradas en tales operaciones, conceptos que deberán ser asumidos por el CLIENTE.
      </li>

      <li class="cascadeList">
        <span class="list__circle list__item"> En caso EL CLIENTE:</span>
        <ul class="list list__circle">
          <li class="list__circle list__item">
            Cancele la operación posterior a haber realizado la transferencia del dinero acordado.
          </li>
          <li class="list__circle list__item">
            Envíe el dinero a través de transferencia regular.
          </li>
          <li class="list__circle list__item">
            Envíe la transferencia desde una cuenta distinta a la que registró en Cambix.
          </li>
          <li class="list__circle list__item">
            Transfiera un monto diferente a lo acordado en el registro de su operación.
          </li>

          <li class="list__circle list__item">
            Realice la transferencia a Cambix, sin previamente registrar una operación.
          </li>

          <div>
            Realice la transferencia a Cambix, sin previamente registrar una operación.
          </div>

          <li class="list__circle list__item">
            De lunes a jueves la devolución de realizará en (01) día hábil.
          </li>
          <li class="list__circle list__item">
            En caso para operación se haya realizado viernes, sábado, domingo la devolución del dinero se realizará el lunes.
          </li>
          <li class="list__circle list__item">
            En caso la operación se haya realizado en feriado, la devolución se realiza el siguiente día hábil.
          </li>
          <li class="list__circle list__item">
            En caso la operación se haya realizado en feriado, la devolución se realiza el siguiente día hábil.
          </li>

         </ul>
      </li>

    </ul>

    <div class="subtitle">7. Seguridad</div>
    <ul class="list list__circle">
      <li class="list__circle list__item">Proteger su privacidad es muy importante para CAMBIX.
        El acceso a los servicios se realiza a través de una contraseña de 8 dígitos alfanumérico que EL
        CLIENTE asigna en el momento del registro. Por lo tanto, EL CLIENTE es
        el único que conoce esta información. EL CLIENTE no deberá revelar dicha
        información a terceros, habiendo sido creada la cuenta para uso exclusivo de EL CLIENTE.</li>
      <li class="list__circle list__item">
        CAMBIX se compromete a proteger la Privacidad y Seguridad de la información de EL CLIENTE,
         para lo cual cumple con la Ley N° 29733, Ley de Protección de Datos Personales y su reglamento,
         asimismo cuenta con mecanismos de seguridad para garantizar las operaciones de nuestros clientes.</li>
      <li class="list__circle list__item">EL CLIENTE podrá acceder al detalle de sus operaciones en la sección Operaciones.</li>
      <li class="list__circle list__item">
        Debe tener en cuenta que todas las actividades de registro de CLIENTE, datos personales, cuentas,
        operaciones, entrega de constancia de transferencia, validación de recepción de fondos en las
        cuentas de CAMBIX, entre otros, están sujetos a todos los controles de seguridad y de identificación
        del CLIENTE que se realizan de acuerdo con nuestras Políticas de Condiciones del Tratamiento de Datos
        Personales y en observancia a la normativa vigente de la Superintendencia de Banca y Seguros
        (en adelante, “SBS"). Por tanto, si es necesaria una verificación adicional,
        le informaremos, previo a continuar realizando operaciones.</li>
      <li class="list__circle list__item">
        CAMBIX cuenta con una Política de Condiciones del Tratamiento de Datos Personales, y
         Términos y Condiciones de uso, por lo que te recomendamos leerlas previo al uso del servicio.
          Esta Política es aplicada durante todo el proceso y el mantenimiento de la información del CLIENTE y
           usuarios.</li>
    </ul>

    <div class="subtitle">8. Horario de atención</div>
    <ul class="list list__circle">
      <li class="list__circle list__item">Para los casos de transferencia interbancaria el horario de atención de CAMBIX es de lunes a domingo de 8:00 am a 10:00 pm en días hábiles, es decir excluyendo feriados locales e internacionales.</li>
      <li class="list__circle list__item">Para los casos donde se utilicen cuentas Bancom para la transferencia de dinero el horario de atención será de lunes a domingo de 8:00 am a 11:45 pm.</li>
      <li class="list__circle list__item">
        Si la transferencia del monto por parte del CLIENTE se realiza a las cuentas de CAMBIX fuera del
        horario de atención, la operación podrá ser anulada y se realizará la devolución del dinero
        de acuerdo a los procedimientos definidos por CAMBIX.</li>
      <li class="list__circle list__item">Para las operaciones de los CLIENTES Persona Jurídica, las operaciones las podrá realizar de lunes a viernes de 08:30 am a 06:00 pm en días hábiles.</li>
    </ul>

    <div class="subtitle">9. Disponibilidad de servicio</div>
    <ul class="list list__circle">
      <li class="list__circle list__item">CAMBIX estará disponible salvo que existan circunstancias de fuerza mayor, caso fortuito o hechos de terceros que impidan o restrinjan el acceso o uso del servicio, esto puede incluir cambios regulatorios, entre otros eventos similares o análogos, por consiguiente, CAMBIX no puede asegurar que el Servicio no presente caídas o que se vea afectado en su continuidad.</li>
      <li class="list__circle list__item">CAMBIX no será responsable por ningún daño, perjuicio o pérdida de cualquier naturaleza que pueda ser causado debido a la falta de disponibilidad o continuidad de operación del Servicio. </li>
      <li class="list__circle list__item">En cualquiera de estos casos y para operaciones que se encuentren en curso y que hayan completado la transferencia, CAMBIX procederá a la devolución de fondos transferidos en el menor plazo posible.</li>
    </ul>

    <div class="subtitle">10. Registro de cuenta</div>
    <ul class="list list__circle">
      <li class="cascadeList">
        <span class="list list__circle list__item">Para registrase como persona natural</span>
        <ul class="list list__circle">
          <li class="list__circle list__item">
            EL CLIENTE para hacer uso de los servicios de CAMBIX, debe declarar tener al menos dieciocho (18) años de edad cumplidos; cuya nacionalidad deberá ser peruana, con un documento de identidad asignado por entidades peruanas y contar con capacidad legal para celebrar contratos.
          </li>
          <li class="list__circle list__item">
            Para el caso de que EL CLIENTE sea una persona natural, declara que no está actuando en nombre de una tercera persona, bajo responsabilidad.
          </li>
          <li class="list__circle list__item">
            EL USUARIO, asociará para el registro su número celular actual, el cual será validado con él envió de un código SMS, una vez registrado el número, no se podrá usar el mismo número celular para registrar otro usuario.
          </li>
          <li class="list__circle list__item">
            EL CLIENTE deberá registrar sus datos personales (como persona natural), lo cual incluye DNI, Nombre, Apellido Materno, Apellido Paterno, ocupación, fecha de nacimiento, celular y correo electrónico. Asimismo. para el proceso de verificación de identidad deberá adjuntar la foto de DNI cara anverso y reverso
          </li>
        </ul>
      </li>
      <li class="cascadeList">
        <span class="list__circle list__item">Para registrase como persona jurídica</span>
        <ul class="list list__circle">
          <li class="list__circle list__item">
            En el caso de personas jurídicas, deben estar domiciliadas en el Perú. Además, sus representantes y ejecutantes deben estar autorizados por dichas personas jurídicas.
          </li>
          <li class="list__circle list__item">
            La persona jurídica, declara que los datos consignados son verídicos y vigentes respecto a: persona responsable de contacto para el registró y operación, información del representante legal e información actualizada de la empresa ( RUC, dirección, número de contacto, etc
          </li>
        </ul>
      </li>

      <li class="cascadeList">
        Es responsabilidad del CLIENTE no compartir la clave de seguridad que género en la aplicación,
        EL CLIENTE es el único responsable por sus accesos, usos y operaciones que haya realizado en
        la plataforma CAMBIX, liberando de responsabilidad al Bancom por las órdenes u operaciones que haya
        realizado en la plataforma.
      </li>
      <li class="cascadeList">
        Asimismo, CAMBIX podrá solicitar información adicional para el registro del usuario, en caso la información enviada no sea legible o no sea suficiente para probar que actúa en nombre de la persona natural.
      </li>

      <li class="cascadeList">
        Asimismo, CAMBIX podrá solicitar información adicional para el registro, en caso la información enviada no sea legible o no sea suficiente para probar que actúa en nombre de la persona natural.
      </li>
      <li class="cascadeList">
        CAMBIX no permite la creación de cuentas de CLIENTE duplicados para un mismo CLIENTE, debido a los
        requisitos de seguridad y de identificación del CLIENTE. En caso de que se detecten cuentas duplicadas,
          CAMBIX cerrará estas cuentas de CLIENTE duplicadas sin notificación previa. Por lo cual,
          CAMBIX queda exenta de todo tipo de responsabilidad por esa situación.
      </li>
      <li class="cascadeList">
        La cuenta registrada en CAMBIX será de uso personal e intransferible.
      </li>
  </ul>

    <div class="subtitle">11. Verificación</div>
    <ul class="list list__circle">
      <li class="list__circle list__item">Es facultad de Bancom informar a nuestro regulador aquellas operaciones que cumplan con la calidad de “operaciones inusuales o sospechosas” de acuerdo a la normativa que abarca únicamente la materia de prevención de lavado de activos y del financiamiento del terrorismo, emitida por la SBS y de tomar las medidas que llegasen a corresponder en base a la regulación vigente.</li>
    </ul>

    <div class="subtitle">12. Cuentas Bancarias</div>
    <ul class="list list__circle">
      <li class="list__circle list__item">EL CLIENTE para completar su operación deberá indicar el CCI de la cuenta desde donde enviará los fondos y CCI de la cuenta donde recibirá el cambio, tanto en soles como en moneda extranjera.</li>
      <li class="list__circle list__item">Para los casos en que EL CLIENTE registre cuentas
        de origen diferentes a las de Bancom, será EL CLIENTE quien asuma los costos y/o gastos de la
        comisión interbancaria, que le cobrara su entidad financiera que corresponda.</li>
      <li class="list__circle list__item">CAMBIX no será responsable del bloqueo de cuentas utilizadas por EL CLIENTE en una entidad financiera o por otra situación similar a esta.</li>
      <li class="list__circle list__item">CAMBIX no será responsable de errores, gastos, comisiones o tiempos incurridos debidos a errores del CLIENTE y/o de la entidad financiera donde este transfiera su cambio y/o cuando se transfiera a otra cuenta de la especificada por CAMBIX.</li>
      <li class="list__circle list__item">CAMBIX no será responsable de errores, gastos, comisiones o tiempos incurridos debidos a errores del CLIENTE, cuando este transfiera de una cuenta de una moneda a otra cuenta en una moneda diferente.</li>
      <li class="list__circle list__item">CAMBIX podrá solicitar el registro de información adicional, a fin de cumplir con la normativa que rige la Ley de Prevención de Lavado de Activos y del Financiamiento del Terrorismo (PLAFT).</li>
    </ul>

    <div class="subtitle">13. Operación de cambio de moneda</div>
    <p class="paragraph">Para registrar una operación, EL CLIENTE deberá tener en cuenta lo siguiente:</p>

    <div class="subtitle subtitle__nivel2">13.1. Registro de una operación y recepción de fondos</div>
    <ul class="paragraph__nivel2 list-circle">
      <li class="list__circle list__item">EL CLIENTE indicará el monto a cambiar y el tipo de operación (compra o venta).</li>
      <li class="list__circle list__item">El CLIENTE está obligado a informar el origen de los fondos en base a la normativa vigente de prevención de lavado de activos y financiamiento de terrorismo. Esta indicación tiene carácter y validez de una declaración jurada.</li>
      <li class="list__circle list__item">
        EL CLIENTE indicará la cuenta bancaria de origen y destino a utilizar para esta operación, estas cuentas
        deberán indicarse en el mismo momento que registra la operación. En caso EL CLIENTE registre cuentas
        de bancos, distintos a Bancom, este deberá indicar sus Códigos De Cuentas Interbancarias (CCI).
        El Banco no se hará responsable por los errores que haya consignado en la plataforma para realizar
        las operaciones. </li>
      <li class="list__circle list__item">Una vez registrada la operación la cuenta de origen desde donde EL CLIENTE transferirá el dinero, no podrá ser utilizada para registrar otra operación hasta que se culmine con la operación pendiente.</li>
      <li class="list__circle list__item">Cuando se haya completado los pasos anteriores se dará por registrada la operación de cambio, estableciéndose el acuerdo de tipo de cambio entre EL CLIENTE y CAMBIX.</li>
      <!-- Inicio SubLista -->
      <li class="cascadeList">
        <ul class="list list__circle">
          <li class="list__circle list__item">EL USUARIO, deberá realizar la transferencia del monto acordado desde la cuenta origen indicada, hacia la cuenta de CAMBIX.</li>
          <li class="list__circle list__item">
            Para las operaciones de CLIENTE Persona Natural: En caso EL CLIENTE no transfiera el dinero la operación se cancelará a las 11:45 pm del mismo día y se procederá a liberar la cuenta de origen. En caso la cuenta de origen y destino registradas sean Bancom la operación se cancelará a las 11:59 pm del mismo día.
          </li>
          <li class="list__circle list__item">
            Para las operaciones de CLIENTE Persona Jurídica: Las operaciones se cancelarán a los 15 minutos de ser registrada.
          </li>
          <li class="list__circle list__item">
            Una vez transferido el dinero EL CLIENTE deberá ingresar al aplicativo en la sección Operaciones - Operaciones Pendientes, seleccionar opción Verificar.
          </li>
          <li class="list__circle list__item">EL CLIENTE deberá realizar la transferencia desde una cuenta personal o de la empresa que representa, en caso corresponda. CAMBIX no acepta depósitos en efectivo ni cheques, por lo que de detectarse alguna de estos casos la operación quedará anulada.</li>
        </ul>
      </li>
      <!-- Fin SubLista -->
      <li class="list__circle list__item">CAMBIX no se hace responsable del tiempo de transferencia de fondos por parte de las entidades financieras.</li>
      <li class="list__circle list__item">Asimismo, es importante tener en cuenta las siguientes consideraciones adicionales para operaciones con transferencias interbancarias, donde EL USUARIO opere con cuenta en el banco origen o destino, distinto a cuentas Banco de Comercio:</li>
      <!-- Inicio SubLista -->
      <li class="cascadeList">
        <ul class="list list__circle">
          <li class="list__circle list__item">EL CLIENTE deberá asumir el costo de la transferencia inmediata cobrada por su entidad financiera y ejecutar la transferencia dentro de los horarios establecidos para la operativa interbancaria; el cual establece que el tiempo para realizar este tipo de operaciones es de lunes a domingo de 8:00 am a 10:00 pm, no se considera feriados.</li>
          <li class="list__circle list__item">En caso, EL CLIENTE realice una transferencia regular, deberá asumir los tiempos de transferencias de su entidad bancaria. Si CAMBIX recibiera el fondo posterior a las 3:30 pm:</li>
          <!-- Inicio SubLista de la SubLista -->
          <li class="cascadeList">
            <ul class="list list__circle">
              <li class="list__circle list__item">CAMBIX aplicará la cancelación de la operación y realizará la devolución del dinero, en este caso EL CLIENTE asumirá la comisión por transferencia a su entidad bancaria en un plazo máximo de 24 horas, el cual el Banco podrá debitarlo del monto por devolver.</li>
            </ul>
          </li>
          <!-- Fin SubLista de la SubLista -->
        </ul>
      </li>
      <!-- Fin SubLista -->
    </ul>

    <div class="subtitle subtitle__nivel2">13.2. Operaciones interbancarias</div>
    <ul class="paragraph__nivel2 list-circle">
      <li class="list__circle list__item">CAMBIX recomienda a EL CLIENTE que para operaciones interbancarias ejecute la TRANSFERENCIA INMEDIATA dentro del horario establecido por su entidad financiera. Sin embargo, si EL CLIENTE a su riesgo decide realizar una transferencia regular, CAMBIX tendrá la posibilidad de cancelar la operación.</li>
      <li class="list__circle list__item">CAMBIX no es ni será responsable por la idoneidad de los productos y/o servicios brindado a EL CLIENTE por su(s) entidad(es) financieras o la Cámara de Compensación Electrónica.</li>
    </ul>

    <div class="subtitle subtitle__nivel2">13.3. Transferencias a la cuenta destino</div>
    <ul class="paragraph__nivel2 list-circle">
      <li class="list__circle list__item">Una vez que EL CLIENTE haya realizado el abono del monto pactado a la cuenta del Banco de Comercio, CAMBIX realizará la transferencia del monto acordado a la cuenta destino del CLIENTE, registrada en la operación.</li>
      <li class="list__circle list__item">CAMBIX no se hace responsable del tiempo de transferencia de fondos por parte de las entidades financieras.</li>
      <li class="list__circle list__item">CAMBIX no se hace responsable en caso EL CLIENTE coloque una cuenta de destino erróneas, tampoco se hará responsable por la información, datos o montos que ingrese EL CLIENTE en la plataforma.</li>
    </ul>

    <div class="subtitle subtitle__nivel2">13.4. Acuerdo de tipo de cambio</div>
    <ul class="paragraph__nivel2 list-circle">
      <li class="list__circle list__item">
        CAMBIX asegura mantener con EL CLIENTE el tipo de cambio acordado al momento de registrar la operación de cambio de dólares. Sin embargo, estas operaciones y sus condiciones caducan si EL CLIENTE no ha finalizado el proceso de cambio de dólares
        <ul class="paragraph__nivel2 list-circle">
          <li class="list__circle list__item">
            Para las operaciones de CLIENTE Persona Natural: En caso EL CLIENTE no transfiera el dinero la operación se cancelará a las 11:45 pm del mismo día y se procederá a liberar la cuenta de origen. En caso la cuenta de origen y destino registradas sean Bancom la operación se cancelará a las 11:59 pm del mismo día.
          </li>
          <li class="list__circle list__item">
            Para las operaciones de CLIENTE Persona Jurídica: Las operaciones se cancelarán a los 15 minutos de ser registrada.
          </li>
         </ul>
      </li>
    </ul>

    <div class="subtitle subtitle__nivel2">13.5. Límites de cambio</div>
      <ul class="paragraph__nivel2 list-circle">
      <li class="list__circle list__item">Monto mínimo por transferencia interbancaria será de $ 20 o S/ 66.</li>
      <li class="list__circle list__item">Para persona natural: El monto máximo es de $10 mil dólares o s/30 mil soles</li>
      <li class="list__circle list__item">Para persona jurídica : Monto máximo por transferencia se encuentra limitado por el tipo de transferencia que realice el CLIENTE</li>
    </ul>

    <div class="subtitle">14. Términos Generales</div>

    <div class="subtitle subtitle__nivel2">14.1. Comunicaciones</div>
    <ul class="paragraph__nivel2 list-circle">
      <li class="list__circle list__item">Al aceptar este contrato, CAMBIX podrá comunicarse con usted a través de: correo electrónico, teléfono, WhatsApp que haya ingresado en la plataforma o publicando avisos en las aplicaciones.</li>
    </ul>

    <div class="subtitle subtitle__nivel2">14.2. Quejas, Reclamos y Solicitudes</div>
    <ul class="paragraph__nivel2 list-circle">
      <li class="list__circle list__item">
        Si usted considera que no ha recibido un servicio acorde con estos estándares de calidad, puede encontrar información sobre el procedimiento para presentar reclamos en nuestro libro de reclamaciones virtual en:
        <a class="link"
          href="https://www.bancom.pe/libro-de-reclamaciones/"
          target="_blank">https://www.bancom.pe/libro-de-reclamaciones/</a>
        o escribirnos a
        <a class="link"
          href="mailto:cambix_info@bancomercio.com.pe" target="_blank" rel="external"
          title="Envíanos un correo">cambix_info@bancomercio.com.pe</a>
      </li>
      <!-- Lo ultimo es un correo -->
    </ul>

    <div class="subtitle subtitle__nivel2">14.3. Marcas, Patentes y Logotipos</div>
    <ul class="paragraph__nivel2 list-circle">
      <li class="list__circle list__item">Las marcas, patentes, publicidad, slogan, logotipos y cualquier elemento de propiedad inelectual que aparecen en la aplicación y página web son de propiedad del Banco de Comercio. Se prohíbe el uso sin el consentimiento por escrito previamente solicitado y acordado.</li>
    </ul>

    <div class="subtitle subtitle__nivel2">14.4. Actualización de las Condiciones</div>
    <ul class="paragraph__nivel2 list-circle">
      <li class="list__circle list__item">CAMBIX, podrá realizar actualizaciones y cambios sobre los términos y condiciones, de acuerdo a cambios y/o exigencias regulatorias, o por adecuaciones propias del servicio establecidas a criterio de CAMBIX. Toda actualización entrará en vigencia desde su publicación a través de la aplicación y/o mediante la actualización de los Términos y Condiciones de la aplicación.</li>
      <li class="list__circle list__item">CAMBIX enviará una notificación de dicha actualización a EL CLIENTE que se encuentre operando a través de su correo registrado o haciendo su publicación en la aplicación.</li>
    </ul>

    <div class="subtitle subtitle__nivel2">14.5. Resolución</div>
    <p class="paragraph__nivel2 paragraph">El Banco de Comercio se reserva el derecho de no contratar con EL CLIENTE, resolver unilateralmente este Contrato y suspender o cancelar definitivamente los accesos de EL CLIENTE en la plataforma, sin expresión de causa y aviso previo, y sin que ello genere algún tipo de responsabilidad para el Banco o el pago de alguna indemnización, comisión o penalidad, en caso de que:</p>
    <ul class="paragraph__nivel2 list-circle">
      <li class="list__circle list__item">(i) cierre previsto por disposición judicial u otra autoridad competente; (ii) si a criterio de EL BANCO, existen dudas o conflictos respecto a la legitimidad, legalidad, vigencia o alcances de las facultades de los representantes de EL CLIENTE, en caso corresponda o la legalidad de la procedencia de los fondos depositados en la cuenta, aceptando EL CLIENTE que en estos casos, EL BANCO proceda a la consignación judicial de los saldos acreedores; (iii) por muerte, incapacidad, disolución, interdicción, quiebra o sometimiento a algún régimen concursal de EL CLIENTE; (iv) cuando se realicen operaciones que puedan perjudicar a EL BANCO, a EL CLIENTE o a otros clientes de EL BANCO, (v) cuando, sin autorización de EL BANCO, la cuenta se utilice para colectas, recaudaciones o depósitos hechos por terceras personas, (vi) cuando incumpla cualquiera de sus obligaciones de acuerdo al presente contrato, (vii) como consecuencia de la aplicación de las normas prudenciales emitidas por la SBS. En este caso, EL BANCO podrá prescindir de comunicación previa. (viii) en caso las cuentas asociadas de EL CLIENTE permanezcan inactivas por diez (10) años, esto es, sin que se hagan nuevas imposiciones y/o retiros, los fondos depositados serán transferidos al Fondo de Seguro de Depósitos, conforme lo establece el Art. 182° de la Ley General del Sistema Financiero y del Sistema de Seguros y Orgánica de la SBS, en adelante La Ley.</li>
      <li class="list__circle list__item">Como consecuencia de la aplicación de las normas prudenciales, emitidas por la SBS, tales como las referidas a la administración del riesgo de sobreendeudamiento de deudores minoristas, por consideraciones del perfil de EL CLIENTE vinculadas al sistema de prevención del lavado de activos o del financiamiento del terrorismo, por falta de transparencia de los CLIENTES, entre otros supuestos que determine la Superintendencia.</li>
      <li class="list__circle list__item">Cuando se haya otorgado información falsa o insuficiente para poder operar en la plataforma, de acuerdo los términos y políticas establecidos por el Banco.</li>
      <li class="list__circle list__item">En caso de personas jurídicas, no se haya cumplido con otorgar las facultades de representación correspondiente.</li>
    </ul>

    <div class="subtitle subtitle__nivel2"> 14.6. Jurisdicción</div>
    <p class="paragraph__nivel2 paragraph">Las partes se someten expresamente en caso de surgir algún conflicto y/o controversia en torno al presente contrato, a la jurisdicción y competencia de los jueces del Distrito Judicial del lugar de celebración de este contrato, renunciando al fuero de sus respectivos domicilios.</p>

</div>
