import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { DialogCdkService } from 'libs/cambix/ui/src/service/dialog-cdk.service';
import { DialogMessageComponent } from '../../../app/cambix/shared/dialog-message/dialog-message.component';
import { messageAppVersion } from 'apps/cambix/src/app/cambix/shared/message-type/message-type';

@Injectable()
export class LoadChunkErrorHandler implements ErrorHandler {
  constructor(
    private readonly injector: Injector,
    private dialogCdkService: DialogCdkService,
  ) {}

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      this.newVersion();
    }
  }

  newVersion(): void {
    window.location.reload();
  }
}
