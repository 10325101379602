
<div class="full-width">
    <div class="title">AUTORIZACIÓN PARA FINALIDADES ADICIONALES</div>
    <p class="paragraph">Autorizo en forma libre, previa, expresa, informada e inequívoca al Banco de Comercio S.A., a tratar mis datos personales proporcionados en la presente operación y/o formulario para las siguientes finalidades adicionales:</p>
    <ul class="list list__letters">
      <li class="list__letters list__item">
        Ofrecerle productos y/o servicios del Banco, y/o de terceros, vinculados al Banco o no (por ejemplo, 
        cuentas, préstamos, entre otros) lo que podremos hacer también a través de diversos medios de comunicación y/o de terceras personas;
      </li>
      <li class="list__letters list__item">
        Enviarle ofertas comerciales y/o promociones (personalizadas o generales), obsequios, publicidad e información de portafolio de los 
        productos y/o servicios del Banco y/o de terceros vinculados o no, (por ejemplo, con empresas con las que tengamos asociaciones o alianzas comerciales),
         mediante cualquiera de los canales físicos y/o virtuales del Banco;
        </li>
      <li class="list__letters list__item">
        Evaluar su comportamiento en el sistema bancario y su capacidad de pago para evaluar si califica para algún otro producto o servicio.
      </li>
      <li class="list__letters list__item">
        Enviarle invitaciones y saludos personales.
      </li>
      <li class="list__letters list__item">
        Desarrollar acciones comerciales y realizar estudios de mercado.
      </li>
      <li class="list__letters list__item">
        Elaborar perfiles de compra y evaluaciones financieras.
      </li>
      <li class="list__letters list__item">
        En el caso de la actualización requerida al empleador del titular del dato, según lo estipulado en el
        siguiente párrafo, los presentes incisos aplicarán para la ejecución de finalidades adicionales en caso
        de aceptación.
      </li>
    </ul>
    
    <p class="paragraph">
      En caso usted autorice el tratamiento de sus datos mediante línea telefónica, el Banco utilizará sus datos
      para las finalidades informadas a través de dicho canal, es decir, además de las finalidades antes indicadas,
      también serán utilizados para evaluar si califica para contratar algún producto o servicio del Banco, y para
      que le puedan informar la oferta de esa oportunidad. Asimismo, el titular del dato personal otorga al Banco
      su consentimiento para requerir de manera directa a su empleador, información de contacto debidamente
      actualizada, como el número de teléfono, dirección de domicilio o algún otro dato que permita contactarlo.
    </p>
  
    <p class="paragraph">
      El otorgamiento del consentimiento a las finalidades adicionales es opcional, por tanto, usted puede otorgar su consentimiento o puede negarse a hacerlo.
      Cuando usted nos brinde su consentimiento, sus datos podrán ser tratados solo para las finalidades señaladas. Del mismo modo, su negativa al consentimiento 
      no afectará la prestación del producto o servicio que el Banco le brinde y sus datos podrán ser usados para cumplir con la preparación, celebración y ejecución 
      de la relación contractual y/o solicitar el cumplimiento de obligaciones adquiridas mediante los contratos, anexos y demás documentación del producto o servicio
       que haya contratado con nosotros.
    </p>
    <p class="paragraph">Asimismo, usted puede ejercer los derechos que le otorga la normativa según lo expuesto en la Política de Tratamiento de Datos Personales.</p>
  
</div>