<div [ngClass]="typeClass" class="cbx-container-tool">
    <div class="div-imagen"  (click)="onNavigate('')">
        <cbx-trademark type="tradeMarkDefault" *ngIf="this.sesionUsuario.userType !== 'EMP'"></cbx-trademark>
        <img [src]="imagenTool" *ngIf="sesionUsuario.userType === 'EMP'" />
    </div>

    <div class="list-items">
        <div class="items" *ngFor="let item of itemsArray"  (click)="onNavigate(item)" >
            <p class="font-secondary-bold-16"> {{ item.name }} </p>
        </div>
        <div class="name-user">
            <p class="saludo color-white" *ngIf="sesionUsuario.userType === 'EMP'"> RUC  </p>
            <p [ngClass]="{
                    'name-user--business color-secondary': sesionUsuario.userType === 'EMP',
                    'name-user--person color-white' : sesionUsuario.userType === 'PER',
                }"
              (click)="onShowSidenavMenuUser($event, 'SidenavSecondary')">
                {{ nameUsuario  | titlecase }}
            </p>
            <img class="icono-shape -mr-2" (click)="onShowSidenavMenuUser($event, 'SidenavSecondary')" src="assets/icons/icono-sahpe.svg" alt="icono del menu" width="16" height="16">
            <img class="icono-menu" (click)="onShowSidenavMenuUser($event, 'SidenavPrimary')" src="assets/imagenes/icon-menu.png" alt="icono del menu" width="24" height="24">
        </div>
    </div>
</div>
