import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
const getStyles = (...args: string[]) => [...args].filter(Boolean)
export enum EInputValidation {
  Number = 'number',
  Alpha = 'alpha',
  Alphanumeric = 'alphanumeric',
  Text = 'text',
}

@Component({
  selector: 'cbx-auth-input',
  templateUrl: './auth-input.component.html',
  styleUrls: ['./auth-input.component.scss']
})
export class AuthInputComponent {

  @Input() idTextField = 'idTextField';
  @Input() placeholder = ' ';
  @Input() label = 'label';
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() paddingtop = false;
  @Input() value : string ="";
  @Input() maxLength = '60';
  @Input() size = 'md';
  @Input() paste = true;
  @Input() msgError = "";
  @Input() msgInfo = "";
  @Input() hasCounter = false;
  @Input() width = '';
  @Input() height = '48px';
  @Input() icon = '';
  @Input()
  inputValidation!: EInputValidation;
  @Output() public valueChanged = new EventEmitter();
  @Output() public blurred = new EventEmitter();
  @Output() public enter = new EventEmitter();
  @Input() typeInput:
    "ClassicInput"  | "ClassicInputPass" | "InputPass" = "ClassicInput";
  @Input() loading = false;
  @Input() readOnly=false;
  hasError = false;
  hasInfo = false;
  maxLimitLength = 60;
  maxInputLength = this.maxLimitLength;
  expression!: RegExp;
  counter = 0;
  total = 60;
  hasIcon = false;
  showPassword : boolean = false;
  @Input() recoverPass = false;

  flgVerCaracteristicas: boolean = false;
  existeNumber: boolean = false;
  existeMayuscula: boolean = false;
  existeMinuscula: boolean = false;
  existeSimbolo: boolean = false;
  existelengValidate: boolean = false;

  
  flgPassValido: boolean = false;
 
  classInput = {
    'text-input': true,
    'error-input': false
  };
  classInfo = {
    'info-container': true,
    'counter-container': false
  };

  public get typeInputs(): string[] {
    return getStyles(this.typeInput)
  }

  ngOnChanges(changes: SimpleChanges) {
   
    if (this.msgInfo != "" && this.msgError != "") {
      this.hasInfo = false;
      this.hasError = true;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    if (this.msgInfo == "" && this.msgError == "") {
      this.hasInfo = false;
      this.hasError = false;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    if (this.msgInfo == "" && this.msgError != "") {
      this.hasInfo = false;
      this.hasError = true;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    if (this.msgInfo != "" && this.msgError == "") {
      this.hasInfo = true;
      this.hasError = false;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    if (this.hasCounter && (this.msgError == "" && this.msgInfo == "")) {
      this.classInfo['info-container'] = false;
      this.classInfo['counter-container'] = true;
    }
    if ( this.flgVerCaracteristicas  && this.msgInfo == "") {
      this.hasError = false;
      this.classInput['error-input'] = !this.hasError;
      this.classInput['text-input'] = this.hasError;
    }
    if(this.recoverPass ){ 
      this.onchangeIconPass();
      this.hasError = true;
      this.flgVerCaracteristicas = true;  
      this.classInput['error-input'] = this.msgError != "" ?  this.hasError : !this.hasError;
      this.classInput['text-input'] =  this.msgError != "" ?  !this.hasError : this.hasError; 
    }

  

    else {
      this.classInfo['info-container'] = true;
      this.classInfo['counter-container'] = false;
    }
    if (this.icon != "") this.hasIcon = true;
    else this.hasIcon = false; 
    this.total = Number(this.maxLength); 

  }
  onChange(target: any) {  
     
    switch (this.inputValidation) {
      case EInputValidation.Number:
        this.expression = /[A-Za-zÑÁÉÍÓÚñáéíóú`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Alpha:
        this.expression = /[0-9`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Alphanumeric:
        this.expression = /[`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Text:
        this.expression = /[0-9`~!¡@#$%^&*()_|+\-=?;:'",.<>°]/g;
        break;
    }

    if (this.expression) {
      target.value = target.value
        .replace(this.expression, ''); 
    }
     
    target.value = target.value.substr(0, Number(this.maxLength));
    this.counter = target.value.length; 

    this.validPassword(target.value); 
    this.blurred.emit(target.value); 
    this.valueChanged.emit(target.value)
  }

  onShowValidPass(bool : boolean){ 
    this.flgVerCaracteristicas = bool;
  }

  onFocus(event : any){  
    this.flgVerCaracteristicas = true;
    this.hasError = false;
  }

  validPassword(target : string) { 
    var contrasenna = target;
    this.existeMayuscula = false;
    this.existeMinuscula = false;
    this.existeNumber = false;
    this.existeSimbolo = false;
    this.existelengValidate = false;   

    for (var i = 0; i < contrasenna.length; i++) {
      if (contrasenna.charCodeAt(i) >= 65 && contrasenna.charCodeAt(i) <= 90) {
        this.existeMayuscula = true;
      } else if ( contrasenna.charCodeAt(i) >= 97 && contrasenna.charCodeAt(i) <= 122 ) {
        this.existeMinuscula = true;
      } else if ( contrasenna.charCodeAt(i) >= 48 && contrasenna.charCodeAt(i) <= 57 ) {
        this.existeNumber = true;
      } else if (
        contrasenna.charCodeAt(i) >= 33 && contrasenna.charCodeAt(i) <= 47 ||
        contrasenna.charCodeAt(i) >= 58 && contrasenna.charCodeAt(i) <= 64 ||
        contrasenna.charCodeAt(i) >= 123 && contrasenna.charCodeAt(i) <= 126
        ) {
        this.existeSimbolo = true;
      }
    }

    if (contrasenna.length >= 8) { 
      this.existelengValidate = true;
    }
 
    if (this.existeMayuscula && this.existeMinuscula && this.existeSimbolo && this.existelengValidate && this.existeNumber) {  
      this.flgVerCaracteristicas = this.recoverPass ?  true : false;  
      
      if(this.recoverPass){   
        this.classInput['error-input'] = false;
        this.classInput['text-input'] = true; 
      } 
    } 
  }
  onBlur(event :any ){ 
    this.hasError = true;
    this.flgVerCaracteristicas = this.recoverPass ? true : false;
  }

  onEnter(event: any, value: any) {
    this.enter.emit(value || event.target.value);
  }

  onPaste(event: ClipboardEvent) {
    if ((window as any).clipboardData) {
      const pastedText = (window as any).clipboardData;
    } else {
      const pastedText = event?.clipboardData?.getData('text');
    }
    return this.paste;
  }

  onchangeIconPass(){
    this.showPassword = !this.showPassword
  }
}

