import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
const getStyles = (...args: string[]) => [...args].filter(Boolean)
 

@Component({
  selector: 'cbx-expandable-input',
  templateUrl: './expandable-input.component.html',
  styleUrls: ['./expandable-input.component.scss'],
})
export class ExpandableInputComponent { 
  @ViewChild('cbxDropDownList')
  cbxDropDownList!: ElementRef;
  @Input() idTextField : any;
  @Input() placeholder = ' ';
  @Input() label = 'label';
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() expandable = false;
  @Input() value = '';
  @Input() size = 'md'; 
  @Output() public valueChanged = new EventEmitter();
  @Output() public dataValue = new EventEmitter();
  @Input() typeInput:    "Classic"  | "ClassicRegister" = "Classic";
  @Input() readOnly = false;
  @Input() typeView: "FULL" | "EMPTY" | "" = "EMPTY";

  classInput = {
    'expandable-input': true,
    'closed-input': false
  };

  arrow='arrow-down-gris';
  isDropdownOpen: boolean = false;
  lastIdInput : string  ="";
  public get typeInputs(): string[] {
    return getStyles(this.typeInput)
  }

  constructor(
    private elementRef: ElementRef
  ) {
  }


  ngOnChanges(changes: SimpleChanges) {  
    if (this.expandable) {
      this.classInput['expandable-input'] = true;
      this.classInput['closed-input'] = false;
      this.isDropdownOpen = true;
      this.typeView = 'FULL';
    }else {
      this.classInput['expandable-input'] = false;
      this.classInput['closed-input'] = true;
      this.typeView = 'EMPTY'; 
    }

  }

  onChange(target: any) {  
    this.valueChanged.emit(target.value);
  }

  UpdateArrow() {
    return this.typeView === 'FULL' ? this.arrow = 'arrow-up' : this.arrow = 'arrow-down-gris';
  }

 
  
  toggleDropdown() {
    if(this.disabled) return;
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) this.typeView = 'FULL';
    else this.typeView = 'EMPTY';
    this.UpdateArrow();
    this.classInput['expandable-input'] = this.typeView === 'FULL' ? true : false;
    this.classInput['closed-input'] = this.typeView === 'FULL' ? false : true;
  }


  getArrow(){ 
    return this.typeView==='FULL'? this.arrow='arrow-up':this.arrow='arrow-down-gris';
  }

  getElement(element: any) { 
    this.typeView = "EMPTY"; 
    this.UpdateArrow(); 
    this.dataValue.emit(element);
  }
 
 

 
}



