import { Component, Input } from '@angular/core';
import { ButtonService } from '../../../service/button.service';
const getStyles = (...args: string[]) => ["nombreBotton", ...args].filter(Boolean)

@Component({
  selector: 'cbx-button-action',
  templateUrl: './button-action.component.html',
  styleUrls: ['./button-action.component.scss'],
})
export class ButtonActionComponent {
  @Input() nombreBottonAction : string  = "";
  @Input() action : string = "";
  @Input() urlimagen : string | null = "";
  @Input() type : 
   "BottonDefault" | "BottonPrimary"  | "BottonSecondary"  = "BottonDefault";
   @Input() size = "";


  public get typeClass(): string[] {
   return getStyles(this.type)
  }

  public get typeSize(): string {
    return `${this.type}__${this.size}`;
  }

  constructor(  
    private serviceButton: ButtonService, 
  ) {    
   
  }

  onAction(value : string){ 
    this.serviceButton.setAction(value); 
  }


}
