<ng-container [ngSwitch]="typeProgress">
  <ng-container *ngSwitchCase="'Classic'">
    <div [ngClass]="ClassSize">
      <div class="row-one">
        <div class="level">
          <img
            *ngIf="hasIcon"
            src="assets/imagenes/progressbar/level-{{ levelImg }}.svg"
            alt="icono de nivel "
          />
          Nivel {{ levelText }}
        </div>
        <div class="points" *ngIf="hasStatus">{{ points }}/{{ total }}</div>
      </div>
      <div class="pb-container">
        <div
          class="pb-percent"
          [style.width]="percent"
          [style.height]="height"
        ></div>
      </div>
    </div>
  </ng-container>
</ng-container>
