<div class="funds">
    <div class="funds__content" >

        <div class="btn-cerrar">
            <div class="btn-cerrar__icon font-secondary-bold-16" (click)="onClose()">
                <p> x </p> 
            </div>
            
        </div>
 
        <div class="body-responsive">   
            <div class="body-responsive__title color-secondary">{{ data.title }}</div> 
        </div>
     

        <div class="body">  
            <!-- INFO -->
            <div class="body__info">  
                <div class="title color-secondary">{{ data.title }}</div>
                <div class="subtitle">{{ data.subtitle }}</div>
                <div class="btn-registro">
                        <cbx-button
                            (click)="onRegistrarEmpresa()"
                            type="BottonPrimary"
                            [size]="size"
                            [nombreBotton]="data.nameBoton"
                            [sizeResponsive]="true"
                        ></cbx-button>
                </div>
            </div>
            <!-- IMAGEN -->
            <div class="body__imagen">
                <img src="assets/landing/images/persona-card-registro-empresa.svg" alt="">
            </div> 
        </div>
     
    </div>
    <div class="funds__loader-line" *ngIf="loaderOtp"    ></div>
</div>
