export const messageType = {
  algo_salio_mal:
  {
      titulo: 'Algo salió mal',
      body: 'Lo sentimos, se ha presentado un inconveniente. Por favor inténtalo nuevamente.',
      icon: 'assets/imagenes/iconos/icono-algo-salio-mal.svg',
      nameBoton: 'Aceptar',
      sizeButton: ''
  },
  algo_salio_mal_ResponseService:
  {
      titulo: 'Algo salió mal',
      body: '',
      icon: 'assets/imagenes/iconos/icono-algo-salio-mal.svg',
      nameBoton: 'Aceptar',
      sizeButton: ''
  },
  cancelar:
  {
      titulo: 'Cancelar Operación',
      body: '¿Estás seguro de cancelar tu operación?',
      icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
      nameBotonConfirm: 'Si',
      nameBotonCancel: 'No',
      sizeButton: ''
  },
  cerrar_sesion:
  {
      titulo: '¿Estas seguro de cerrar sesión?',
      body: 'Se cerrará tu sesión inmediatamente ',
      icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
      nameBotonConfirm: 'Si',
      nameBotonCancel: 'No',
      sizeButton: ''
  },
  fuera_horario:
  {
      titulo: 'Operación fuera de horario',
      body: 'En este momento no podemos atenderte. Puedes realizar operaciones en el siguiente horario:',
      icon: 'assets/imagenes/iconos/icono-op-fuera-horario.svg',
      nameBoton: 'Aceptar',
      sizeButton: 'sm'
  }

}

export const messageTransferenciaType = {
  default:
  {
      titulo: '¡Transferencia Exitosa! ',
      body: 'La transferencia se realizó con éxito.',
      icon: 'assets/imagenes/modals/mano-dollar.svg',
      nameBoton: 'Entendido',
      sizeButton: ''
  }
  ,
  exito:
  {
      titulo: '¡Transferencia Exitosa!',
      body: 'La transferencia se realizó con éxito.',
      icon: 'assets/imagenes/modals/mano-dollar.svg',
      nameBoton: 'Entendido',
      sizeButton: ''
  }
  ,
  pendiente:
  {
      titulo: '¡Transferencia pendiente! ',
      body: 'Debes realizar una transferencia inmediata para continuar con la operación.',
      icon: 'assets/imagenes/iconos/icono-tranferencia-pendiente.svg',
      nameBoton: 'Entendido',
      sizeButton: ''
  }
  ,
  en_proceso:
  {
      titulo: '¡Transferencia pendiente!',
      body: 'Esperanos unos minutos, estamos validando tu operación.',
      icon: 'assets/imagenes/iconos/icono-tranferencia-pendiente.svg',
      nameBoton: 'Entendido',
      sizeButton: ''
  }
  ,

}

export const messageAuth = {
  revisa_tus_datos:
  {
      titulo: 'Revisa tus datos',
      body: 'Alguno de los datos ingresado son incorrectos, revísalos antes de continuar.',
      icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
      nameBoton: 'Aceptar',
      sizeButton: ''
  },
  datos_existentes:
  {
      titulo: 'Revisa tus datos',
      body: 'Estos datos ya se encuentran registrados, debes ir a la opción de iniciar sesión.',
      icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
      nameBoton: 'Iniciar sesión',
      sizeButton: ''
  },
  datos_Noexistentes:
  {
      titulo: 'Revisa tus datos',
      body: 'Los datos ingresados no están registrados en Cambix. Te invitamos a registrarte.',
      icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
      nameBoton: 'Ir a registro',
      sizeButton: ''
  },
  lista_negra:
  {
      titulo: 'Lo sentimos ',
      body: 'Por el momento no puedes continuar con  tu registro. Para mayor información, ponte en contacto con nosotros.',
      icon: 'assets/imagenes/modals/mano-dollar.svg',
      nameBoton: 'Aceptar',
      sizeButton: ''
  },
  revisa_tu_contrasena:
  {
      titulo: 'Revisa tu contraseña',
      body: 'Por seguridad, evita usar tus últimas 5 contraseñas. Por favor elige una nueva',
      icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
      nameBoton: 'Aceptar',
      sizeButton: ''
  },
}

export const messageCamara = {
  not_camara:
  {
      titulo: 'Ocurrió algo con tu cámara',
      body: 'Detectamos que no tienes cámara, puedes realizar la validación de identidad en otro momento.',
      icon: 'assets/imagenes/modals/mano-dollar.svg',
      nameBoton: 'Aceptar',
      sizeButton: ''
  },
}

export const messageCCI = {
  cci_duplicado:
  {
      titulo: 'Datos de CCI duplicados',
      body: 'Los datos del CCI empresa ya están registrados.',
      icon: 'assets/imagenes/modals/mano-dollar.svg',
      nameBoton: 'Aceptar',
      sizeButton: ''
  },
  cci_error:
  {
      titulo: 'Ocurrió un error',
      body: '',
      icon: 'assets/imagenes/modals/mano-dollar.svg',
      nameBoton: 'Aceptar',
      width: '300px',
      sizeButton: ''
  },
  cci_en_uso:
  {
      titulo: 'Selecciona otro CCI',
      body: 'El CCI lo estas usando en una operación de cambio, elige otro.',
      icon: 'assets/imagenes/modals/mano-dollar.svg',
      nameBoton: 'Aceptar',
      width: '300px',
      sizeButton: ''
  },
}

export const messageBiometric = {
  no_se_pudo_validar_tu_identidad:
  {
      titulo: 'Algo salió mal',
      body: 'No se pudo validar tu identidad.',
      icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
      nameBoton: 'Aceptar',
      sizeButton: ''
  },
  pasar_validacion_identidad:
  {
      titulo: 'Validación de identidad',
      body: 'Para continuar con tu operación de cambio debes pasar el proceso de validación de identidad.',
      icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
      nameBoton: 'Aceptar',
      sizeButton: ''
  },
  usuario_bloqueado:
  {
      titulo: 'Recuerda lo siguiente',
      body: 'Por tu seguridad  la cuenta ha sido bloqueada por haber superado los intentos permitidos de verificación biométrica, regresa en:',
      icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
      nameBoton: 'Aceptar',
      sizeButton: ''
  }
}

export const messageDelete = {
  delete_representante:
  {
      titulo: 'Eliminar representante',
      body: '¿Estás seguro de eliminar este representante?',
      icon: 'assets/imagenes/modals/mano-dollar.svg',
      nameBotonConfirm: 'Si',
      nameBotonCancel: 'No',
      sizeButton: ''
  },
  delete_accionistas:
  {
      titulo: 'Eliminar accionista',
      body: '¿Estás seguro de eliminar este accionista?',
      icon: 'assets/imagenes/modals/mano-dollar.svg',
      nameBotonConfirm: 'Si',
      nameBotonCancel: 'No',
      sizeButton: ''
  },
  delete_accionistas_registrados:
  {
      titulo: 'Recuerda que',
      body: ' Se borrarán los datos ingresados de cada accionista(s), ¿Estas seguro?',
      icon: 'assets/imagenes/modals/mano-dollar.svg',
      nameBotonConfirm: 'Si',
      nameBotonCancel: 'No',
      sizeButton: ''
  },
}

export const messageAppVersion = {
default: {
    titulo: 'Cambix tiene una nueva versión',
    body: 'La aplicación se reiniciará.<br>Debe iniciar sesión de nuevo.<br>',
    icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
    nameBoton: 'Entendido',
    sizeButton: ''
},
general: {
    titulo: 'Cambix tiene una nueva versión',
    body: 'Actualizaremos dependencias.',
    icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
    nameBoton: 'Entendido',
    sizeButton: ''
},
}

export const messageFileUpload = {
errorFileSize: {
    titulo: 'No se puede adjuntar constancia',
    body: 'Superaste el peso máximo permitido de <br>10 MB<br>',
    icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
    nameBoton: 'Aceptar',
    sizeButton: ''
},
errorFileType: {
    titulo: 'No se puede adjuntar constancia',
    body: 'El archivo elegido no es el tipo de<br> formato permitido',
    icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
    nameBoton: 'Aceptar',
    sizeButton: ''
},
}

export const messageHelp = {
  remainder: {
    titulo: 'Estamos para ayudarte',
    body: 'Hemos recibido tu solicitud de ayuda. En breve nos pondremos en contacto contigo para revisar el estado de tu operación.<br><br><b>Cualquier ayuda adicional:<br>WhatsApp: 920 005 007</b>',
    icon: 'assets/imagenes/iconos/icono-revisa-tus-datos.svg',
    nameBoton: 'Aceptar',
    sizeButton: '',
  },
};
