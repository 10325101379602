import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { EChartsOption } from 'echarts';

@Injectable({
  providedIn: 'root'
})
export class LineChartService {

  private dataChart$ = new BehaviorSubject<EChartsOption>({});
  private  _dataChart: Observable<EChartsOption> = this.dataChart$.asObservable();

 
  constructor() { }
  setDataChart(value: EChartsOption) {
    this.dataChart$.next(value);
  }

   getDataChart(): Observable<EChartsOption> {
    return this._dataChart;
  }
}
