import { NgModule } from '@angular/core';
import { ButtonComponent } from '../stories/buttons/button/button.component';
import { CardsComponent } from '../stories/cards/cards.component';
import { InputComponent } from '../stories/input/normalInput/input.component';
import { MenuComponent } from '../stories/menu/menu.component';
import { ToolbarComponent } from '../stories/toolbar/toolbar.component';
import { BadgeComponent } from '../stories/badge/badge.component';
import { TableComponent } from '../stories/table/table.component';
import { ButtonService } from '../service/button.service';
import { MenuService } from '../service/menu.serice';
import { CommonModule } from '@angular/common';
import { CheckboxService } from '../service/checkbox.service';
import { CheckboxComponent } from '../stories/checkbox/checkbox.component';
import { InputKeyValidation } from '../directives/input-key-validation.directive';
import { InputSimulatorComponent } from '../stories/input/simulatorInput/input..simulator.component';
import { ReactiveFormDirective } from '../directives/reactiveForm.directive';
import { TradeMarkComponent } from '../stories/trademark/trademark.component';
import { LineChartComponent } from '../stories/line-chart/line-chart.component';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { ProgressBarComponent } from '../stories/progressbar/progressbar.component';
import { ButtonActionComponent } from '../stories/buttons/button-action/button-action.component';
import { PaginatorComponent } from '../stories/paginator/paginator.component';
import { PaginatorService } from '../service/paginator.service';
import { LineChartService } from '../service/line-chart.service';
import { ButtonTipoCambioComponent } from '../stories/buttons/button-tipo-cambio/button-tipo-cambio.component';
import { DropdownComponent } from '../stories/dropdown/dropdown.component';
import { MessageComponent } from '../stories/message/message.component';
import { SidenavComponent } from '../stories/sidenav/sidenav.component';
import { SidenavService } from '../service/sidenav.service';
import { TooltipComponent } from '../stories/tooltip/tooltip.component';
import { DialogService } from '../service/dialog.service';
import { SteppersComponent } from '../stories/steps/steps.component';
import { DialogCdkService } from '../service/dialog-cdk.service';
import { DialogComponent } from '../stories/dialogs/dialog.component';
import { TableService } from '../service/table.service';
import { OperationService } from '../service/operation.service';
import { MenuUserComponent } from '../stories/menu-user/menu-user.component';
import { DropdownListComponent } from '../stories/dropdown/Normal-dropdown/dropdown-list.component';
import { SwitchComponent } from '../stories/switch/switch.component';
import { ToggleButtonComponent } from '../stories/toggle-button/toggle-button.component';
import { PreloaderComponent } from '../stories/preloader/preloader.component';
import { PdDigitsOnlyDirective } from '../directives/digits-only.directive';
import { PreloaderService } from '../service/preloader.service';
import { AuthInputComponent } from '../stories/input/auth-input/auth-input.component';
import { AuthDropdownComponent } from '../stories/dropdown/auth-dropdown/auth-dropdown.component';
import { CbxBiometricComponent } from '../stories/biometric/biometric.component';
import { CalendarComponent } from '../stories/calendar/calendar.component';
import { DatepickerComponent } from '../stories/datepicker/datepicker.component';
import { CalendarDropdownComponent } from '../stories/dropdown/calendar-dropdown/calendar-dropdown.component';
import { StripHtmlDirective } from '../directives/strip-html.directive';
import { CarouselComponent } from '../stories/carousel/carousel.component';
import { DatepickerFilterComponent } from '../stories/datepicker/datepicker-filter/datepicker-filter.component';
import { ExpandableInputComponent } from '../stories/input/expandable-input/expandable-input.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { decimalsFormatPipe } from 'apps/cambix/src/utils/pipes/decimals.pipe';
import { EmailValidationDirective } from '../directives/email-validation.directive';
import { PasswordValidationDirective } from '../directives/pass.validate.directive';
import { CellphoneValidationDirective } from '../directives/cellphone.validation.directive';
import { AddPointsPipe } from 'apps/cambix/src/utils/pipes/add-point.pipe';
import { FromatterCCIPipe } from 'apps/cambix/src/utils/pipes/formatter-cci.pipe';
import { FormatAmountPipe } from 'apps/cambix/src/utils/pipes/format-amount.pipe';


@NgModule({
  imports: [
    CommonModule,
    NgxEchartsModule.forRoot({
      echarts
    }),
    NgxSkeletonLoaderModule.forRoot(),

  ],


  declarations: [
    ButtonComponent,
    CardsComponent,
    InputComponent,
    MenuComponent,
    ToolbarComponent,
    BadgeComponent,
    TableComponent,
    CheckboxComponent,
    InputKeyValidation,
    InputSimulatorComponent,
    ReactiveFormDirective,
    TradeMarkComponent,
    LineChartComponent,
    ProgressBarComponent,
    ButtonActionComponent,
    ButtonTipoCambioComponent,
    PaginatorComponent,
    DropdownComponent,
    DialogComponent,
    MessageComponent,
    SidenavComponent,
    TooltipComponent,
    SteppersComponent,
    MenuUserComponent,
    DropdownListComponent,
    SwitchComponent,
    ToggleButtonComponent,
    PreloaderComponent,
    PdDigitsOnlyDirective,
    AuthInputComponent,
    AuthDropdownComponent,
    CbxBiometricComponent,
    StripHtmlDirective,
    CalendarComponent,
    DatepickerComponent,
    CalendarDropdownComponent,
    CarouselComponent,
    DatepickerFilterComponent,
    ExpandableInputComponent,
    decimalsFormatPipe,
    EmailValidationDirective,
    PasswordValidationDirective,
    CellphoneValidationDirective,
    AddPointsPipe,
    FromatterCCIPipe,
    FormatAmountPipe
  ],



  exports: [
    ButtonComponent,
    CardsComponent,
    InputComponent,
    MenuComponent,
    ToolbarComponent,
    BadgeComponent,
    TableComponent,
    CheckboxComponent,
    InputKeyValidation,
    InputSimulatorComponent,
    ReactiveFormDirective,
    TradeMarkComponent,
    LineChartComponent,
    ProgressBarComponent,
    ButtonActionComponent,
    ButtonTipoCambioComponent,
    PaginatorComponent,
    DropdownComponent,
    DialogComponent,
    MessageComponent,
    SidenavComponent,
    TooltipComponent,
    SteppersComponent,
    MenuUserComponent,
    DropdownListComponent,
    SwitchComponent,
    ToggleButtonComponent,
    PreloaderComponent,
    PdDigitsOnlyDirective,
    AuthInputComponent,
    AuthDropdownComponent,
    CbxBiometricComponent,
    StripHtmlDirective,
    CalendarComponent,
    DatepickerComponent,
    CalendarDropdownComponent,
    CarouselComponent,
    DatepickerFilterComponent,
    ExpandableInputComponent,
    decimalsFormatPipe,
    EmailValidationDirective,
    PasswordValidationDirective,
    CellphoneValidationDirective,
    AddPointsPipe,
    FromatterCCIPipe,
    FormatAmountPipe
  ],


  providers: [
    ButtonService,
    CheckboxService,
    LineChartService,
    MenuService,
    PaginatorService,
    SidenavService,
    DialogService,
    DialogCdkService,
    TableService,
    OperationService,
    PreloaderService,
  ],


})
export class CambixUiModule { }
