import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { AuthReqV2, AuthReqV3 } from '../../../models';


@Injectable()
export class AuthService {

  private path: string;
  private pathv2: string;
  constructor(

    private apiService: ApiService) {
    this.path = 'v1/auth/';
    this.pathv2 = 'v2/auth/';

  }

  authUserv2(reCaptchaToken: string, cellphone: string, password: string): Observable<any> {
    const authReq: AuthReqV2 = {
      cellphone,
      password,
      reCaptchaToken
    };
    return this.apiService.post(this.pathv2 + 'login', authReq);


  }

  authUserV3(reCaptchaKey: string, cellphone: string, password: string): Observable<any> {
    const authReq: AuthReqV3 = {
      platformCode: 'WEB',
      cellphone,
      password,
      reCaptchaKey
    };
    return this.apiService.post('v3/auth/login', authReq);


  }
  authUserReset(token: string, code: string, password: string): Observable<any> {
    const authReq = {
      token,
      code,
      password
    };
    return this.apiService.post('v3/auth/password', authReq);
  }
}
