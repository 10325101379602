 <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'switchFilter'">
        <div class="switchFilter">  
            <div [ngClass]="typeClassOpcion">
                <div class="contenedor">
                    <div class="firt-switch"
                        (click)="onSelectFilter('switchSecondary')">
                        {{namefirstOpcion}}
                    </div>
                    <div class="second-switch"
                        (click)="onSelectFilter('switchPrimary')">
                        {{namesecondOpcion}}
                    </div>
                </div>
            </div>
         
        </div> 
    </ng-container>
    <ng-container *ngSwitchCase="'switchDeclarate'">
        <div class="switchDeclarate">
            <div [ngClass]="typeClassOpcion">
                <div class="contenedor">
                    <div class="firt-switch font-secondary-regular-14"
                        (click)="onSelectFilter('switchSecondary')">
                        {{namefirstOpcion}}
                    </div>
                    <div class="second-switch font-secondary-regular-14"
                        (click)="onSelectFilter('switchPrimary')">
                        {{namesecondOpcion}}
                    </div>
                </div>
            </div> 
        </div> 
    </ng-container>
</ng-container>
