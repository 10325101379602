import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../api.service';
import {
  OperationReq,
  OperationRes,
  PostRes,
  OperationCardPaymentRes,
  UserBenefitRes,
  UpdateOperationReq,
  IOperationList,
} from '../../../models';

import { catchError } from 'rxjs/operators';
import { OPERATION_STATUS } from 'apps/cambix/src/utils/constants/operation-state';

@Injectable()
export class OperationsService {
  private path: string;
  private pathv2: string;
  private pathv3: string;

  constructor(private apiService: ApiService) {
    this.path = 'v1/exchange-operations/';
    this.pathv2 = 'v2/exchange-operations/';
    this.pathv3 = 'v3/exchange-operations/';
  }

  getOperations(typeOperation: number): Observable<[OperationRes]> {
    const params = {
      typeOperation: String(typeOperation),
    };
    return this.apiService.get(
      this.path + '?',
      new HttpParams({ fromObject: params })
    );
  }

  getOperationsByState(state: string): Observable<IOperationList[]> {
    const params = {
      operationStatusCode: state,
    };

    return this.apiService.get(
      this.pathv2,
      new HttpParams({ fromObject: params })
    );
  }

  getOperationsSecondPlane() {
    const statusInProgress = new HttpParams({
      fromObject: { operationStatusCode: OPERATION_STATUS.IN_PROGRESS },
    });
    const statusEnded = new HttpParams({
      fromObject: { operationStatusCode: OPERATION_STATUS.SUCCESS },
    });

    const operationsInProgress = this.apiService
      .get(this.pathv2, statusInProgress)
      .pipe(catchError((error) => of([])));
    const operationsEnded = this.apiService
      .get(this.pathv2, statusEnded)
      .pipe(catchError((error) => of([])));

    return forkJoin([operationsInProgress, operationsEnded]);
  }

  getOperation(idOperation: string): Observable<OperationRes> {
    return this.apiService.get(
      this.pathv3 + 'exchange-operation/' + idOperation
    );
  }

  saveOperation(operationReq: OperationReq): Observable<PostRes> {
    return this.apiService.post(
      this.pathv2 + 'exchange-operation',
      operationReq
    );
  }

  getOperationCreditCard(
    idOperation: string
  ): Observable<OperationCardPaymentRes> {
    return this.apiService.get(
      this.path + 'exchange-operation/credit-card/' + idOperation
    );
  }

  saveOperationCreditCard(operationRes: OperationRes): Observable<PostRes> {
    return this.apiService.post(
      this.path + 'exchange-operation/pay-credit-card',
      operationRes
    );
  }

  confirmOperation(operationRes: OperationRes): Observable<any> {
    return this.apiService.put(this.path + 'exchange-operation', operationRes);
  }

  cancelOperation(operationRes: OperationRes): Observable<any> {
    return this.apiService.put(
      this.path + 'exchange-operation/cancel',
      operationRes
    );
  }

  cancelOperationv2(
    idOperation: number,
    stateCode: string
  ): Observable<PostRes> {
    const updateOperationReq: UpdateOperationReq = {
      stateCode,
    };
    return this.apiService.patch(
      this.pathv2 + 'exchange-operation/' + idOperation,
      updateOperationReq
    );
  }

  getUserBenefit(documentNumber: string): Observable<UserBenefitRes> {
    return this.apiService.get(this.pathv2 + 'user-benefit');
  }
}
