 <div [ngClass]="typeClass"  (click)="onCloseDialog()">   
    <div  [ngClass]="{'content' : isShowDialog, 'contentDark' : !isShowDialog}"  >  
        <div class="modal-content" > 
            <div class="modal-text">  
                <div class="imagenModal" *ngIf="imagenModal">
                    <img [src]="imagenModal" alt="imagen del modal">
                </div>
    
                <div class="contenido">
                    <h2 class="titulo-modelo-1 font-primary-bold-h6"> {{ titulo }}</h2> 
                    <div class="titulo-modelo-2">
                        <h2 class="font-primary-bold-h6"> {{ titulo }}</h2>
                        <div class="subrayado"></div>
                    </div> 
                    <div class=" font-secondary-regular-16">
                        {{body}}
                    </div> 
                </div> 
                <div class="botones"> 
                    <cbx-button   
                        *ngIf="firstButton" 
                        [size]="sizeButton" 
                        [isDisabled]="false" 
                        [nombreBotton]="firstButton" 
                        type="BottonSecondary"> 
                    </cbx-button>  

                    <cbx-button  
                        (clicked)="onOk()"
                        *ngIf="secondtButton" 
                        [size]="sizeButton" 
                        [isDisabled]="false"
                        [nombreBotton]="secondtButton"
                        type="BottonPrimary">
                    </cbx-button>
                </div> 
            </div>  
        </div>
    </div> 
</div> 