<div class="funds">
    <div class="funds__content">
        <div class="funds__title font-primary-bold-h6 color-black-300 ">
            <span class="funds__title__line ">Val</span>idación de operación
        </div>
        <div class="font-secondary-regular-14 color-black-100 marginx">
            Para validar tu  transferencia, necesitamos que nos envíes el voucher de tu operación
        </div>
        <div class="font-primary-bold-h6 color-black-300 marginx">
            Te damos estas opciones
        </div>
        <div class="funds__info font-primary-bold-h5 mb-4">
            <div class="font-secondary-regular-12  color-black mb-2"> Al número del celular del whatsapp de Cambix </div>
            <div class="font-primary-bold-h6 color-black-300 funds__info--dato">  920 005 007 
                <img (click)="onCopyCodigoReferencia('920 005 007',1)" class="cursor-pointer" src="assets/imagenes/icon-copy-black.svg" alt="icono de copiar"> 
            </div>  
            <div [ngClass]="{ 'mensaje-copy' : !showMssCopy , 'mensaje-copy-show' : showMssCopy }" class="color-black font-secondary-regular-16">
                Copiado
            </div>
        </div>
     
        <div class="funds__info font-primary-bold-h5 mb-4">
            <div class="font-secondary-regular-12  color-black mb-2">Al correo electrónico de Cambix</div>
            <div class="font-primary-bold-h6 color-black-300 funds__info--dato"> Cambix_info@bancom.pe 
                <img (click)="onCopyCodigoReferencia('Cambix_info@bancom.pe',2)" class="cursor-pointer" src="assets/imagenes/icon-copy-black.svg" alt="icono de copiar"> 
            </div> 
            <div [ngClass]="{ 'mensaje-copy2' : !showMssCopy2 , 'mensaje-copy-show-two' : showMssCopy2 }" class="color-black font-secondary-regular-16">     
                Copiado
            </div> 
        </div>
      
      
        <div class="funds__boton">
            <cbx-button
                (click)="onAceptar()"
                type="BottonPrimary"
                size="xl"
                nombreBotton="Aceptar"
                [sizeResponsive]="true"
            ></cbx-button>
        </div>
    </div>
</div>
