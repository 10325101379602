import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { ISideNavMenuUser } from '../interfaces/menu.interface';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  sidenavMenuUser : ISideNavMenuUser = {
    active: false,
    type: 'SidenavPrimary'
  };
  private isHiddenSidenav$ = new BehaviorSubject<boolean>(false);
  private  _isShow: Observable<boolean> = this.isHiddenSidenav$.asObservable();

  private isHiddenSidenavMenuUser$ = new BehaviorSubject<ISideNavMenuUser>(this.sidenavMenuUser);
  private  _isShowMenuUser: Observable<ISideNavMenuUser> = this.isHiddenSidenavMenuUser$.asObservable();


  constructor() { }

  setisActive(value: boolean) {
    this.isHiddenSidenav$.next(value);
  }

  setisActiveMenuUser(value: boolean, type: 'SidenavPrimary' | 'SidenavSecondary' = 'SidenavPrimary') {
    this.sidenavMenuUser = {
        active: value,
        type : type,
    }
    this.isHiddenSidenavMenuUser$.next(this.sidenavMenuUser);
  }


  showSidenav(): Observable<boolean> {
    return this._isShow;
  }

  showSidenavMenuUser(): Observable<ISideNavMenuUser> {
    return this._isShowMenuUser;
  }

}
