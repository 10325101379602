import { Component, EventEmitter, Input, Output } from '@angular/core';


const getStyles = (...args: string[]) => [...args].filter(Boolean)


@Component({
  selector: 'cbx-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent {
 
  @Input() type: "switchDefault" |  "switchDefaultDeclarate" | "switchFilter" | "switchDeclarate" = "switchDefault";
  @Input() namefirstOpcion : string = '';
  @Input() namesecondOpcion: string = '';
  @Input() styleOpcion : string = ''; 
  @Output() changeType = new EventEmitter();


  public get typeClass(): string[] {
    return getStyles(this.type)
  }

  public get typeClassOpcion(): string[] {
    return getStyles(this.styleOpcion)
  }
 

  onSelectFilter(typeSwitch: string) {
    this.changeType.emit(typeSwitch);
  }

}
