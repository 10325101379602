<div class="funds">
    <div class="funds__content"> 
        <img class="imagen" [src]="data.icon" alt="icono de cerrar sesion"> 
        <div class="titulo font-primary-bold-h6 mb-4"> {{ data.titulo }}</div>
        <div class="font-secondary-regular-16 mb-6" [innerHtml]="bodyHtml"> </div> 
        <div class="boton">
            <cbx-button (clicked)="onCancel()" type="BottonSecondary" size="md"  [nombreBotton]="data.nameBotonCancel" [sizeResponsive]="true" ></cbx-button>
            <cbx-button (clicked)="onAceptar()" type="BottonPrimary" size="md" [loadingdButton]="loadingButtonConfirm" [nombreBotton]="data.nameBotonConfirm" [sizeResponsive]="true"></cbx-button>
        </div>
    </div>  
</div>