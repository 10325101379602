import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CheckboxService {

  private isDisabledSource$ = new BehaviorSubject<boolean>(false);
  private  _isDisabled: Observable<boolean> = this.isDisabledSource$.asObservable();

 
  constructor() { }
  setisDisabled(value: boolean) {
    this.isDisabledSource$.next(value);
  }

   getisDisabled(): Observable<boolean> {
    return this._isDisabled;
  }
}
