import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from 'libs/cambix/ui/src/directives/dialog-cdk-ref';
import { DIALOG_DATA } from 'libs/cambix/ui/src/directives/dialog-cdk-tokens';
import { Subject, catchError, finalize, of, tap } from 'rxjs';
import { RegistersService } from '../../auth/service/register.service';
import { MigrationChangePassHttp } from '../../migration/http/migrationChangePass.http';


@Component({
  selector: 'cambix-web-v2.0-dialog-otp-cellphone',
  templateUrl: './dialog-otp-cellphone.component.html',
  styleUrls: ['./dialog-otp-cellphone.component.scss'],
})
export class DialogOtpCellphoneComponent {

  @ViewChild('digit1', { static: true }) digit1!: ElementRef;
  @ViewChild('digit2', { static: true }) digit2!: ElementRef;
  @ViewChild('digit3', { static: true }) digit3!: ElementRef;
  @ViewChild('digit4', { static: true }) digit4!: ElementRef;
  @ViewChild('digit5', { static: true }) digit5!: ElementRef;
  @ViewChild('digit6', { static: true }) digit6!: ElementRef;
  @ViewChild('inputnewCellphone', { static: false }) inputnewCellphone!: ElementRef;
  loaderOtp = false;
  width = '315px';
  destroyed = new Subject<void>();
  otpCodeForm: FormGroup;
  newCellPhoneForm: FormGroup;

  cronometro: any;
  timerId: any;
  arrCode = ['', '', '', '', '', ''];
  secureCellphone: string = "";
  secureEmail: string = "";
  disableResendCode = true;

  flgResendCode: boolean = false;
  flgErroCodeService: boolean = false;
  flgValidarCellphone: boolean = false;
  flgTimeoOut: boolean = false;
  codeOTP: any;

  get frmCellphone(): FormControl {
    return this.newCellPhoneForm.get("cellphone") as FormControl;
  }

  getInputByDigit(digit: string): FormControl {
    return this.otpCodeForm.get(digit) as FormControl
  }

  get getErrorCellphone(): string {
    if (this.frmCellphone.invalid && this.frmCellphone.touched) {
      if (this.frmCellphone.hasError('required')) {
        return 'Ingresa un celular';
      }
      if (this.frmCellphone.hasError('customErrorCellphone')) {
        return this.frmCellphone?.errors?.['customErrorCellphone'];
      }
    }
    return '';
  }


  constructor(
    private fb: FormBuilder,
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private service: RegistersService,
    private migrationService : MigrationChangePassHttp
  ) {

    this.otpCodeForm = this.fb.group({
      digit01: ['', Validators.required],
      digit02: ['', Validators.required],
      digit03: ['', Validators.required],
      digit04: ['', Validators.required],
      digit05: ['', Validators.required],
      digit06: ['', Validators.required],
    });


    this.newCellPhoneForm = this.fb.group({
      cellphone: ['', [Validators.required, Validators.minLength(9),Validators.maxLength(9), Validators.pattern("^(?:9\\d{0,})")]],
    });
  }


  ngOnInit(): void {
    this.startTimer();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }



  pasteDigit(event: any, previous: any, current: any, next: any, index: any, textPaste: any = null, isPaste: boolean = false) {

    if (isPaste) {
      this.flgErroCodeService = false;
      let indexPaste = 0;
      if (textPaste !== null && textPaste !== "") {
        const code = textPaste.replace(/\s/g, "");
        const charsCode = [...code];
        charsCode.forEach((dig) => {
          if (dig?.match(/[0-9]/g) && indexPaste < 6) {
            this.arrCode[indexPaste] = dig;
            this.codeOTP = this.arrCode.join('');
            let indexString = indexPaste + 1;
            let digitInput = `digit0${indexString.toString()}`;
            this.getInputByDigit(digitInput).setValue(dig);
            if (indexPaste === 5 || this.codeOTP.length === 6) {
              this.setFocusByDigit(indexPaste + 1);
            }
            if (indexPaste === 5) {
              this.onValideCodeOTP();
              return;
            }
            indexPaste++;
          }
        });
      }


    }
  }
  moveDigit(event: any, previous: any, current: any, next: any, index: any, textPaste: any = null, isPaste: boolean = false) {

    if (!isPaste && event.keyCode !== 17 && event.keyCode !== 86) {
      this.flgErroCodeService = false;
      let text = (event.target as HTMLInputElement).value;
      if (!(text?.match(/[0-9]/g)) && (event.keyCode != 8)) { current.value = ""; return; }

      let length = current.value.length;
      let max_length = current.getAttribute('maxlength');

      if (length == max_length && next != '') next.focus();

      if (event.keyCode == 8 && previous != '') {
        this.arrCode[index] = "";
        previous.focus();
        return;
      }
      if (text?.match(/[0-9]/g)) {
        this.arrCode[index] = text;
        this.codeOTP = this.arrCode.join('');
        if (index === 5 || this.codeOTP.length === 6) {
          this.onValideCodeOTP();
        }
      } else {
        this.arrCode[index] = "";
      }

    }
  }
  setFocusByDigit(indexPaste: number) {
    const selectFocus = indexPaste as 1 | 2 | 3 | 4 | 5;
    const input = {
      1: () => { this.digit2.nativeElement.focus(); },
      2: () => { this.digit3.nativeElement.focus(); },
      3: () => { this.digit4.nativeElement.focus(); },
      4: () => { this.digit5.nativeElement.focus(); },
      5: () => { this.digit6.nativeElement.focus(); }
    };

    input[selectFocus]();
  }

  resetForm() { 
    this.otpCodeForm.reset();
    this.arrCode.splice(0, this.arrCode.length).fill('', 0, 5);
    this.flgErroCodeService = false;
    this.startTimer();
  }

  resendCode() {
    if (!this.flgResendCode) {
      return;
    }

    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    this.loaderOtp = true;
    this.flgResendCode = false;
    this.flgTimeoOut = false;

    this.migrationService.resendSMSCode(this.data.token).pipe(
      tap((resp) => {
        this.data.session = resp.session;
        this.data.token = resp.token;
        this.resetForm();
      }),
      catchError(({ error }) => {
        return of("error");
      }),
      finalize(() => this.loaderOtp = false)
    ).subscribe()

  }

  startTimer() {
    this.digit1.nativeElement.focus();

    let segundos = 59;
    let minutos = 2;
    let ceromin = "";
    let ceroseg = "";

    this.timerId = setInterval(() => {
      minutos < 10 ? (ceromin = "0") : (ceromin = "");
      segundos < 10 ? (ceroseg = "0") : (ceroseg = "");

      this.cronometro = minutos + " : " + ceroseg + segundos;

      if (minutos === 0 && segundos === 0) {
        this.flgResendCode = true;
        this.flgTimeoOut = true;
        this.flgErroCodeService = false;
        this.otpCodeForm.reset();
        clearTimeout(this.timerId);
      }

      if (segundos === 0) {
        minutos--;
        segundos += 60;
      }
      segundos--;
    }, 1000);
  }

  onValidarCellphone() {
   if(!this.newCellPhoneForm.valid){
      return;
    }

  this.loaderOtp = true
  this.service.validateCellphone(this.frmCellphone.value, this.data.userType).pipe(
    tap((resp) => {
      this.flgTimeoOut = false;
      this.flgValidarCellphone = false;
      this.resetForm();
      this.data.cellphone = this.frmCellphone.value;
      this.data.session = resp.session;
    }),
    catchError(({ error }) => {
      this.inputnewCellphone.nativeElement.focus();
      this.frmCellphone.setErrors({ customErrorCellphone: 'Ingresa otro número de celular.' });
      return of("error");
    }),
    finalize(() => this.loaderOtp = false)
  ).subscribe()
  }

  onCorregirCellphone() {
    this.flgTimeoOut = true;
    this.frmCellphone.setValue(this.data.cellphone);
    setTimeout(() => {
      this.inputnewCellphone.nativeElement.focus();
    }, 500);
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.otpCodeForm.reset();
    this.flgErroCodeService = false;
    this.flgValidarCellphone = true;
  }

  onValideCodeOTP() {
    this.loaderOtp = true;
    this.flgErroCodeService = false;
    this.service.validateSmsCodeV4(this.codeOTP, this.data.session)
      .pipe(
        tap((resp: any) => {
          this.dialogRef.close(this.data);
          this.digit6.nativeElement.blur();
        }),
        catchError(({ error }) => {
          this.flgErroCodeService = true
          return of("error");
        }),
        finalize(() => this.loaderOtp = false)
      ).subscribe();
  }

}
