<div class="funds">
    <div class="funds__content" >

        <div class="divCerrar">
            <div class="divCerrar__icon font-secondary-bold-16" (click)="onClose()"> x </div>
        </div>

        <div class="datalegal">
            <ng-container [ngSwitch]="data"> 
                <ng-container *ngSwitchCase="1">
                    <cbx-terms-conditions></cbx-terms-conditions>
                </ng-container> 
                <ng-container *ngSwitchCase="2">
                    <cbx-personal-data-processing-policy></cbx-personal-data-processing-policy>
                </ng-container> 
                <ng-container *ngSwitchCase="3">
                    <cbx-authorizations-additional-pusposes></cbx-authorizations-additional-pusposes>
                </ng-container> 
                <ng-container *ngSwitchCase="4">
                    <cbx-cookies-policy></cbx-cookies-policy>
                </ng-container> 
            </ng-container>
        </div>

    </div>
</div>
