import { Component } from '@angular/core';

@Component({
  selector: 'cbx-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.scss']
})
export class CookiesPolicyComponent {

}
