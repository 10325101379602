import { Component, Inject, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogRef } from 'libs/cambix/ui/src/directives/dialog-cdk-ref';
import { DIALOG_DATA } from 'libs/cambix/ui/src/directives/dialog-cdk-tokens';
import { GlobalService } from 'libs/cambix/ui/src/service/global_service';

@Component({
  selector: 'cbx-dialog-validate-operation',
  templateUrl: './dialog-validate-operation.component.html',
  styleUrls: ['./dialog-validate-operation.component.scss'],
})
export class DialogValidateOperationComponent {
  width = 'auto';
  size = 'sm' ;
  cellphoneSeparate : any;
  showMssCopy: boolean = false;
  showMssCopy2: boolean = false;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    public service: GlobalService
    ) {
  
  }
 
  onCopyCodigoReferencia(dato : string, code : number) {
    this.service.onCopyCodeText(dato);
    if(code === 1){
      this.showMssCopy = true;
      setTimeout(() => {
        this.showMssCopy = false;
      }, 1000);
    }else{
      this.showMssCopy2 = true;
      setTimeout(() => {
        this.showMssCopy2 = false;
      }, 1000);
    }


  }

  onAceptar() { 
    this.dialogRef.close(true);
  }

}
