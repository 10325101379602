import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HomeCookiesComponent } from '../utils/dialogs/home-cookies/home-cookies.component';
import { CookiesConfigComponent } from '../utils/dialogs/cookies-config/cookies-config.component';
import { environment } from '../environments/environment';
import { filter } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { AppVersionService } from '../utils/services/app-version/app-version.service';
import { TokenStorageService } from './landing/utils/services';
import { STORAGE_KEY } from '../utils/constants/storage';
declare const gtag: Function;

@Component({
  selector: 'cambix-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  screenWidth: number;
  screenHeight: number;

  constructor(
    private appVersionService: AppVersionService,
    private tokenStorageService: TokenStorageService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.addGAScript();
    this.trackPageView();
  }
  ngOnInit(): void {
    this.checkAppVersion();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }
  ngAfterViewInit(): void {
    this.openCookiePolicy();
  }
  private openModalCookies(): MatDialogRef<HomeCookiesComponent, any> {
    return this.dialog.open(HomeCookiesComponent, {
      hasBackdrop: false,
      disableClose: true,
      autoFocus: false,
      position: {
        bottom: '0px',
      },
      scrollStrategy: new NoopScrollStrategy(),
      panelClass: 'dialog-cookies',
    });
  }
  openCookiePolicy(): void {
    const pdCookies = window.localStorage.getItem(STORAGE_KEY.accept_cookies);
    if (!pdCookies || pdCookies === 'no') {
      const dialogCookies = this.openModalCookies();
      dialogCookies.afterClosed().subscribe((isOpenConfig: boolean) => {
        if (isOpenConfig) {
          const dialogConfig = this.openModalCookiesConfig();
          dialogConfig.afterClosed().subscribe((isOpenCookies: boolean) => {
            if (isOpenConfig) {
              this.openCookiePolicy();
            }
          });
        }
      });
    }
  }
  private openModalCookiesConfig(): MatDialogRef<CookiesConfigComponent, any> {
    let bottom = '0px';
    if (this.screenWidth > 960) {
      bottom = '25px';
    }
    return this.dialog.open(CookiesConfigComponent, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      panelClass: 'dialog-cookies-config',
      position: {
        bottom,
      },
    });
  }

  /** Add Google Analytics Script Dynamically */
  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src =
      'https://www.googletagmanager.com/gtag/js?id=' +
      environment.GA_TRACKING_ID;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
  }

  trackPageView(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        /** START : Code to Track Page View  */
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
        });
        /** END */
      });
  }

  private checkAppVersion() {
    const LATEST = this.appVersionService.getLatestVersion();

    if (!this.appVersionService.hasNewVersion()) {
      return;
    }

    if(this.tokenStorageService.isAuthenticated){
      localStorage.setItem('newVersion', '1');
    }

    this.clearSession();

    if (LATEST) {
      this.appVersionService.setAppCurrentVersion(LATEST);
    }

    setTimeout(() => this.reloadApp(), 2000);
  }

  private clearSession() {
    const DATA_TO_KEEP = [];

    DATA_TO_KEEP.push({
      key: STORAGE_KEY.accept_cookies,
      value: localStorage.getItem(STORAGE_KEY.accept_cookies)
    });

    DATA_TO_KEEP.push({
      key: STORAGE_KEY.acceptedCookies,
      value: localStorage.getItem(STORAGE_KEY.acceptedCookies)
    });

    DATA_TO_KEEP.push({
      key: 'newVersion',
      value: localStorage.getItem('newVersion')
    });

    localStorage.clear();

    for (let i = 0; i < DATA_TO_KEEP.length; i++) {
      const element = DATA_TO_KEEP[i];

      localStorage.setItem(element.key, element.value);
    }
  }

  private reloadApp() {
    window.location.reload();
  }
}
