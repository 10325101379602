<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'CarouselPrimary'">
        <div class="CarouselPrimary">
            <div class="content">
                <div class="carouselService">
                    <div class="sliders">
                        <div class="icon-btn-left" (click)="onChangeCodeLeft()">
                            <img src="assets/icons/icono-sahpe.svg" alt="imagen de un hombre">
                        </div>

                        <div class="sliders__slider" >
                            <div>
                                <img class="img-web" [src]="slideService?.image.urlWebImage" alt="">
                                <img class="img-app" [src]="slideService?.image.urlAppImage" alt="">
                            </div>
                            <div class="boton">
                                <a target="_blank" [href]="slideService?.product.url">
                                    <cbx-button  [nombreBotton]="slideService?.product.content"  size="sm" type="BottonPrimary"> </cbx-button>
                                </a>
                            </div>
                        </div>

                        <div class="icon-btn-right"  (click)="onChangeCodeRight()">
                            <img src="assets/icons/icono-sahpe.svg" alt="imagen de un hombre">
                        </div>
                    </div>
                </div>

                <div class="legend" >
                    <div [ngClass]="{ 'legend__item' : i != codeCarousel,
                                    'legend__item--active' : i === codeCarousel}"
                    *ngFor="let item of arraySlidesService, let i = index" > </div>
                </div>

            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'CarouselMeses'">
        <div class="CarouselMeses">
            <div class="sliders">
                <div (click)="onChangeMonth('left')">
                    <img src="assets/imagenes/iconos/icon-paginator-left.svg" alt="imagen de un hombre" width="22">
                </div>

                <div class="sliders__slider" >
                    {{ daySelect | date: 'LLLL' | titlecase }}
                </div>

                <div (click)="onChangeMonth('rigth')">
                    <img src="assets/imagenes/iconos/icon-paginator-rigth.svg" alt="imagen de un hombre" width="22">
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
