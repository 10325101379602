
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'apps/cambix/src/environments/environment';
import { IAccountResV2, IAccountResV3 } from '../../interfaces/dropdown/dropdown.response.interface';
import { TokenStorageService } from 'apps/cambix/src/utils/services/token/token.storage.service';

@Injectable({
  providedIn: 'root'
})
export class DropdownHttp {

  private apiUrl: string;
  private path: string;
  private pathV2: string;
  private pathV3: string;

  constructor(private http: HttpClient,private tokenService: TokenStorageService) {
    this.apiUrl = `${environment.api_url}`;
    this.path = 'v1/accounts/';
    this.pathV2 = 'v2/accounts';
    this.pathV3 = 'v3/accounts';
  }


  getAccountByUser(currencyCode: string): Observable<IAccountResV2[]> {
    const params = new HttpParams()
      .append('currencyCode', currencyCode);

      const auth = this.tokenService.getToken();
      const  headers= new HttpHeaders({'Authorization':  `Bearer ${auth}`,  'Ocp-Apim-Subscription-Key': `${environment.OcpApimSubscriptionKey}`})
      
    return this.http.get<IAccountResV2[]>(`${this.apiUrl}${this.pathV2}?`, {headers, params});
  }


  getAccountByCciV3(currencyCode: string, cci: string, originAccount: boolean = false, ruc = null): Observable<IAccountResV3> {
    let params = new HttpParams()
      .append('currencyCode', currencyCode)
      .append('cci', cci);

    if (ruc) {
      params = params.set('ruc', ruc);
    }
    if (originAccount) {
      params = params.set('originAccount', originAccount.toString());
    }
    const auth = this.tokenService.getToken();
    const  headers= new HttpHeaders({'Authorization':  `Bearer ${auth}`,  'Ocp-Apim-Subscription-Key': `${environment.OcpApimSubscriptionKey}`})

    return this.http.get<IAccountResV3>(`${this.apiUrl}${this.pathV3}/account`, { params, headers });
  }
}
