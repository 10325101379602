import {
  AfterViewInit,
  Component,
  OnInit,
} from "@angular/core";
import {
  FormGroup,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CookiesPolicyModalComponent } from "apps/cambix/src/app/landing/cookies-policy-modal/cookies-policy-modal.component";
import { CookieService } from "ngx-cookie-service";
import { AppObservable } from "../../observables/app.observable";
import { environment } from "apps/cambix/src/environments/environment";

import { STORAGE_KEY } from 'apps/cambix/src/utils/constants/storage';

@Component({
  selector: "app-home-cookies",
  templateUrl: "./home-cookies.component.html",
  styleUrls: ["./home-cookies.component.scss"]
})
export class HomeCookiesComponent implements OnInit, AfterViewInit {

  formCookies: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<HomeCookiesComponent>,
    private cookieService: CookieService,
    private appObservable: AppObservable,
    private dialog: MatDialog,
  ) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.evaluateCookies();
    }, 4500);
  }

  ngOnInit(): void {
  }

  openCookiesPolicyModal(): void {
    this.dialog.open(CookiesPolicyModalComponent, {
      disableClose: true,
      panelClass: 'dialog-primary',
    });
  }

  evaluateCookies(): void {
    const allowCookies: boolean = this.cookieService.check("accept_cookies");
    const cookies = this.cookieService.getAll();

    if (!allowCookies) {
      localStorage.setItem(STORAGE_KEY.accept_cookies, "no");
    }
    this.appObservable.setTemporalCookies(cookies);

    environment.domains.forEach((domain) => {
      this.cookieService.deleteAll("/", domain, true, "None");
      this.cookieService.deleteAll("/", "." + domain, true, "None");
      this.cookieService.deleteAll();
    });
  }

  configureCookies(): void {
    this.dialogRef.close(true);
  }

  acceptAllCookies(): void {
    localStorage.setItem(STORAGE_KEY.accept_cookies, "yes");
    const cookies = this.appObservable.getTemporalCookies();
    localStorage.setItem(STORAGE_KEY.acceptedCookies, JSON.stringify(cookies));
    for (const key in cookies) {
      if (cookies) {
        this.cookieService.set(key, cookies[key]);
      }
    }
    this.dialogRef.close();
  }
}
