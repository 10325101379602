import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
const getStyles = (...args: string[]) => [...args].filter(Boolean)
export enum EInputValidation {
  Number = 'number',
  Alpha = 'alpha',
  Alphanumeric = 'alphanumeric',
  Text = 'text',
}

@Component({
  selector: 'cbx-auth-dropdown',
  templateUrl: './auth-dropdown.component.html',
  styleUrls: ['./auth-dropdown.component.scss']
})
export class AuthDropdownComponent {
  @ViewChild('cbxDropDownList') cbxDropDownList!: ElementRef;

  @Input() idTextField = 'idTextField';
  @Input() placeholder = ' ';
  @Input() label = 'label';
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() value = '';
  @Input() maxLength = '60';
  @Input() size = 'md';
  @Input() paste = true;
  @Input() msgError = "";
  @Input() msgInfo = "";
  @Input() hasCounter = false;
  @Input() width = '';
  @Input() height = '48px';
  @Input() icon = '';
  @Input() inputValidation!: EInputValidation;
  @Input() typeInput: "ClassicInput"  | "ClassicInputRegister" = "ClassicInput";
  @Input() loading = false;
  @Input() readOnly = false;
  @Input() data: any = [];
  @Input() textSearch: any = "";
  @Input() typeView: "FULL" | "FOUND" | "ERROR" | "EMPTY" | "" = "EMPTY";
  @Output() public valueChanged = new EventEmitter();
  @Output() public blurred = new EventEmitter();
  @Output() public enter = new EventEmitter();
  @Output() public dataValue = new EventEmitter();
  @Output() public restore = new EventEmitter();

  hasError = false;
  maxLimitLength = 60;
  maxInputLength = this.maxLimitLength;
  expression!: RegExp;
  itemSelected = false;
  classInput = {
    'text-input': true,
    'error-input': false
  };
  classInfo = {
    'info-container': true,
    'counter-container': false
  };
  arrow='arrow-down-gris';
  isDropdownOpen: boolean = false;

  public get typeInputs(): string[] {
    return getStyles(this.typeInput)
  }

  constructor(private elementRef: ElementRef) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.msgError != "") {
      this.typeView = "EMPTY"
      this.hasError = true;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }

    if (this.msgError == "") {
      this.hasError = false;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    else {
      this.classInfo['info-container'] = true;
      this.classInfo['counter-container'] = false;
    }
  }

  onChange(target: any) {
    switch (this.inputValidation) {
      case EInputValidation.Number:
        this.expression = /[A-Za-zÑÁÉÍÓÚñáéíóú`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Alpha:
        this.expression = /[0-9`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Alphanumeric:
        this.expression = /[`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Text:
        this.expression = /[0-9`~!¡@#$%^&*()_|+\-=?;:'",.<>°]/g;
        break;
    }

    if (this.expression) {
      target.value = target.value.replace(this.expression, '');
    }

    if(this.cbxDropDownList.nativeElement.value == '') {
      this.itemSelected = false;
      this.restore.emit();
    }

    if(!this.data && this.textSearch){
      this.typeView = 'ERROR';
      this.itemSelected = false;
    }

    target.value = target.value.substr(0, Number(this.maxLength));
    this.valueChanged.emit(target.value);
  }

  UpdateArrow() {
    return this.typeView === 'FULL' ? this.arrow = 'arrow-up' : this.arrow = 'arrow-down-gris';
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.typeView = 'EMPTY';
      this.UpdateArrow();
    }
    if(!this.itemSelected) {
      this.cbxDropDownList.nativeElement.value = '';
      this.restore.emit();
    }
  }

  onEnter(event: any, value: any) {
    this.enter.emit(value || event.target.value);
  }

  onPaste(event: ClipboardEvent) {
    if ((window as any).clipboardData) {
      const pastedText = (window as any).clipboardData;
    } else {
      const pastedText = event?.clipboardData?.getData('text');
    }
    return this.paste;
  }

  toggleDropdown() {
    if(this.disabled) return;
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) this.typeView = 'FULL';
    else this.typeView = 'EMPTY';
    this.UpdateArrow();
  }


  getArrow(){
    return this.typeView==='FULL'? this.arrow='arrow-up':this.arrow='arrow-down-gris';
  }

  getElement(element: any) {
    this.itemSelected = true;
    this.typeView = "EMPTY";
    this.loading = false;
    this.UpdateArrow();
    this.dataValue.emit(element);
  }




}

