import { Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';


const getStyles = (...args: string[]) => ["LineChartDefault", ...args].filter(Boolean)

@Component({
  selector: 'cbx-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})

export class LineChartComponent implements OnInit {

  @Input()
  exchangeRateOptions!: EChartsOption;
  @Input() type = 'LineChartDefault';
  @Input() width = '';
  @Input() height = '400px';


  constructor() { }
  ngOnInit(): void {
  }




}
