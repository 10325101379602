<!-- <button [ngClass]="typeClass" (click)="onAction(action)">   
    <div  [ngClass]="typeSize" class="CustomButton">
        <img [ngSrc]="urlimagen" alt="icono del boton" width="20px" height="20px">
        <p *ngIf="nombreBottonAction" class="textoBotton"> {{ nombreBottonAction }}  </p>  
    </div> 
</button> 
 -->
<button [ngClass]="typeClass" (click)="onAction(action)">   
    <div  [ngClass]="typeSize" >
        <div class="CustomButton">
            <img [src]="urlimagen" alt="icono del boton" width="20px" height="20px">
            <p *ngIf="nombreBottonAction" class="textoBotton"> {{ nombreBottonAction }}  </p>  
        </div> 
    </div> 
</button> 
