<ng-container [ngSwitch]="typeInputs">
    <ng-container *ngSwitchCase="'InputPass'">
      <div class="InputPass">
        <div class="input-container" [style.width]="width">
          <input
            [type]="showPassword ? 'text' : 'password'"
            [id]="idTextField"
            [style.height]="height"
            [placeholder]="placeholder"
            [disabled]="disabled"
            [readonly]="readOnly"
            [value]="value"
            (blur)="onBlur($event)"
            (focus)="onFocus($event)"
            [ngClass]="classInput"
            (input)="onChange($event.target)"
            autocomplete="off" />
          <label [htmlFor]="idTextField"  class="label">{{label}}</label>
          <div class="icon" (click)="onchangeIconPass()">
            <img *ngIf="showPassword" src="assets/imagenes/auth/icon-eye-open.svg">
            <img *ngIf="!showPassword" src="assets/imagenes/auth/icon-eye-close.svg">
          </div>
        </div>

        <div [ngClass]="classInfo" [style.width]="width">
          <div *ngIf="hasError" class="message-error">
            {{msgError}}
          </div>
        </div>

        <div [ngClass]="classInfo" [style.width]="width">
          <div *ngIf="flgVerCaracteristicas"  class="text-left" >
            <div  class="font-secondary-regular-12 pt-2" [ngClass]="{ 'valid-pass' : !recoverPass , 'valid-pass-recover' : recoverPass || paddingtop}">
              <div class="ml-4 valid-pass" >Tu contraseña debe cumplir con lo siguiente</div>
              <div>
                  <div class="list-error-password font-secondary-regular-12">

                    <div [ngClass]="{ 'text-valid-pass' : existelengValidate , 'text-invalid-pass' : !existelengValidate}">
                      <img [src]="!existelengValidate ? 'assets/imagenes/auth/icon-ul.svg' : 'assets/imagenes/auth/icon-check-azul.svg' " alt="icono de check">
                      <p>Mínimo 8 caracteres.</p>
                    </div>

                    <div [ngClass]="{ 'text-valid-pass' : existeMinuscula , 'text-invalid-pass' : !existeMinuscula}">
                      <img [src]="!existeMinuscula ? 'assets/imagenes/auth/icon-ul.svg' : 'assets/imagenes/auth/icon-check-azul.svg' " alt="icono de check">
                      <p> Al menos una minúscula. </p>
                    </div>

                    <div [ngClass]="{ 'text-valid-pass' : existeMayuscula , 'text-invalid-pass' : !existeMayuscula}">
                      <img [src]="!existeMayuscula ? 'assets/imagenes/auth/icon-ul.svg' : 'assets/imagenes/auth/icon-check-azul.svg' " alt="icono de check">
                      <p> Al menos una mayúscula. </p>
                    </div>

                    <div [ngClass]="{ 'text-valid-pass' : existeNumber, 'text-invalid-pass' : !existeNumber}">
                      <img [src]="!existeNumber ? 'assets/imagenes/auth/icon-ul.svg' : 'assets/imagenes/auth/icon-check-azul.svg' " alt="icono de check">
                      <p> Al menos un número. </p>
                    </div>

                    <div [ngClass]="{ 'text-valid-pass' : existeSimbolo , 'text-invalid-pass' : !existeSimbolo}" >
                      <img [src]="!existeSimbolo ? 'assets/imagenes/auth/icon-ul.svg' : 'assets/imagenes/auth/icon-check-azul.svg' " alt="icono de check">
                      <p> Al menos un carácter especial. Ejm: !@#$%ˆ&* </p>
                    </div>

                  </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </ng-container>
    <ng-container *ngSwitchCase="'ClassicInputPass'">
      <div class="ClassicInputPass">
        <div class="input-container" [style.width]="width">
          <input
            [type]="showPassword ? 'text' : 'password'"
            [id]="idTextField"
            [style.height]="height"
            [placeholder]="placeholder"
            [disabled]="disabled"
            [readonly]="readOnly"
            [value]="value"
            (blur)="onBlur($event)"
            [ngClass]="classInput"
            (input)="onChange($event.target)"
            autocomplete="off" />
          <label [htmlFor]="idTextField"  class="label">{{label}}</label>
          <div class="icon" (click)="onchangeIconPass()">
            <img *ngIf="showPassword" src="assets/imagenes/auth/icon-eye-open.svg">
            <img *ngIf="!showPassword" src="assets/imagenes/auth/icon-eye-close.svg">
          </div>
        </div>
        <div [ngClass]="classInfo" [style.width]="width">
          <div *ngIf="hasError" class="message-error">
            {{msgError}}
          </div>
        </div>
      </div>

    </ng-container>
  </ng-container>
