import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, SimpleChanges } from '@angular/core';

const getStyles = (...args: string[]) => [...args].filter(Boolean)

@Component({
  selector: 'cbx-datepicker-filter',
  templateUrl: './datepicker-filter.component.html',
  styleUrls: ['./datepicker-filter.component.scss'],
})
export class DatepickerFilterComponent {
 
  @Input() typeInput:   "Filter"  = "Filter";
  @Input() dateFrom :any = "";  
  @Input() dateUntil :any = "";  
  @Input() typeDate = "";  
  @Output() public sendDate = new EventEmitter();  
  @Output() public changeMonth = new EventEmitter(); 
  
  public get typeInputs(): string[] {
    return getStyles(this.typeInput)
  }
    
  days: string[] =  ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']; 
  currentDate = new Date();
  calendar: Array<Array<any | null>> = [];

  year = this.currentDate.getFullYear(); 
  month = this.currentDate.getMonth(); 
  day = this.currentDate.getDate(); 

  selectedDateUntil: any;
  selecteDateFrom : any;

  disableBtn : boolean = true; 
  DateSelect : any;
  daySelect : any;
  selectedateOver : any;
  
  classDatePicker = {
    'selected': false, 
    'default': false,
    'normal': true,
    'bloqued': false,
    'range': false,
  };
 
  
  constructor( 
    private datePipe: DatePipe
  ) { 
  }

  ngOnInit(){    
    if(this.typeDate === 'dateFrom'){ 
      this.onValidDateFrom(); 
    }

    if(this.typeDate === 'dateUntil'){  
       this.onValidDateUntil();  
     }  
   }
 

  onValidDateFrom(){
    if(this.dateFrom){  
      this.disableBtn = false; 
      this.selecteDateFrom =  this.dateFrom; 
      this.daySelect = this.dateFrom;  
      
      if(this.dateUntil){  
        this.selectedDateUntil =  this.dateUntil;   
      }

      this.year =  this.dateFrom.getFullYear();
      this.month =  this.dateFrom.getMonth();

      this.updateCalendar();
    }else{
      this.updateCalendar(); 
      this.daySelect = this.currentDate;
    }
  }

  onValidDateUntil(){ 
    if(this.dateUntil){ 
      this.disableBtn = false; 
      this.selectedDateUntil =  this.dateUntil;
      this.daySelect = this.dateUntil;

      if(this.dateFrom){ 
        this.selecteDateFrom =  this.dateFrom;   
      }
      this.year =  this.dateUntil.getFullYear();
      this.month =  this.dateUntil.getMonth(); 
      this.updateCalendar(); 


    }else{
      if(this.dateFrom){ 
        this.daySelect = this.dateFrom;
        this.selecteDateFrom =  this.dateFrom;  
        this.year =  this.dateFrom.getFullYear();
        this.month =  this.dateFrom.getMonth(); 
        this.updateCalendar(); 
      }else{
        this.updateCalendar(); 
        this.daySelect = this.currentDate;
      }
    }
  }

  onChangeMonth(value : any){   
    if(value){   
      this.day =  value.getDate();
      this.month =  value.getMonth();
      this.year=  value.getFullYear(); 
      let newDates = this.year + '/' +  (this.month + 1) + '/' + this.day;
      this.daySelect =  new Date(newDates);
      this.updateCalendar();  
    } 
  }
  
 

  onClassDatePicker(date:any): any{ 
 
    let newFecha = new Date(date.year + '/' + (date.month +1) + '/' + date.day);
    let fecha =  this.datePipe.transform(newFecha, 'dd-MM-yyyy');

    let fechaFrom = this.datePipe.transform(this.selecteDateFrom, 'dd-MM-yyyy');
    let fechaUntil = this.datePipe.transform(this.selectedDateUntil, 'dd-MM-yyyy');
    let fechaOver = this.datePipe.transform(this.selectedateOver, 'dd-MM-yyyy');

    if(this.typeDate === 'dateFrom'){ 
      let fechaDefault = this.datePipe.transform(this.currentDate, 'dd-MM-yyyy'); 
      
      if(!this.selecteDateFrom){
        if(fecha === fechaDefault){
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = true;
          this.classDatePicker['normal'] = false;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        }
        if(fecha !==  fechaDefault){
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = true;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        } 

        if( newFecha > this.currentDate) {
          this.classDatePicker['bloqued'] = true; 
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false; 
          this.classDatePicker['range'] = false; 
        }

      }
      if(this.selecteDateFrom){
        if(fecha === fechaFrom){
          this.classDatePicker['selected'] = true;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        }
        if(fecha != fechaFrom){
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = true;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        }
      }
  
    } 

    if(this.typeDate === 'dateUntil'){ 
        
      if(this.selecteDateFrom && !this.DateSelect){
 
        if(fecha === fechaFrom){
          this.classDatePicker['selected'] = true;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        }

        if(fecha != fechaFrom){
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = true;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        }

        if(newFecha < this.dateFrom ){
          this.classDatePicker['bloqued'] = true; 
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false; 
          this.classDatePicker['range'] = false; 
        }

      }

      if(this.dateFrom && this.DateSelect){ 
        if(fecha === fechaFrom || fecha === fechaUntil){
          this.classDatePicker['selected'] = true;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        } 
        if(fecha != fechaFrom && fecha != fechaUntil){
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = true;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        }   
        if(this.dateFrom <  newFecha &&  newFecha < this.selectedDateUntil ){
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false; 
          this.classDatePicker['range'] = true; 
        }
        if(newFecha < this.dateFrom ){
          this.classDatePicker['bloqued'] = true; 
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false; 
          this.classDatePicker['range'] = false; 
        }
      }
  

      if(this.dateFrom && this.dateUntil  && this.DateSelect){

        if(fecha === fechaFrom || newFecha === this.selectedDateUntil){
          this.classDatePicker['selected'] = true;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        } 
        if(fecha != fechaFrom && newFecha != this.selectedDateUntil){
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = true;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        }  

        if(this.dateFrom <  newFecha &&  newFecha < this.selectedDateUntil ){
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false; 
          this.classDatePicker['range'] = true; 
        }
      }

      if(this.dateFrom && this.dateUntil  && this.DateSelect && this.selectedDateUntil){

        if(fecha === fechaFrom || newFecha === this.selectedDateUntil){
          this.classDatePicker['selected'] = true;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        } 
        if(fecha != fechaFrom && newFecha != this.selectedDateUntil){
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = true;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        }  

        if(this.dateFrom <  newFecha &&  newFecha < this.selectedDateUntil ){
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false; 
          this.classDatePicker['range'] = true; 
        }
      }
      if(this.dateFrom && this.dateUntil){
   
        if(newFecha < this.dateFrom ){
          this.classDatePicker['bloqued'] = true; 
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false; 
          this.classDatePicker['range'] = false; 
        }

        if(fecha === fechaFrom || fecha === fechaUntil){
          this.classDatePicker['selected'] = true;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        }
 
        if(fecha != fechaFrom && fecha != fechaUntil){
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = true;
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['range'] = false; 
        }  
  
        if(this.dateFrom <  newFecha &&  newFecha < this.dateUntil ){
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false; 
          this.classDatePicker['range'] = true; 
        } 
      }
 
      if(this.dateFrom && !this.DateSelect && this.selectedateOver){
        if(this.dateFrom <  newFecha &&  newFecha < this.selectedateOver ){ 
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false; 
          this.classDatePicker['range'] = true; 
        }  
      }
       
      if(this.dateFrom && this.dateUntil && this.selectedateOver){
        if(this.dateFrom <  newFecha &&  newFecha < this.selectedateOver ){ 
          this.classDatePicker['bloqued'] = false; 
          this.classDatePicker['selected'] = false;
          this.classDatePicker['default'] = false;
          this.classDatePicker['normal'] = false; 
          this.classDatePicker['range'] = true; 
        }  
      }
    
    } 

   
    return this.classDatePicker;
  }

  updateCalendar() { 
    this.calendar = [];
    const firstDayOfMonth = new Date(this.year, this.month, 1).getDay(); 
    const lastDayOfMonth = new Date(this.year, (this.month +1 ), 0); 
    let week: Array<any | null> = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      let dayMonthYear = {
        year : this.year,
        month : this.month,
        day : null
      }
      week.push(dayMonthYear); // Agrega días en blanco antes del primer día del mes
    }
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      let dayMonthYear = {
        year : this.year,
        month : this.month,
        day : i
      }
      week.push(dayMonthYear); 
      if ((firstDayOfMonth + i - 1) % 7 === 6 || i === lastDayOfMonth.getDate()) {
        this.calendar.push(week);
        week = [];
      }
    } 
  }

  dayOver(date: any) {  
    let dateOver = date.year + '/' +  (date.month + 1) + '/' + date.day; 
    if(!this.dateUntil && this.dateFrom){
      this.selectedateOver = new Date(dateOver);
      this.onClassDatePicker(date); 
    }
  }
 

  selectDate(date: any) {   
    let newDates = date.year + '/' +  (date.month + 1) + '/' + date.day;
    let fecha = new Date(newDates);
    
 
    if(this.typeDate === 'dateUntil'){ 
      if(fecha < this.dateFrom){
        return;
      } 
      const DATE = {
        date : fecha,
        type : this.typeDate,
        flgclick : true
      }
      this.sendDate.emit(DATE); 
      this.selectedDateUntil = fecha;
    }else{  
      if(fecha > this.currentDate){
        return;
      } 
      this.selecteDateFrom = fecha;   
    } 

    this.DateSelect =  fecha; 
    this.disableBtn = false; 
    this.onClassDatePicker(date);
  }
 
  onAceptar(){
    this.DateSelect = this.DateSelect ? this.DateSelect : this.selectedDateUntil;
    const DATE = {
      date : this.DateSelect,
      type : this.typeDate,
      flgclick : false
    }
    this.sendDate.emit(DATE); 
  
  }

  onBorrar(){ 
    const DATE = {
      date : null,
      type : this.typeDate
    }
    this.sendDate.emit(DATE); 
  }

 
}

