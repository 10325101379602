<div [ngClass]="typeClass" >
  <div *ngIf="titulo">
    {{ titulo }}  
  </div>

  <div *ngIf="subtitulo">
    {{ subtitulo }}  
  </div>
    
 
   <div *ngIf="body" class="body">
    {{ body }}
   </div>
     
   <div class="botones">
    <cbx-button *ngIf="firstButton" [nombreBotton]="firstButton" type="BottonPrimary"> </cbx-button>
    <cbx-button *ngIf="secondtButton" [nombreBotton]="secondtButton" type="BottonPrimary"> </cbx-button>  
   </div> 
</div>