export const STORAGE_KEY = {
  decimalsQuantity: 'pYfm4usknkiFnfJOF/rHaA==',
  id_paginate: 'LxdGwujF+UyjjFXiw3hRqw==',
  accept_cookies: 'iX3YUFY6X0+tmmMVFrQLyQ==',
  acceptedCookies: 'pWt+RoMVaUipf7HFeMK4Pw==',
  id_random: 'Dy/5ARU48EGtlU1LtbWNqw==',
  firstLogin: 'QIl6zwYHV02jSMUuDMIEsQ==',
  originBiometric: 'HK3ilSCJ/UGSOXnPovjWcA==',
  authToken: 'U9943RIISkSkIJS80GhUEQ==',
  refreshAuthToken: 'yOsattQwf0OYMwDn8w9aQA==',
  sessionUser: 'HQgvAqcR60ScPnyayTZexA==',
  sessionSimulator: 'L0KHdO/c00mGZ42tpXDK9g==',
  smsResponse: 'zhk6N2UDlkCQSvtIQlO06g==',
  jwtToken: 'qpSiWUUzG0SnPuCSSuVvdg==',
  userCambix: 'joxqNz1/n0qfCn2SnAV/hg==',
  operationList: '2b7f01b13db0=='
}
