import { Component, EventEmitter, Input, Output } from '@angular/core';

const getStyles = (...args: string[]) => [...args].filter(Boolean)

@Component({
  selector: 'cbx-button-tipo-cambio',
  templateUrl: './button-tipo-cambio.component.html',
  styleUrls: ['./button-tipo-cambio.component.scss'],
})
export class ButtonTipoCambioComponent {

  @Input() tipo : string | null = "";
  @Input() valorTipo : number = 0;
  @Input() decimalQuantity : number = 2;
  @Input() isActive : boolean = false;
  @Input() type :   "BottonDefault"  | "BottonTipoCambio" = "BottonDefault";
  @Output() clicked = new EventEmitter();
  public get typeClass(): string[] {
   return getStyles(this.type)
  }


  onClicked() {
      this.clicked.emit();
  }

}
