import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { IConfigBiometric } from '../../biometric/commons/interface/biometric.interface';
import { BiometricSegmentServiceType } from '../../biometric/commons/type/biometric.type';
import { StorageService } from 'apps/cambix/src/utils/services/storage/storage.service';
import { STORAGE_KEY } from 'apps/cambix/src/utils/constants/storage';

@Injectable({
    providedIn: 'root'
})
export class BiometricGlobalService {
    private configBiometric = new BehaviorSubject<IConfigBiometric>({ activated: false, segment: '' });
    public configBiometric$ = this.configBiometric.asObservable();
    private sendBiometric = new Subject<any>();
    public sendBiometric$ = this.sendBiometric.asObservable();
    private dataCaptureFacial = new BehaviorSubject<any>({ dataBiometric: null, segment: '' });
    public dataCaptureFacial$ = this.dataCaptureFacial.asObservable();
    public destroyBiometric = new Subject<void>();
    public successfulBiometrics = new BehaviorSubject<any>(null);
    public successfulBiometrics$ = this.successfulBiometrics.asObservable();

    constructor(
      private storageService: StorageService
    ) { }


    setConfigBiometric(val: IConfigBiometric) {
        this.configBiometric.next(val);
    }
    getConfigBiometricSegment(): BiometricSegmentServiceType {
        return this.configBiometric.getValue().segment;
    }
    setsendBiometric(val: any) {
        this.sendBiometric.next(val);
    }
    setdataCaptureFacial(val: any) {
        this.dataCaptureFacial.next(val);
    }

    getOriginBiometric(): BiometricSegmentServiceType {
        return localStorage.getItem(STORAGE_KEY.originBiometric) as BiometricSegmentServiceType;
    }

    setOriginBiometric(newValue: BiometricSegmentServiceType) {
        return this.storageService.setData(STORAGE_KEY.originBiometric, newValue);
    }

    setSuccessfulBiometrics(val: any) {
        this.successfulBiometrics.next(val);
    }
}
