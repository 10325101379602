import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ButtonService {

  private isDisabledSource$ = new BehaviorSubject<boolean>(true);
  private  _isDisabled: Observable<boolean> = this.isDisabledSource$.asObservable();

  private isLoadingSource$ = new BehaviorSubject<boolean>(false);
  private  _isLoading: Observable<boolean> = this.isLoadingSource$.asObservable();
 
  private isActionActive$ = new BehaviorSubject<string>('');
  private  _isAction: Observable<string> = this.isActionActive$.asObservable();

 
  setDisabled(value: boolean) {
    this.isDisabledSource$.next(value);
  }

   getDisabled(): Observable<boolean> {
    return this._isDisabled;
  }

  setLoading(value: boolean) {
    this.isLoadingSource$.next(value);
  }

   getLoading(): Observable<boolean> {
    return this._isLoading;
  }



  setAction(value: string) {
    this.isActionActive$.next(value);
  }

   getAction(): Observable<string> {
    return this._isAction;
  }
 
}
