import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import {
  UserRes,
  PostRes,
  UserEmployeeReq,
  ReferredsRes,
  VerifyCellphoneRes,
  AuthRes,
  NewUserReq,
  NewUserResV3,
  SmsCodeReq,
  NewUserResV4,
  UserPatchResponse,
} from '../../../models';
import { IProfessionUpdateRequest } from '../../../interfaces/me/profession-update-request';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from 'apps/cambix/src/utils/services/token/token.storage.service';
import { STORAGE_KEY } from 'apps/cambix/src/utils/constants/storage';

@Injectable()
export class UserService {

  private path: string;
  private pathv2: string;
  private pathv3: string;
  private pathv4: string;
  private helper = new JwtHelperService();

  constructor(
    private apiService: ApiService,
    private serviceToken : TokenStorageService,
  ) {
    this.path = 'v1/users/';
    this.pathv2 = 'v2/users/';
    this.pathv3 = 'v3/users/';
    this.pathv4 = 'v4/users/';
  }

  get isAuthenticated(): boolean {
    const datosSession: any = this.serviceToken.getStoreSessionUser();
    const user =  JSON.parse(datosSession);
    const token = this.serviceToken.getToken();
    return token && user;
  }

  getLocalUser(): UserRes {
    const datosSession: any = this.serviceToken.getStoreSessionUser();
    if(!datosSession) {
      return null;
    }
    return JSON.parse(datosSession);
  }

  saveLocalUser(userRes: UserRes) {
    window.localStorage[STORAGE_KEY.userCambix] = JSON.stringify(userRes);
  }

  removeLocalUser(): void {
    window.localStorage.removeItem('_grecaptcha');
    window.localStorage.removeItem(STORAGE_KEY.authToken);
    window.localStorage.removeItem(STORAGE_KEY.userCambix);
    window.localStorage.removeItem(STORAGE_KEY.sessionUser);
    window.localStorage.removeItem(STORAGE_KEY.refreshAuthToken);
    window.localStorage.removeItem('isDoNotShowMessage');
    window.localStorage.removeItem('generalSchedule');
    window.localStorage.removeItem('clientSchedule');
    window.localStorage.removeItem('exchangeRate');
    window.sessionStorage.removeItem('cellphone-new');
    window.sessionStorage.removeItem(STORAGE_KEY.smsResponse);
  }

  getDataUser(idUser: number): Observable<UserRes> {
    return this.apiService.get(this.path + 'user/' + idUser.toString());
  }

  getUserMeValid(cellphone: string): Observable<AuthRes> {
    return this.apiService.get(this.pathv2 + 'me-valid?cellphone=' + cellphone);
  }

  updateUser(newUserReq: UserRes): Observable<any> {
    return this.apiService.put(this.path + 'user/', newUserReq);
  }
  updateLastUse(): Observable<any> {
    return this.apiService.patch(this.path + 'user/last-use');
  }

  getIsEmployee(idUser: number): Observable<PostRes> {
    const params = {
      idUser
    };
    return this.apiService.get(this.path + 'user-employee?' + 'idUser=' + idUser);
  }

  saveBancomUserTuc(tuc: number, idUser: number): Observable<PostRes> {
    const employeeReq: UserEmployeeReq = {
      tuc,
      idUser
    };
    return this.apiService.post(this.path + 'user-employee', employeeReq);
  }


  getVerifyReferredCode(referredCode: string): Observable<ReferredsRes> {
    return this.apiService.get(this.pathv2 + 'verify-referred-code?' + 'referredCode=' + referredCode);
  }

  getVerifyCellphone(cellphone: string): Observable<VerifyCellphoneRes> {
    return this.apiService.get(this.pathv2 + 'verify-cellphone?' + 'cellphone=' + cellphone);
  }

  getVerifyCellphoneV3(cellphone: string): Observable<VerifyCellphoneRes> {
    return this.apiService.get(this.pathv3 + 'verify-cellphone?' + 'cellphone=' + cellphone);
  }

  createUser(newUserReq: NewUserReq, tokenReCaptcha?: string): Observable<NewUserResV3> {
    return this.apiService.post(this.pathv3 + 'user', newUserReq);
  }

  createUserV4(newUserReq: NewUserReq, tokenReCaptcha?: string): Observable<NewUserResV4> {
    return this.apiService.post(this.pathv4 + 'user', newUserReq);
  }

  updateUserv2(referredTerms: number): Observable<PostRes> {

    const params = {
      referredTerms: String(referredTerms)
    };


    return this.apiService.patch(this.pathv2 + 'update-user/', params );
  }

  updateUserProfession(codeProfession: string, nameProfession: string, cellphone: string): Observable<PostRes> {

    const params = {
      codeProfession: codeProfession,
      nameProfession: nameProfession,
      cellphone: cellphone
    };

    return this.apiService.patch(this.pathv2 + 'update-user-profession', params );
  }

  updateCellphone(cellphone: string): Observable<UserPatchResponse> {
    const smsCodeReq: SmsCodeReq = {
      cellphone
    };
    return this.apiService.patch(this.pathv3 + 'user/me', smsCodeReq);
  }

  updateProfessionV3(request: IProfessionUpdateRequest): Observable<UserPatchResponse> {
    return this.apiService.patch(this.pathv3 + 'user/me', request);
  }

}
