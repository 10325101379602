<ng-container [ngSwitch]="typeInputs">
    <ng-container *ngSwitchCase="'Classic'">
      <div class="Classic font-secondary-regular-14" >
        <div class="div-meses" >

            <cbx-calendar-dropdown
                typeInput="Classic"
                #idDropMonth
                label="Mes"
                [disabled]="false"
                [placeholder]="'Mes'"
                idTextField="idMes"
                [data]="arrayMeses"
                (dataValue)="onSelectMonth($event)"
                [value]="dateMonthSelect"
            ></cbx-calendar-dropdown>

            <cbx-calendar-dropdown
                typeInput="Classic"
                #idDropYear
                label="Año"
                [disabled]="false"
                [placeholder]="'Año'"
                idTextField="idAneo"
                [data]="years"
                (dataValue)="onSelectYear($event)"
                [value]="dateYearSelect"
            ></cbx-calendar-dropdown>

        </div>

        <span class="errorFecha" *ngIf="errorFecha"> Selecciona mes y año porfavor </span>

        <div class="div-calendario">
            <div class="form-group">
                <div class="datepicker">
                  <div class="calendar" >
                    <div class="calendar-header">
                    </div>
                    <table class="calendar-table">
                      <thead>
                        <tr>
                          <th *ngFor="let day of days">{{ day }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let week of calendar">

                          <ng-container *ngFor="let day of week">
                            <td *ngIf="day !== null" (click)="selectDate(day)"
                              [ngClass]="{
                                'selected': selectedDay === day,
                                'normal': selectedDay != day
                              }" >
                              {{ day }}
                            </td>
                            <td *ngIf="day === null" class="empty-cell"></td>
                          </ng-container>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
        </div>

      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'ClassicArray'">
      <div class="ClassicArray font-secondary-regular-14" >
        <div class="div-meses" >

            <cbx-calendar-dropdown
                typeInput="Classic"
                #idDropMonth
                label="Mes"
                [disabled]="false"
                [placeholder]="'Mes'"
                idTextField="idMes"
                [data]="arrayMeses"
                (dataValue)="onSelectMonth($event)"
                [value]="dateMonthSelect"
            ></cbx-calendar-dropdown>

            <cbx-calendar-dropdown
                typeInput="Classic"
                #idDropYear
                label="Año"
                [disabled]="false"
                [placeholder]="'Año'"
                idTextField="idAneo"
                [data]="years"
                (dataValue)="onSelectYear($event)"
                [value]="dateYearSelect"
            ></cbx-calendar-dropdown>

        </div>

        <span class="errorFecha" *ngIf="errorFecha"> Selecciona mes y año porfavor </span>

        <div class="div-calendario">
            <div class="form-group">
                <div class="datepicker">
                  <div class="calendar" >
                    <div class="calendar-header">
                    </div>
                    <table class="calendar-table">
                      <thead>
                        <tr>
                          <th *ngFor="let day of days">{{ day }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let week of calendar">

                          <ng-container *ngFor="let day of week">
                            <td *ngIf="day !== null" (click)="selectDate(day)"
                              [ngClass]="{
                                'selected': selectedDay === day,
                                'normal': selectedDay != day
                              }" >
                              {{ day }}
                            </td>
                            <td *ngIf="day === null" class="empty-cell"></td>
                          </ng-container>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
        </div>

      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'Others'">
      <div class="Others" >
        <div class="div-meses" >

            <cbx-calendar-dropdown
                typeInput="Others"
                #idDropMonth
                label="Mes"
                [disabled]="false"
                [placeholder]="'Mes'"
                idTextField="idMes"
                [data]="arrayMeses"
                (dataValue)="onSelectMonth($event)"
                [value]="dateMonthSelect"
            ></cbx-calendar-dropdown>

            <cbx-calendar-dropdown
                typeInput="Others"
                #idDropYear
                label="Año"
                [disabled]="false"
                [placeholder]="'Año'"
                idTextField="idAneo"
                [data]="years"
                (dataValue)="onSelectYear($event)"
                [value]="dateYearSelect"
            ></cbx-calendar-dropdown>

        </div>

        <span class="error-fecha" *ngIf="errorFecha"> Selecciona mes y año porfavor </span>

        <div class="div-calendario">
            <div class="form-group">
                <div class="datepicker">
                  <div class="calendar" >
                    <div class="calendar-header">
                    </div>
                    <table class="calendar-table">
                      <thead>
                        <tr>
                          <th *ngFor="let day of days">{{ day }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let week of calendar">

                          <ng-container *ngFor="let day of week">
                            <td *ngIf="day !== null" (click)="selectDate(day)"
                              [ngClass]="{
                                'selected': selectedDay === day,
                                'normal': selectedDay != day
                              }" >
                              {{ day }}
                            </td>
                            <td *ngIf="day === null" class="empty-cell"></td>
                          </ng-container>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
        </div>

      </div>
    </ng-container>
</ng-container>
