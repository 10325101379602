import { Component } from '@angular/core';

@Component({
  selector: 'cbx-authorizations-additional-pusposes',
  templateUrl: './authorizations-additional-pusposes.component.html',
  styleUrls: ['./authorizations-additional-pusposes.component.scss']
})
export class AuthorizationsAdditionalPusposesComponent {

}
