import { Component, Inject, OnInit, OnDestroy, SecurityContext} from '@angular/core';
import { ETYPECLOSED } from 'libs/cambix/ui/src/constants/dialog/dialog.const';
import { DialogRef } from 'libs/cambix/ui/src/directives/dialog-cdk-ref';
import { DIALOG_DATA } from 'libs/cambix/ui/src/directives/dialog-cdk-tokens';
import { ModalConfirmService } from '../service/dialog-confirm.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { SidenavService } from 'libs/cambix/ui/src/service/sidenav.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'cbx-dialog-sing-off',
  templateUrl: './dialog-sing-off.component.html',
  styleUrls: ['./dialog-sing-off.component.scss'],
})
export class DialogSingOffComponent implements OnInit, OnDestroy{

  loadingButtonConfirm = false;
  destroy = new Subject<void>();
  bodyHtml : any ="";
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: any,
    private serviceModal: ModalConfirmService,
    private serviceSidenav: SidenavService,
    private sanitizer: DomSanitizer
  ) {

  }
  ngOnDestroy(): void {
    this.destroy.next();
  }

  ngOnInit(): void {
    this.bodyHtml =  this.sanitizer.sanitize(SecurityContext.HTML, this.data.body);
    this.serviceModal.loadingButtonConfirm
      .pipe(
        tap(
          val => this.loadingButtonConfirm = val
        ),
        takeUntil(this.destroy)
      ).subscribe();
  }

  onAceptar() {
    this.serviceModal.setLoadingButtonConfirm(true);
    this.dialogRef.close(null, ETYPECLOSED.BEFORE_CLOSED);

  }
  onCancel() {
    this.dialogRef.close();
    this.serviceSidenav.setisActiveMenuUser(false);
  }
}
