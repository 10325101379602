import { Component, Input } from '@angular/core';

const getStyles = (...args: string[]) => ["titulo", ...args].filter(Boolean)

@Component({
  selector: 'cbx-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})

export class CardsComponent {
  @Input() titulo : string | null = " ";
  @Input() subtitulo : string | null = " ";
  @Input() body : string | null = " ";
  @Input() firstButton : string | null = " ";
  @Input() secondtButton : string | null = " ";
  @Input() type : 
   "CardDefault" | "CardVacio" | "CardModal" = "CardDefault";

  
  public get typeClass(): string[] {
   return getStyles(this.type)
  }
}
