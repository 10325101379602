import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from "rxjs";
import { IMenuList } from '../interfaces/menu.interface';
import { GlobalService } from './global_service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  sesionUsuario : any;


  constructor(
    private service: GlobalService,
    private router: Router,
  ) {
  }

  getMenuService = this.service.getDatosMenu();
  getMenuBusinessService = this.service.getDatosMenuBusiness();

  private isMenuSource$ = new BehaviorSubject<IMenuList[]>(this.getMenuService)
  private _isMenu: Observable<IMenuList[]> = this.isMenuSource$.asObservable();


  private isMenuBusinessSource$ = new BehaviorSubject<IMenuList[]>(this.getMenuBusinessService)
  private _isMenuBusiness: Observable<IMenuList[]> = this.isMenuBusinessSource$.asObservable();

  getMenuSource(): Observable<IMenuList[]> {
    return this._isMenu;
  }

  getMenuBusinessSource(): Observable<IMenuList[]> {
    return this._isMenuBusiness;
  }

  setUpdateMenu(codeMenu: number, router: string = "") {
    let navigate = router === "" ? this.router.url : router;
      this._isMenu
        .pipe(
          map((menu) => { this.validateMenu(menu, codeMenu) }),
          tap(() => {
            if (router !== "")
            this.router.navigateByUrl(navigate)
          })
        ).subscribe();
  }


  setUpdateMenuBusiness(codeMenu: number, router: string = "") {
    let navigate = router === "" ? this.router.url : router;
      this._isMenuBusiness
        .pipe(
          map((menu) => { this.validateMenu(menu, codeMenu) }),
          tap(() => {
            if (router !== "")
            this.router.navigateByUrl(navigate);
          })
        ).subscribe();
  }

  validateMenu(menu: any, codeMenu: number) {
    return menu.map((m: any) => { m.active = codeMenu === m.code })
  }

}
