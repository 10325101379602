import { Directive, OnDestroy } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { TokenStorageService } from 'apps/cambix/src/utils/services/token/token.storage.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Directive({
  selector: '[emailValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailValidationDirective,
      multi: true
    }
  ]
})

export class EmailValidationDirective implements Validator, OnDestroy {
  private subscription!: Subscription;
  constructor(
    private tokenService: TokenStorageService
  ) {}
  
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
  validate(control: AbstractControl): { [key: string]: any } | null {
    if (!this.subscription) {
      this.subscription = control.valueChanges
        .pipe(
          tap((resp: any) => {
            if (resp) { 
              let contentArroba = resp.includes('@'); 
              const [DATA_EMAIL, DATA_EMAIL_BEFORE_ARROBA] = resp.split('@');
              const validate = this.tokenService.onValidateFormEmail(DATA_EMAIL_BEFORE_ARROBA);

              if(!contentArroba){
                control.setErrors({ customErrorEmail: 'Correo electrónico inválido' });
              }else{ 
                if (validate) {
                  control.setErrors({ customErrorEmail: 'Correo electrónico inválido' });
                } else {
                  control.setErrors(null);
                }
              } 
            }
          })
        )
        .subscribe();
    }
    return null;
  }
}