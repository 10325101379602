import { Component,Input } from '@angular/core';
import { CbxLayoutService } from '../../service/layout/responsive.service';

const getStyles = (...args: string[]) => ["data", ...args].filter(Boolean)

@Component({
  selector: 'cbx-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steppers.component.scss'],
})
export class SteppersComponent {

  @Input() data: any[] = [];
  @Input() position = 'vertical';
  @Input() type:'StepperDefault'  | 'StepperPrimary' = 'StepperDefault';
  @Input() montoLimite : boolean = false;

  public get typeClass(): string[] {
    return getStyles(this.type)
   }

   constructor(
    private cbxLayout: CbxLayoutService,
   ){

    this.cbxLayout.currentScreenSize$.subscribe((currentSize) => {
      this.position = currentSize === 'xs' || currentSize === 'sm' ? 'horizontal' : 'vertical';
    });

   }

}
