import { Directive, ElementRef, HostListener, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[keyValidation]'
})
export class InputKeyValidation { 
  @Input() keyValidation: string = "";
  @Input() decimals: string = '2';
  @Input() integers: string = '+';

  cases;
  private regex: RegExp = new RegExp(/^\d+([.]\d{1,2})?$/);
 
  constructor(private el: ElementRef) {
    this.cases = {
      number_decimal: 'decimal',
      number_integer: 'integer',
      text: 'text-no-especial-char'
    }; 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['decimals'] && this.decimals && changes['integers'] && this.integers) { 
      const decimalPattern = "^\\d{1,"+ this.integers +"}([.]\\d{1,"+ this.decimals +"})?$";
      this.regex = new RegExp(decimalPattern);  
    }
  }

  
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const e = event as KeyboardEvent;  
    if (this.cases.number_decimal === this.keyValidation) { 
      if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 || 
        (e.keyCode === 65 && e.ctrlKey === true) ||
        (e.keyCode === 86 && e.ctrlKey === true) || 
        (e.keyCode === 67 && e.ctrlKey === true) || 
        (e.keyCode === 88 && e.ctrlKey === true) || 
        (e.keyCode >= 35 && e.keyCode <= 39) ||
        (e.keyCode === 190) || (e.keyCode === 110)
      ) { 
        return;
      }



      const current: string = this.el.nativeElement.value;
      const position = this.el.nativeElement.selectionStart;
      const next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');

      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
    }

    if (this.cases.number_integer === this.keyValidation) {
      if (['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'NumLock', 'ArrowLeft', 'ArrowRight', 'End', 'Home', '.'].indexOf(e.key) !== -1 || 
        (e.key === 'a' && (e.ctrlKey || e.metaKey)) || 
        (e.key === 'c' && (e.ctrlKey || e.metaKey)) || 
        (e.key === 'v' && (e.ctrlKey || e.metaKey)) || 
        (e.key === 'x' && (e.ctrlKey || e.metaKey))) { 
        return;
      }

      if ((e.shiftKey || ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(e.key) === -1)) {
        e.preventDefault();
      }
    }

    if (this.cases.text === this.keyValidation) {

      if (['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'NumLock', 'ArrowLeft', 'ArrowRight', 'End', 'Home', '.'].indexOf(e.key) !== -1 ||
        // Allow: Ctrl+A
        (e.key === 'a' && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.key === 'c' && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.key === 'v' && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.key === 'x' && (e.ctrlKey || e.metaKey))) {
        // let it happen, don't do anything
        return;
      }

      if ((['á', 'é', 'í', 'ó', 'ú', 'ü', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'ñ', 'o', 'p', 'q', 'r', 't', 's', 'u', 'v', 'w', 'x',
        'y', 'z', 'Á', 'É', 'Í', 'Ó', 'Ú', 'Ü', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'T', 'S', 'U', 'V', 'W',
        'X', 'Y', 'Z', ' ', '\'', '-'].indexOf(e.key) === -1)) {
        e.preventDefault();
      } 
    } 
 
  }
}
