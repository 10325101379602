import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class MigrationGlobalService {

    private dataSmsCode = new BehaviorSubject<any>(null);
    public dataSmsCode$ = this.dataSmsCode.asObservable();
 
    private setUserMigration = new BehaviorSubject<any>(null);
    public getUserMigration$ = this.setUserMigration.asObservable();
    
    private setNameUser= new BehaviorSubject<any>(null);
    public getNameUser$ = this.setNameUser.asObservable();

    constructor() { }
 
    setDataSmsCode(val: any) {
        this.dataSmsCode.next(val);
    }

    setDataUserMigration(val: any) {
        this.setUserMigration.next(val);
    }
    setDataNameUser(val: any) {
        this.setNameUser.next(val);
    }

}