  <div class="full-width">
    <div class="title">POLÍTICA DE COOKIES</div>
    <p class="paragraph paragraph__first"> De conformidad con lo establecido en la Ley N° 29733, Ley de Protección de Datos Personales, su Reglamento, aprobado por Decreto Supremo N° 003-2013-JUS y sus modificatorias, la presente política tiene por objetivo informar las condiciones de uso de cookies en los sitios web del Banco de Comercio.</p>
    
    <div class="subtitle">Definición de cookies</div>
    <p class="paragraph">Las cookies son pequeños archivos de texto que las páginas web pueden utilizar para hacer más eficiente la experiencia del usuario. Estos datos proveen información sobre cómo y cuándo usted visita un sitio. Se almacena en su dispositivo en la forma de un archivo de texto pequeño. Las "cookies" no recopilan información como su nombre, dirección, número telefónico o dirección de correo electrónico.</p>
    
    <div class="subtitle">Responsable del tratamiento</div>
    <p class="paragraph">Banco de Comercio S.A., con RUC N° 20509507199 y domicilio en Av. Canaval y Moreyra 452 – 454, San Isidro, Lima, Perú.</p>
    
    <div class="subtitle">Finalidades de las cookies</div>
    <p class="paragraph">Los sitios web del Banco de Comercio utilizan cookies, por ejemplo, para recopilar información sobre su actividad en nuestros sitios web que nos permita mejorar su experiencia como cliente.</p>
    
    <div class="subtitle">Consecuencias de proporcionar los datos o su negativa a hacerlo</div>
    <p class="paragraph">De no proporcionar los datos obligatorios no se le podrá brindar el servicio o producto.</p>
    <p class="paragraph">Entre los diferentes tipos de cookies y su finalidad tenemos:</p>
  
    <table  class="table-modal">
      <tr>
        <th>Cookies</th>
        <th>Finalidades</th>
      </tr>
      <tr>
        <td>Necesario</td>
        <td>Las cookies necesarias ayudan a hacer una página web utilizable activando funciones básicas como la navegación en la página y el acceso a áreas seguras de la página web. La página web no puede funcionar adecuadamente sin estas cookies.</td>
      </tr>
      <tr>
        <td>Estadística </td>
        <td>Las cookies estadísticas ayudan a los propietarios de páginas web a comprender cómo interactúan los visitantes con las páginas web reuniendo y proporcionando información de forma anónima, nos ayuda a mejorar la experiencia del usuario en la plataforma.</td>
      </tr>
    </table>
    <!--Falta definir el marco de la tabla-->
  
    <p class="paragraph">El Banco solicitará su consentimiento para la recopilación de cookies estadísticas y marketing. En caso no lo autorice, recuerde que podrá seguir navegando en el sitio web.</p>
  
    <div class="subtitle">Banco de Datos</div>
    <p class="paragraph">La data de navegación recopilada a través de las cookiesserá almacenada en los Bancos de Datos mediante su inscripción en el Registro Nacional de Protección de Datos Personales, con las denominaciones ‘‘Clientes Persona Natural” RNPDP-PJP N° 5464 y “Prospecto de Clientes” RNPDP-PJP N°10391.</p>
  
    <div class="subtitle">Tiempo</div>
    <p class="paragraph">Las cookies serán utilizadas hasta su fecha de caducidad o hasta que usted opte por deshabilitarlas, lo cual podrá hacer en cualquier momento configurando las opciones de privacidad y seguridad de su navegador.</p>
  
    <div class="subtitle">Transferencia y Destinatarios</div>
    <p class="paragraph">“Los Datos” no se transmitirán a terceros, salvo obligación legal.</p>
  
    <div class="subtitle">Ejercicio de los derechos ARCO</div>
    <p class="paragraph">Como titular se sus datos personales, usted podrá ejercer su derecho de acceso, rectificación, cancelación y/o oposición al tratamiento de sus datos personales. Asimismo, usted puede modificar o revocar su consentimiento en cualquier momento configurando su navegador.</p>
    <p class="paragraph">Los cambios y actualizaciones la presente política se harán de tu conocimiento en cualquiera de nuestras agencias u oficinas; en nuestro portal 
      <a class="link" href="http://www.bancomercio.com/" target="_blank">www.bancomercio.com</a>.</p>
  
    <div class="subtitle">Contactar al Banco</div>
    <p class="paragraph">Si considera que sus derechos de protección de datos personales han sido vulnerados por alguna conducta de nuestros trabajadores, de nuestras actuaciones o respuestas, puede contactar a nuestra Área de Protección de Datos Personales, email: <a class="link" href="mailto:derechos_pdp@bancomercio.com.pe" target="_blank" rel="external" title="Envíanos un correo">derechos_pdp@bancomercio.com.pe</a> o a los teléfonos 0-800-00-2222 o al 513-6001 al interior del país. Sin perjuicio de ello, puede presentar una reclamación ante la Autoridad Nacional de Protección de Datos Personales, dirigiéndose a la Mesa de Partes del Ministerio de Justicia y Derechos Humanos: Calle Scipion Llona 350, Miraflores, Lima, Perú.</p>
  
  </div>