
<ng-container [ngSwitch]="typeClass">
    <ng-container *ngSwitchCase="'BottonTipoCambio'">
        <button [ngClass]="{'BottonTipoCambio': true, 'active' : isActive, 'inactive' : !isActive}" [disabled]="false"  (click)="onClicked()">
            <div [ngClass]="{'font-primary-regular': !isActive, 'font-primary-bold' : isActive}"> {{ tipo }}  </div>
            <div [ngClass]="{'font-primary-regular': !isActive, 'font-primary-bold' : isActive}"> S/ {{ valorTipo | decimalFormat:decimalQuantity }}  </div>
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="'BottonDefault'">
        <button class="BottonDefault" [disabled]="false"  (click)="onClicked()">
            <div class="font-primary-regular"> {{ tipo }}  </div>
            <div class="font-primary-regular"> S/ {{ valorTipo  | decimalFormat:decimalQuantity }}  </div>
        </button>
    </ng-container>
</ng-container>
