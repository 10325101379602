import { Component, Input} from '@angular/core';
const getStyles = (...args: string[]) => ["titulo", ...args].filter(Boolean)
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { CbxLayoutService } from '../../service/layout/responsive.service';
import { DialogService } from '../../service/dialog.service';

@Component({
  selector: 'cbx-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() type : "DialogInformativo" | "DialogAction" = "DialogInformativo";

 titulo : string = "";
 body : string = " ";
 sizeButton : string = "";
 imagenModal : string = "";
 firstButton : string = "";
 secondtButton : string = "";
 size  : string = "";
 closeAuto! : boolean;

 public get typeClass(): string[] {
  return getStyles(this.type)
 }

  isShowDialog : boolean = false;

  constructor(
    private serviceDialog : DialogService
  ){
    this.serviceDialog.getData()
     .pipe(tap(resp => this.onBuildModal(resp)))
      .subscribe();
  }

  onBuildModal(data : any){
    if(data){
      this.isShowDialog = true;
      this.titulo = data.titulo;
      this.body = data.body;
      this.sizeButton = data.sizeButton;
      this.imagenModal = data.imagenModal;
      this.firstButton = data.firstButton;
      this.secondtButton = data.secondtButton;
      this.closeAuto = data.closeAuto
    }
  }



  onCloseDialog(){
    if(this.closeAuto){
      this.isShowDialog = false;
    }
  }

  onOk(){
    this.isShowDialog = false;
  }
}
