import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenReq } from '../../../models';
import { STORAGE_KEY } from 'apps/cambix/src/utils/constants/storage';

@Injectable()
export class JwtService {

  path: string;
  constructor(
    private http: HttpClient,
    private cookieService: CookieService

  ) {

    this.path = 'v1/auth-jwt';
  }


  getTokenRemote(tokenReq: TokenReq): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(
      `${environment.api_url}${this.path}`,
      JSON.stringify(tokenReq)
    ).pipe(catchError(this.formatErrors));

  }


  getLocalToken(): string {
    return window.localStorage[STORAGE_KEY.jwtToken];
  }

  saveLocalToken(token: string) {
    window.localStorage[STORAGE_KEY.jwtToken] = token;
  }

  removeLocalToken() {
    localStorage.removeItem(STORAGE_KEY.jwtToken);
  }



  saveCookieToken(token: string) {
    this.cookieService.set(STORAGE_KEY.jwtToken, token);
  }

  removeCookieToken() {
    this.cookieService.delete(STORAGE_KEY.jwtToken);
  }

  getCookieToken(): string {
    return this.cookieService.get(STORAGE_KEY.jwtToken);
  }


  private formatErrors(error: any) {
    return throwError(error.error);
  }

}
