<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'BtnBack'">
      <button  [ngClass]="typeClass"  (click)="onClicked()" >  
          <div [ngClass]="typeSize">
              <p class="font-secondary-regular-16 text">{{ nombreBotton }}</p>  
          </div>   
      </button> 
    </ng-container>
    <ng-container *ngSwitchCase="'BtnTypeRegister'" >
        <button  [ngClass]="typeClass"  (click)="onClicked()"  >  
            <div [ngClass]="{ 'text' : !isActive, 'text-disabled' : isActive}" >
                <p>{{ nombreBotton }}</p>  
            </div>   
        </button> 
      </ng-container>
    <ng-container *ngSwitchCase="'Classic'">
        <button  [ngClass]="typeClass"   [disabled]="isDisabled"  (click)="onClicked()" >  
            <div [ngClass]="typeSize">
                <p class="font-secondary-bold-14 text">{{ nombreBotton }}</p>  
            </div>   
        </button> 
      </ng-container>
      <ng-container *ngSwitchCase="'ClassicCompany'">
        <button  [ngClass]="typeClass"   [disabled]="isDisabled"  (click)="onClicked()" >  
            <div [ngClass]="typeSize">
                <p class="font-secondary-bold-14 text">{{ nombreBotton }}</p>  
            </div>   
        </button> 
      </ng-container>
    <ng-container *ngSwitchCase="'BottonGoogle'">
      <button  [ngClass]="typeClass"  (click)="onClicked()"  >  
          <div [ngClass]="typeSize">
              <div class="custom-button">
                  <img [src]="urlimagen" alt="icono del boton" width="20px" height="20px">
                  <p  class="font-secondary-regular-14 text"> {{ nombreBotton }}  </p>  
              </div>
          </div>   
      </button> 
    </ng-container>
    <ng-container *ngSwitchDefault>
      <button
          *ngIf="!sizeResponsive"
          [ngClass]="typeClass"
          [disabled]="isDisabled" 
          (click)="onClicked()">  
          <div class="show-loading" [ngClass]="typeSize">
              <p class="font-secondary-bold-16" [ngClass]="{ 'texto-botton' : !loadingdButton,'texto-botton-oculto' : loadingdButton }">
                  {{ nombreBotton }} 
               </p>  
               <span *ngIf="loadingdButton" class="spinner spinner--quarter"></span> 
          </div>   
      </button> 
      <button
        *ngIf="sizeResponsive" 
        [ngClass]="typeClass" 
        [disabled]="isDisabled"  
        (click)="onClicked()">  
        <div class="show-loading" [ngClass]="typeSize">
            <p class="font-secondary-bold-16" [ngClass]="{ 'texto-botton-stactic' : !loadingdButton,'texto-botton-oculto-static' : loadingdButton}">
                {{ nombreBotton }} 
             </p>  
             <span *ngIf="loadingdButton" [ngClass]="{ 'spinner' : nombreBotton != 'Si' ,'spinner2' : nombreBotton === 'Si' }" class="spinner--quarter"></span> 
        </div>   
    </button> 
    </ng-container>
   
  </ng-container>
  