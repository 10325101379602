import { Component, Input } from '@angular/core';
import { SidenavService } from '../../service/sidenav.service';
import { Router } from '@angular/router';
import { TokenStorageService } from 'apps/cambix/src/utils/services/token/token.storage.service';
import { GlobalService } from '../../service/global_service';
import { Subject, takeUntil } from 'rxjs';
import { PreloaderService } from '../../service/preloader.service';
import { AppObservable } from 'apps/cambix/src/utils/observables/app.observable';
import { IProfileUser } from 'apps/cambix/src/app/cambix/dashboard/profile-user/interface/profile.interface';

const getStyles = (...args: string[]) => ["itemsArray", ...args].filter(Boolean)

@Component({
  selector: 'cbx-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @Input() imagenTool : string = "";
  @Input() nameUsuario : string | null = "";
  @Input() profile : string | null = "";
  @Input() itemsArray: any[] = [];
  @Input() type : "ToolBarDefault" | "ToolBarDashboard" = "ToolBarDefault";
  sesionUsuario! : IProfileUser;
  showSidenav : boolean = false;

  private destroyed = new Subject<void>();

  public get typeClass(): string[] {
   return getStyles(this.type)
  }

  constructor(
    private serviceSidenav : SidenavService,
    private router: Router,
    private globalService : GlobalService,
    private tokenStorageService: TokenStorageService,
    private servicePreloader: PreloaderService,
    private appObservable: AppObservable
  ){

    this.appObservable.changeProfile$
    .pipe(takeUntil(this.destroyed))
    .subscribe((resp : IProfileUser) => {
      if(resp){
        this.sesionUsuario = resp;
        this.onChangeTools(resp.userType);
      }
    });

  }

  ngOnInit(){
    const datosSession : any = this.tokenStorageService.getStoreSessionUser()
    this.sesionUsuario = JSON.parse(datosSession);
    this.onChangeTools(this.sesionUsuario.userType);
  }

  onShowSidenavMenuUser(event : Event, type: 'SidenavPrimary' | 'SidenavSecondary' ){
    if(event){
      this.showSidenav = !this.showSidenav
      this.serviceSidenav.setisActiveMenuUser(this.showSidenav, type);
    }
  }

  onChangeTools(profile : string){
    this.globalService.getToolBar(profile)
    .pipe(takeUntil(this.destroyed))
    .subscribe((menuOptions) => this.itemsArray = menuOptions);

    if(profile === 'PER'){
      const [firstname] = this.sesionUsuario.names.split(" ");
      this.nameUsuario = firstname;
    }else{
      this.nameUsuario = this.sesionUsuario.documentNumber;
    }
  }

  onNavigate(item: any){
    if(item.name === "Ayuda"){
      this.router.navigateByUrl(item.routerlink);
    }else if(item.name === "Blog"){
      this.router.navigateByUrl('/blog');
    }else if(item.name === 'Empresa' || item.name === 'Persona'){
      //no tiene token para el siwtch
      if(!this.sesionUsuario.token ){
        this.appObservable.setRegisterBusiness(true);
        this.router.navigateByUrl('cambix-business/auth/registro');
        return;
      }

      this.onToggleProfile();
    }else{
      if(this.sesionUsuario.userType === 'PER'){
        this.router.navigateByUrl('/');
      }else{
        this.router.navigateByUrl('/business/home');
      }
    }
  }

  ngOnDestroy(){
    this.destroyed.next();
    this.destroyed.complete();
  }

  onToggleProfile() {
    this.servicePreloader.setpreloader(true);
    this.tokenStorageService.toggleUserProfile()
    .subscribe({
      next: (userData: IProfileUser) => {
        this.sesionUsuario = userData;
        this.onChangeTools(this.sesionUsuario.userType);

        this.servicePreloader.setpreloader(true);

        if(userData.userType === 'PER'){
          this.router.navigateByUrl('cambix/dashboard');
          if(userData.hasCompany === false) this.appObservable.setRegisterBusiness(true);
        }else{
          this.router.navigateByUrl('cambix-business/dashboard')
        }
      },
      error: (e) => this.servicePreloader.setpreloader(false)
    })
  }

}
