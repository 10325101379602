
<div [ngClass]="typeClass" >
    <ng-container *ngIf="arrayBody.length; else noData">
      <table class="tabla" >
        <thead class="cabecera">
          <tr  style="width:50px;" *ngIf="isDashboard">
            <th class="font-secondary-bold-14" > &nbsp; </th>
          </tr>
          <tr class="itemsdynamic" *ngFor="let cab of arrayHeader">
            <th class="font-secondary-bold-14 color-black-300" >{{ cab.header }}</th>
          </tr>
          <tr class="centrar" *ngIf="isAction">
            <th > {{textAction}} </th>
          </tr>
        </thead>

        <div class="cbx-content-line">
          <div class="cbx-line"></div>
        </div>
        <tbody class="cuerpo" >
          <div class="cuerpo-items">
            <tr *ngFor="let item of arrayBody" (click)="onSelectRow(item)" >
              <td  style="width: 40px;" *ngIf="isDashboard">
                <div class="imagen-table">
                  <img
                  [src]="item.operation === 'Venta' ? 'assets/imagenes/iconos/icono-venta-tabla.svg' : 'assets/imagenes/iconos/icono-compra-tabla.svg' "
                  alt="icono del item de la tabla" width="40px" height="30px">

                  <div class="tool">
                    <div class="tool__content color-black font-secondary-regular-16">
                          {{item.operation }}
                      </div>
                  </div>
                </div>
              </td>

              <td  class="itemsdynamic" *ngFor="let cab of arrayHeader">
                <div [ngSwitch]="cab.field">
                  <div *ngSwitchCase="'estado'" class="status" >
                    <div class="font-secondary-regular-12"
                    [ngClass]="onClaseEstado(item.cod_estado)">
                       {{item[cab.field] | titlecase  }}
                    </div>
                  </div>
                  <div *ngSwitchCase="'tipoCambio'" class="font-secondary-regular-14 color-black-300">
                    {{ item[cab.field] }}
                  </div>
                  <div *ngSwitchDefault class="font-secondary-regular-14 color-black-300">
                    {{item[cab.field]}}
                  </div>

                </div>
              </td>
            </tr>
          </div>
        </tbody>
      </table>

      <div class="tabla-responsive" >
        <div *ngFor="let cab of arrayBody">
          <div class="mb-10">
            <div class="row-date"  (click)="onSelectRow(cab)" >
              <div class="font-secondary-regular-14 color-black label-operation"> {{cab.operation }} </div>
              <div class="font-secondary-regular-16"
                [ngClass]="onClaseEstado(cab.cod_estado)">
                {{cab.estado | titlecase  }}
              </div>
            </div>

            <div class="row-date mt-2 mb-2">
              <div class="font-secondary-regular-14 color-black-300">  TC {{cab.tipoCambio }}    </div>
              <div class="font-secondary-regular-14 color-black-300"> {{cab.fecha }}  - {{cab.hour }} </div>
            </div>

            <div class="row-date">
              <div>
                <div class="font-secondary-regular-12 color-black-300"> Monto Negociado </div>
                <div class="font-secondary-regular-14 color-black label-amoun-left"> {{cab.amountFrom}} </div>
              </div>

              <img src="assets/imagenes/icon-flecha-azul.svg" alt="icono de flecha azul">

              <div>
                <div class="font-secondary-regular-12 color-black-300"> Cambio Recibido </div>
                <div class="font-secondary-regular-14 color-black label-amoun-from"> {{cab.amountTo }} </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </ng-container>

     <ng-template #noData>
        <div class="no-data-table font-secondary-regular-16 color-black-300"  *ngIf="type === 'TableDash' ">
          Aquí se mostrará tus últimas operaciones
        </div>
        <div class="no-data-table font-secondary-regular-16 color-black-300" *ngIf="type != 'TableDash' ">
          No tienes operaciones con el filtro ingresado
        </div>
      </ng-template>
</div>
