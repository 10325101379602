<ng-container [ngSwitch]="typeInputs">
    <ng-container *ngSwitchCase="'Classic'">
        <div class="Classic">
            <div class="input-container" [style.width]="width" >
                <input
                    type="text"
                    [style.height]="height"
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    [readonly]="true"
                    [value]="value"
                    [ngClass]="classInput"
                    (click)="toggleDropdown()"
                    autocomplete="off"

                >
                <div class="arrow" (click)="toggleDropdown()" >
                    <img src="assets/imagenes/dropdown/{{arrow}}.svg">
                </div>
            </div>

            <div *ngIf="showCombo" class="wrapper-list">
                <div *ngFor="let item of data" class="options">
                    <div class="option"
                        (click)="getElement(item)"
                        (mousedown)="$event.preventDefault();" >
                        {{item?.name}}
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'Others'">
        <div class="Others">
            <div class="input-container" [style.width]="width"  >
                <input
                    type="text"
                    [style.height]="height"
                    [placeholder]="placeholder"
                    [disabled]="disabled"
                    [readonly]="true"
                    [value]="value"
                    [ngClass]="classInput"
                    (click)="toggleDropdown()"
                    autocomplete="off"

                >
                <div class="arrow" (click)="toggleDropdown()" >
                    <img src="assets/imagenes/dropdown/{{arrow}}.svg">
                </div>
            </div>

            <div *ngIf="showCombo">
                <div class="wrapper-list">
                    <div *ngFor="let item of data">
                        <div class="wrapper-list__option"
                            (click)="getElement(item)"
                            (mousedown)="$event.preventDefault();" >
                            {{item?.name}}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </ng-container>
</ng-container>
