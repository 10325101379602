<ng-container [ngSwitch]="typeMsg">
  <ng-container *ngSwitchCase="'small'">
    <div [ngClass]="typeMsg" [style.width]="width" [style.height]="height">
      <div class="message">{{msg}}</div>
      <div class="icon"><img src="assets/imagenes/messages/alert-small.svg" alt="alerta"></div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'medium_right'">
    <div [ngClass]="typeMsg" [style.width]="width" [style.height]="height">
      <div class="message">{{msg}}</div>
      <div class="icon"><img src="assets/imagenes/messages/alert-medium.svg" alt="alerta"></div>
      <div class="alert">Crear alerta</div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'medium_left'">
    <div [ngClass]="typeMsg" [style.width]="width" [style.height]="height">
      <div class="icon"><img src="assets/imagenes/messages/alert-medium.svg" alt="alerta"></div>
      <div class="message">{{msg}}</div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'medium_left_2'">
    <div [ngClass]="typeMsg" [style.width]="width" [style.height]="height">
      <div class="icon"><img src="assets/imagenes/messages/alert-medium.svg" alt="alerta"></div>
      <div class="message">{{msg}}</div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'migration'">
    <div [ngClass]="typeMsg" [style.width]="width" [style.height]="height">
      <div class="icon"><img src="assets/imagenes/messages/alert-medium.svg" alt="alerta"></div>
      <div class="message">{{msg}} <b> ingresa aquí  </b></div>
    </div>
  </ng-container>
</ng-container>