import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ICompaniesNewRegister, IEmailRegister, IBusinessLeadRegisterV3, IBusinessLeadV6, IregisterGoogleRequest, IregisterGoogleResponse, IuserDate, IuserDateResponse, IuserLeadV1, IuserMigration, ProfesionsRes, RespRegisterUserLead } from '../interface/register.interface';
import { environment } from 'apps/cambix/src/environments/environment';
import { TokenRes } from 'apps/cambix/src/utils/interface/token/token.response';

@Injectable({
    providedIn: 'root'
})

export class RegistersService {
    private apiUrl: string;
    private path: string;
    private path3: string;
    private pathv4: string;
    private pathvLead: string;
    private pathvemail: string;
    private pathvLeadV3: string;
    private pathvLeadV5: string;
    private pathvLeadV6: string;
    private pathvLeadV6Business: string;
    private pathuser: string;
    private pathuser4: string;
    private pathuser5: string;
    private pathPerson: string;

    constructor(
        private http: HttpClient) {
        this.apiUrl = `${environment.api_url}`;
        this.path = 'v2/professions/';
        this.path3 = 'v3/professions?';
        this.pathvLead = 'v1/user-leads/user-lead';
        this.pathvLeadV5 = 'v5/users/user';
        this.pathvLeadV6 = 'v6/users/user';
        this.pathvLeadV6Business = 'v6/users/user-company';
        this.pathv4 = 'v4/validations/';
        this.pathvemail = 'v3/validations/email';
        this.pathuser = 'v3/users';
        this.pathuser4 = 'v4/users';
        this.pathuser5 = 'v5/users';
        this.pathPerson = 'v1/persons?';
        this.pathvLeadV3 = 'v3/user-leads/user-lead';
    }


    getProffesions(professionName: string): Observable<ProfesionsRes[]> {
        const params = new HttpParams()
            .append('professionName', professionName);
        return this.http.get<ProfesionsRes[]>(`${this.apiUrl}${this.path}/search?`, { params });
    }

    getProffesionsV3(name: string): Observable<ProfesionsRes[]> {
        return this.http.get<ProfesionsRes[]>(`${this.apiUrl}${this.path3}name=${name}`);
    }

    /* NEW SERVICE COMPANIES */
    getCompaniesV3(name: string): Observable<ICompaniesNewRegister[]> {
        return this.http.get<ICompaniesNewRegister[]>(`${this.apiUrl}v2/companies?name=${name}`);
    }


    //CORREGIR EMAIL
    updateEmail(id: number, email: string): Observable<any> {
        const body = { email };
        return this.http.patch(`${this.apiUrl}${this.pathvLead}/${id}`, body);
    }

    //VALIDAR CODE OTP
    validateSmsCodeV4(code: string, session: string) {
        const params = new HttpParams()
            .append('code', code)
            .append('session', session)
        return this.http.get(`${this.apiUrl}${this.pathv4}code?`, { params });
    } 
    //REENVIAR CODIGO AL CORREO -
    resendCodeEmail(token: string): Observable<IEmailRegister> {
        const body = { token };
        return this.http.post<IEmailRegister>(`${this.apiUrl}${this.pathvemail}/resend`, body);
    }


    //Validate Cellphone Registro empresa
    validateCellphone(cellphone: string, userType : string): Observable<IEmailRegister>  { 
        const params = { cellphone, userType };
        return this.http.post<IEmailRegister>(`${this.apiUrl}${this.pathv4}sms/send`, params);
    } 

    //register persona
    userReniecV5(userReniec: IuserLeadV1): Observable<RespRegisterUserLead> {
        return this.http.post<RespRegisterUserLead>(`${this.apiUrl}${this.pathvLead}`, userReniec);
    }
    businessReniecV3(companyReniec: IBusinessLeadRegisterV3): Observable<RespRegisterUserLead> {
        return this.http.post<RespRegisterUserLead>(`${this.apiUrl}${this.pathvLeadV3}`, companyReniec);
    }

    //register Migration
    saveMigration(userMigration: IuserMigration): Observable<IEmailRegister> {
        return this.http.post<IEmailRegister>(`${this.apiUrl}${this.pathuser4}/user/me`, userMigration);
    }

    validarUser(userReniec: IuserLeadV1): Observable<TokenRes> {
        return this.http.post<TokenRes>(`${this.apiUrl}${this.pathvLeadV5}`, userReniec);
    }

    validarCompany(company: IBusinessLeadV6): Observable<TokenRes> {
        return this.http.post<TokenRes>(`${this.apiUrl}${this.pathvLeadV6}`, company);
    }
    //BUSINESS 
    validarCompanyBusiness(company: IBusinessLeadV6): Observable<TokenRes> {
        return this.http.post<TokenRes>(`${this.apiUrl}${this.pathvLeadV6Business}`, company);
    }
    
    validarUserDate(data: IuserDate): Observable<IuserDateResponse> {
        const params = new HttpParams()
            .append('documentNumber', data.documentNumber)
            .append('issuedDate', data.issuedDate)
        return this.http.get<IuserDateResponse>(`${this.apiUrl}${this.pathPerson}`, { params });
    }
    



    //EMAIL PARA CAMBIAR DE PASS  Y CAMBIAR EMAIL EN MIGRACION
    sendEmail(email: string): Observable<IEmailRegister> {
        const data = {
            email: email
        }
        return this.http.post<IEmailRegister>(`${this.apiUrl}${this.pathvemail}/send`, data);
    }

    //UPDATE PASS
    updatePass(data: any): Observable<any> {
        return this.http.patch<any>(`${this.apiUrl}${this.pathuser5}/password`, data);
    }

    registerWithGoogle(registerGoogleRequest: IregisterGoogleRequest): Observable<IregisterGoogleResponse> {
       return this.http.post<IregisterGoogleResponse>(`${this.apiUrl}v2/user-leads/user-lead`, registerGoogleRequest);
    }



    onValidPass(event : any){   
        var contrasenna = event;
        let existeMayuscula = false;
        let existeMinuscula = false;
        let existeNumber = false;
        let existeSimbolo = false;
        let existelengValidate = false;   

        for (var i = 0; i < contrasenna.length; i++) {
            if (contrasenna.charCodeAt(i) >= 65 && contrasenna.charCodeAt(i) <= 90) {
                existeMayuscula = true;
            } else if ( contrasenna.charCodeAt(i) >= 97 && contrasenna.charCodeAt(i) <= 122 ) {
                existeMinuscula = true;
            } else if ( contrasenna.charCodeAt(i) >= 48 && contrasenna.charCodeAt(i) <= 57 ) {
                existeNumber = true;
            } else if (
                contrasenna.charCodeAt(i) >= 33 && contrasenna.charCodeAt(i) <= 47 ||
                contrasenna.charCodeAt(i) >= 58 && contrasenna.charCodeAt(i) <= 64 ||
                contrasenna.charCodeAt(i) >= 123 && contrasenna.charCodeAt(i) <= 126
                ) {
                existeSimbolo = true;
            }
        }

        if (contrasenna.length >= 8) { 
            existelengValidate = true;
        }

        if (existeMayuscula && existeMinuscula && existeSimbolo && existelengValidate && existeNumber) {  
            return true;
        }else{
            return false;
        } 
    }
}
