export enum OPERATION_STATUS {
  REGISTERED  = 'P',
  VALIDATED   = 'VA',
  IN_PROGRESS = 'IP',
  SUCCESS     = 'FS',
  OBSERVED    = 'OB',
  CANCELLED   = 'C',
  EXPIRED     = 'CA',
}

export const OPERATION_STATUS_TEXT = {
  REGISTRADO    : 'REGISTRADA',
  VALIDADO      : 'VALIDADA',
  'EN PROCESO'  : 'EN PROCESO',
  FINALIZADO    : 'FINALIZADA',
  OBSERVADO     : 'OBSERVADA',
  CANCELADO     : 'CANCELADA',
  CADUCADO      : 'CADUCADA',
}
