import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuService } from '../../service/menu.serice';
import { SidenavService } from '../../service/sidenav.service';
import { TokenStorageService } from 'apps/cambix/src/utils/services/token/token.storage.service';
import { tap } from 'rxjs';


import { DialogCdkService } from '../../service/dialog-cdk.service';
import { messageType } from 'apps/cambix/src/app/cambix/shared/message-type/message-type';
import { DialogSingOffComponent } from 'apps/cambix/src/app/cambix/shared/dialog-confirm/dialog-sing-off/dialog-sing-off.component';
import { ModalConfirmService } from 'apps/cambix/src/app/cambix/shared/dialog-confirm/service/dialog-confirm.service';
import { Router } from '@angular/router';
import { STORAGE_KEY } from 'apps/cambix/src/utils/constants/storage';

const getStyles = (...args: string[]) => ["listMenu", ...args].filter(Boolean)


@Component({
  selector: 'cbx-menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss'],
})

export class MenuUserComponent {

  @Input() listMenu: any[] = [];
  @Input() type: "MenuUserDefault" | "MenuUserCbx" = "MenuUserDefault";

  @Output() emitMenuSelect: EventEmitter<any> = new EventEmitter();

  sesionUsuario: any;
  itemsArray: any[] = [];
  firstName: string = "";

  public get typeClass(): string[] {
    return getStyles(this.type)
  }

  constructor(
    private serviceMenu: MenuService,
    private serviceSidenav: SidenavService,
    private serviceToken: TokenStorageService,
    private serviceModal: ModalConfirmService,
    private dialogService: DialogCdkService,
    private router: Router
  ) {

    this.itemsArray = [
      {
        code: 1,
        name: "Datos del Perfil",
        icon: "assets/imagenes/iconos/icono-datos-usuario.svg",
        routerlink: 'cambix/dashboard/profile'
      },
      {
        code: 2,
        name: "Ayuda",
        icon: "./assets/imagenes/iconos/icono-fqa.svg",
        routerlink: 'cambix/dashboard/help'
      },
      {
        code: 3,
        name: "Blog",
        icon: "./assets/imagenes/iconos/icono-blog.svg",
        routerlink: '/blog',
        routerlinkBusiness: '/business/blog'
      },

      {
        code: 0,
        name: "Cerrar Sesión",
        icon: "assets/imagenes/iconos/icono-cerrar-session.svg",
        routerlink: "cambix/auth"
      }
    ]
    const datosSession: any = this.serviceToken.getStoreSessionUser();
    this.sesionUsuario = JSON.parse(datosSession);



    //Quitamos Datos de perfil cuando es empresa
    if (this.sesionUsuario.userType === 'EMP') {
      this.firstName = this.sesionUsuario.names;
      this.itemsArray.shift();
    } else {
      const [firstname] = this.sesionUsuario.names.split(" ");
      this.firstName = firstname;
    }
  }


  onHiddenSidenav() {
    this.serviceSidenav.setisActiveMenuUser(false);
  }


  onActive(router: string, codeMenu: number) {
    if (codeMenu === 0) {
      const data = messageType.cerrar_sesion;
      const dialogref = this.dialogService.open(DialogSingOffComponent,
        { disableClose: true, data: data, width: '350' }
      );

      dialogref.beforeClosed()
        .pipe(
          tap(({ dispose }) => {
            dispose();
            localStorage.removeItem(STORAGE_KEY.firstLogin);
            this.serviceToken.removeStoreSessionUser();
            this.serviceModal.setLoadingButtonConfirm(false);
            this.serviceSidenav.setisActiveMenuUser(false);
            this.serviceToken.removeToken();
            this.router.navigateByUrl('/');
          }),
        ).subscribe();

    } else if (codeMenu === 3) {
      if(this.sesionUsuario.userType === 'EMP') {
        this.router.navigateByUrl('/business/blog');
      } else {
        this.router.navigateByUrl('/blog');
      }
    } else {
      this.onRute(0, router);
    }

  }

  onRute(codeMenu: number, router: string) {
    this.serviceMenu.setUpdateMenu(codeMenu, router);
    this.serviceSidenav.setisActiveMenuUser(false);
  }
}
