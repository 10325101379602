import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
const getStyles = (...args: string[]) => [...args].filter(Boolean)
export enum EInputValidation {
  Number = 'number',
  Alpha = 'alpha',
  Alphanumeric = 'alphanumeric',
  Text = 'text',
  digitsOnly = 'number',
}

@Component({
  selector: 'cbx-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent {

@ViewChild('cbxDropDownList') cbxDropDownList!: ElementRef;
@ViewChild('inputCalendario') inputCalendario!: ElementRef;
@ViewChild('bodyCalendar') bodyCalendar!: ElementRef;

@Input() placeholder = ' ';
@Input() label = 'label';
@Input() type = EInputValidation.digitsOnly;
@Input() disabled = false;
@Input() value = '';
@Input() maxLength = '60';
@Input() size = 'md';
@Input() paste = true;
@Input() msgError = "";
@Input() width = '';
@Input() height = '48px';
@Output() public dataValue = new EventEmitter();
@Output() public dateFromValue = new EventEmitter();
@Output() public dateUntilValue = new EventEmitter();
@Input() typeInput:  "Others"  | "CalendarCambix" |  "CalendarArray" | "CalendarFilter" = "Others";
@Input() loading = false;
@Input() readOnly = false;
@Input() showCalendar: boolean = false;

@Input() existeFecha: string = "";
@Input() dateFrom: string = "";
@Input() dateUntil: string = "";
@Input() typeDate: string = "";
@Input() idArray: number | null = 0;

classInfo = {
  'info-container': true,
  'counter-container': false
};
fechaexistente : any;
fechaArrayexistente : any;
existeDateFilter : any;
hasError = false;
maxLimitLength = 110;
maxInputLength = this.maxLimitLength;
hasIcon = false;
notSelectDateStyle: boolean = false;
flgShowCalendar : boolean = false;

public get typeInputs(): string[] {
  return getStyles(this.typeInput)
}

onChange(target: any) {


  if(!this.dateFrom && !this.dateUntil){
    this.existeDateFilter = null;
  }

  target.value = target.value.substr(0, Number(this.maxLength));
  if (target.value.length === 2 || target.value.length === 5) {
     target.value += '/';
  }
  if(!target.value){
    this.fechaexistente = null;
    this.showCalendar = false;
    this.notSelectDateStyle = true;
    this.dataValue.emit(null);
  }
  this.dataValue.emit(target.value);
}



ngOnChanges(changes: SimpleChanges) {
  if(!this.dateFrom && !this.dateUntil){
    this.existeDateFilter = null;
  }
  this.hasError = this.msgError != "" ? true : false;

}


onFocus(event : boolean){
  this.flgShowCalendar = true;
  this.showCalendar = event;
}


getFecha(element: any) {
  this.fechaexistente = element.split('/');
  this.dataValue.emit(element);
}

getFechaArray(element: any) {
  this.fechaexistente = element.split('/');
  const DATE_ARRAY = {data: element, index: this.idArray};
  this.dataValue.emit(DATE_ARRAY);
}



getFechaCalendarFilter(element: any) {
  if(element.date){
    this.showCalendar = element.flgclick ? true : false;
    this.existeDateFilter = element.date;

    if(element.type === 'dateFrom'){
      this.dateFromValue.emit(element.date);
    }else{
      const DATA_UNTIL = {
        data :  element.date,
        flgFilter : element.flgclick
      }
      this.dateUntilValue.emit(DATA_UNTIL);
    }
  } else{
    this.existeDateFilter = null;
    this.showCalendar = false;
    if(this.typeDate === 'dateFrom'){
      this.dateFromValue.emit(null);
    }else{
      const DATA_UNTIL = {
        data :  null,
        flgFilter : true
      }

      this.dateUntilValue.emit(DATA_UNTIL);
    }
  }
}


  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if(this.typeInput === 'CalendarCambix' || this.typeInput === 'CalendarArray'){
      this.onValidateCalendarCambix(event);
    } else
     if(this.typeInput === 'CalendarFilter' ){
      this.onValidateCalendarFilter(event);
    }
  }


  onValidateCalendarCambix(event: any){
    let isInsideInput
    if(this.typeInput === 'CalendarCambix'){
      isInsideInput = (event.target as HTMLElement).closest('.CalendarCambix .input-container') !== null;
    } else if(this.typeInput === 'CalendarArray'){
      isInsideInput = (event.target as HTMLElement).closest('.CalendarArray .input-container') !== null;
    }


    const isInsideCombo = (event.target as HTMLElement).closest(' .body-container .wrapper-list-found  .div-meses .Classic .wrapper-list .options, .option') !== null;
    const isInsideDay = (event.target as HTMLElement).closest(' .body-container .wrapper-list-found  .div-calendario .calendar .calendar-table .empty-cell, .selected,.normal  ') !== null;

    // Al activar el loading, el usuario completo la fecha y este no debe mostrar mensajes de error en el formulario
    if(this.loading){
      this.showCalendar = false;
      this.notSelectDateStyle = false;
    }



    // Al dar click fuera del input evaluamos si ya la fecha fue completada de lo contrario mostramos mensaje de error
    if(!isInsideInput && !isInsideCombo && !isInsideDay ) {
      this.showCalendar = false;
      this.flgShowCalendar = false;

      if(this.fechaexistente){
        const [daySelect, monthSelect, yearSelect] = this.fechaexistente;
        //si no completo la fecha mostramos mensaje de error
        if(!(daySelect != 'dd' && monthSelect != 'mm' && yearSelect != 'yyyy')){
          this.hasError = true;
          this.msgError = 'Completa la fecha de emisión';
          this.notSelectDateStyle = true;
        }else{
          this.showCalendar = false;
        }
      } else{
        this.notSelectDateStyle = true;
      }
    }
  }

  onValidateCalendarFilter(event: any){
    if (!this.inputCalendario || !this.bodyCalendar ) {return;}

    if(this.inputCalendario.nativeElement.contains(event.target)){
      this.showCalendar = true;
      return;
    }

    if (!this.inputCalendario.nativeElement.contains(event.target)) {
      if(this.bodyCalendar.nativeElement.contains(event.target)){
        this.showCalendar = true;
      }else{
        this.showCalendar = false;
      }
      return;
    }
  }



  // En el método containsMoreChildren del componente DatepickerComponent
  containsClassRecursively(node: Node, className: string): boolean {
    if (node.nodeType === Node.ELEMENT_NODE && (node as Element).classList.contains(className)) {
      return true;
    }
    const childNodes = node.childNodes;
    for (let i = 0; i < childNodes.length; i++) {
      const childNode = childNodes[i];
      if (this.containsClassRecursively(childNode, className)) {
        return true;
      }
    }
    return false;
  }
  containsClassInChildren(className: string): boolean {
    if (!this.bodyCalendar) {
      return false;
    }
    return this.containsClassRecursively(this.bodyCalendar.nativeElement, className);
  }

}


