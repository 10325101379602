import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable, filter } from 'rxjs';
import { DialogConfig } from '../interfaces/dialog.interface';
import { ETYPECLOSED } from '../constants/dialog/dialog.const';

export class DialogRef {
  private afterClosedSubject = new Subject<any>();
  private beforeClosedSubject = new Subject<any>();

  constructor(
    private overlayRef  : OverlayRef,
    private config      : DialogConfig
  ) {
    if (!config.disableClose) {
      this.overlayRef.backdropClick().subscribe(() => {
        this.close();
      });

      this.overlayRef.keydownEvents().pipe(
        filter(event => event.key === 'Escape')
      ).subscribe(() => {
        this.close();
      });
    }
  }

  public close(result?: any, typeClosed?: ETYPECLOSED) {

    if (typeClosed === ETYPECLOSED.BEFORE_CLOSED) {
      this.beforeClosedSubject.next({ result, dispose: () => this.overlayRef.dispose() });
      this.beforeClosedSubject.complete();
    }
    else {
      this.overlayRef.dispose();
      this.afterClosedSubject.next(result);
      this.afterClosedSubject.complete();
    }
  }

  public afterClosed(): Observable<any> {
    return this.afterClosedSubject.asObservable();
  }
  public beforeClosed(): Observable<any> {
    return this.beforeClosedSubject.asObservable();
  }
}
