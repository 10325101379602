import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
const getStyles = (...args: string[]) => [...args].filter(Boolean)
export enum EInputValidation {
  Number = 'number',
  Alpha = 'alpha',
  Alphanumeric = 'alphanumeric',
  Text = 'text',
}

@Component({
  selector: 'cbx-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
})
export class DropdownListComponent implements OnChanges {
  @ViewChild('cbxDropDownList')
  cbxDropDownList!: ElementRef;
  @Input() placeholder = ' ';
  @Input() label = 'label';
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() value = '';
  @Input() maxLength = '60';
  @Input() size = 'md';
  @Input() paste = true;
  @Input() msgError = "";
  @Input() msgInfo = "";
  @Input() hasCounter = false;
  @Input() width = '';
  @Input() height = '48px';
  @Input() icon = '';
  @Input()
  inputValidation!: EInputValidation;
  @Output() public valueChanged = new EventEmitter();
  @Output() public blurred = new EventEmitter();
  @Output() public enter = new EventEmitter();
  @Output() public dataValue = new EventEmitter();
  @Input() typeInput:
    "ClassicInput" | "ClassicInputCompany" | "InputWithoutTitle" = "ClassicInput";
  @Input() loading = false;
  @Input() readOnly = false;
  @Input() data: any = [];
  hasError = false;
  hasInfo = false;
  maxLimitLength = 60;
  maxInputLength = this.maxLimitLength;
  expression!: RegExp;
  counter = 0;
  total = 60;
  hasIcon = false;
  isDropdownOpen: boolean = false;
  public typeView: "FULL" | "FOUND" | "EMPTY" | "ERROR" | "LOADING" | "" = "";
  classInput = {
    'text-input': true,
    'error-input': false
  };
  classInfo = {
    'info-container': true,
    'counter-container': false
  };
  arrow = 'arrow-down-gris';

  public get typeInputs(): string[] {
    return getStyles(this.typeInput)
  }
  constructor(private elementRef: ElementRef) {

  }
  ngOnChanges(changes: SimpleChanges) {

    if (this.msgInfo != "" && this.msgError != "") {
      this.hasInfo = false;
      this.hasError = true;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    if (this.msgInfo == "" && this.msgError == "") {
      this.hasInfo = false;
      this.hasError = false;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    if (this.msgInfo == "" && this.msgError != "") {
      this.hasInfo = false;
      this.hasError = true;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    if (this.msgInfo != "" && this.msgError == "") {
      this.hasInfo = true;
      this.hasError = false;
      this.classInput['error-input'] = this.hasError;
      this.classInput['text-input'] = !this.hasError;
    }
    if (this.hasCounter && (this.msgError == "" && this.msgInfo == "")) {
      this.classInfo['info-container'] = false;
      this.classInfo['counter-container'] = true;
    }
    else {
      this.classInfo['info-container'] = true;
      this.classInfo['counter-container'] = false;
    }
    if (this.icon != "") this.hasIcon = true;
    else this.hasIcon = false;

    this.total = Number(this.maxLength);



  }

  onChange(target: any) {
    switch (this.inputValidation) {
      case EInputValidation.Number:
        this.expression = /[A-Za-zÑÁÉÍÓÚñáéíóú`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Alpha:
        this.expression = /[0-9`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Alphanumeric:
        this.expression = /[`~!¡@#$%^&*()_|+\-=?;:'",.<>° ]/g;
        break;
      case EInputValidation.Text:
        this.expression = /[0-9`~!¡@#$%^&*()_|+\-=?;:'",.<>°]/g;
        break;
    }
    if (this.expression) {
      target.value = target.value
        .replace(this.expression, '');

    }
    target.value = target.value.substr(0, Number(this.maxLength));
    this.counter = target.value.length;
    this.valueChanged.emit(target.value);
  }

  UpdateArrow() {
    return this.typeView === 'FULL' ? this.arrow = 'arrow-up' : this.arrow = 'arrow-down-gris';
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.typeView = 'EMPTY';
      this.UpdateArrow();
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) this.typeView = 'FULL';
    else
      this.typeView = 'EMPTY';
    this.UpdateArrow();
  }

  getElement(element: any) {
    this.dataValue.emit(element);
  }

}

