<ng-container [ngSwitch]="typeInputs">
  <ng-container *ngSwitchCase="'ClassicInput'">
    <div class="ClassicInput">
      <div class="input-container" [style.width]="width">
        <input 
          type="text" 
          [id]="idTextField" 
          [style.height]="height" 
          [placeholder]="placeholder" 
          [disabled]="disabled"
          [readonly]="readOnly"
          [value]="value"
          [ngClass]="classInput"
          (input)="onChange($event.target)" 
          autocomplete="off" />
        <label [htmlFor]="idTextField"  class="label">{{label}}</label>
        <div class="loading" *ngIf="loading && !hasIcon">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="icon" *ngIf="hasIcon">
          <img src="assets/imagenes/normal-input/{{icon}}.svg">
        </div>
      </div>
      
      <div [ngClass]="classInfo" [style.width]="width">
        <div *ngIf="hasError" class="message-error"  [appStripHtml]="msgError">
          {{msgError}}
        </div>
        <div *ngIf="hasInfo" class="message-info">
          {{msgInfo}}
        </div>
        <div *ngIf="hasCounter" class="counter">
          {{counter}}/{{total}}
        </div>
      </div>
    </div> 
  </ng-container>
 
  <ng-container *ngSwitchCase="'ClassicInputCompany'">
    <div class="ClassicInputCompany">
      <div class="input-container" [style.width]="width">
        <input 
          type="text" 
          [id]="idTextField" 
          [style.height]="height" 
          [placeholder]="placeholder" 
          [disabled]="disabled"
          [readonly]="readOnly"
          [value]="value"
          [ngClass]="classInput"
          (input)="onChange($event.target)" 
          autocomplete="off" />
        <label [htmlFor]="idTextField"  class="label">{{label}}</label>
        <div class="loading" *ngIf="loading && !hasIcon">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="icon" *ngIf="hasIcon">
          <img src="assets/imagenes/normal-input/{{icon}}.svg">
        </div>
      </div>
      
      <div [ngClass]="classInfo" [style.width]="width">
        <div *ngIf="hasError" class="message-error"  [appStripHtml]="msgError">
          {{msgError}}
        </div>
        <div *ngIf="hasInfo" class="message-info">
          {{msgInfo}}
        </div>
        <div *ngIf="hasCounter" class="counter">
          {{counter}}/{{total}}
        </div>
      </div>
    </div> 
  </ng-container>

  <ng-container *ngSwitchCase="'InputWithoutTitle'">
    <div class="InputWithoutTitle">
      <div class="input-container" >
        <input 
          type="text"
          id="username" 
          [style.height]="height" 
          [style.width]="width"
          [placeholder]="placeholder" 
          [disabled]="disabled"
          [value]="value"
          [ngClass]="classInput"
          [readonly]="readOnly"
          (input)="onChange($event.target)" 
          autocomplete="off" />
        <div class="loading" *ngIf="loading && !hasIcon">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="icon" *ngIf="hasIcon">
          <img src="assets/imagenes/normal-input/{{icon}}.svg">
        </div>
      </div>
      
      <div [ngClass]="classInfo" [style.width]="width">
        <div *ngIf="hasError" class="message-error">
          {{msgError}}
        </div>
        <div *ngIf="hasInfo" class="message-info">
          {{msgInfo}}
        </div>
        <div *ngIf="hasCounter" class="counter">
          {{counter}}/{{total}}
        </div>
      </div>

    </div>
  </ng-container>
</ng-container>