import { Component, Input, SecurityContext } from '@angular/core'; 
import { DomSanitizer } from '@angular/platform-browser';

const getStyles = (...args: string[]) => ["type", ...args].filter(Boolean)


@Component({
  selector: 'cbx-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  
  @Input() position : string  = "up";    
  @Input() data : string | null = "";     
  @Input() idTextField = 'idTextField';
  @Input() type : 
   "TooltipPrimary" | "TooltipTableDash" |"TooltipSecondary" = "TooltipPrimary";
 
   constructor( 
    private sanitizer: DomSanitizer
  )
  {
    this.data =  this.sanitizer.sanitize(SecurityContext.HTML, this.data);
  }

  public get typeClass(): string[] {
   return getStyles(this.type)
  }
 
  public get typePosition(): string {
    return `${this.type}__${this.position}`;
  }
 
}
